import React, { Component, Fragment } from 'react';
import { ArrowSmDownIcon, ArrowSmLeftIcon, ArrowSmRightIcon, ArrowSmUpIcon, CheckCircleIcon, CheckIcon, ChipIcon, PaperAirplaneIcon, PlusIcon, SelectorIcon, SparklesIcon, SpeakerphoneIcon, UserGroupIcon, UserIcon, UsersIcon, XCircleIcon, XIcon } from '@heroicons/react/outline'
import { Listbox, Transition, Dialog } from '@headlessui/react'
import PreviewGoogleSearch from '../previews/google/search';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import PreviewSectionFacebook from './previewSectionFacebook';
import PreviewSectionLinkedin from './previewSectionLinkedin';
import PreviewSectionTwitter from './previewSectionTwitter';
import PreviewSectionTikTok from './previewSectionTikTok';
import PreviewTikTokFeed from '../previews/tiktok/feed';
import tiktok_cta from '../assets/json/tiktok_cta.json';
import PreviewSectionSnapchat from './previewSectionSnapchat';


class SlideoutTailwindAsset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: {},
            title: "",
            subtitle: "",
            open: false,
            loader: false,
            loaderSecondary: false,
            error: false,
            success: false,
            reach: {},
            disabled: false,
            noSubmit: false,
            OpenAiResult: [],
            open_chat: false,
            ai_chat: false,
            language: { id: 1, name: "English" },
            ai_action: { id: 2, name: "Auto create" },
            preview: {
                facebook: { id: 1, value: "feed", name: "Feed", ratio: "1:1" },
                instagram: { id: 1, value: "feed", name: "Feed", ratio: "1:1" },
                twitter: { id: 1, value: "feed", name: "Feed" },
                linkedin: { id: 1, value: "feed", name: "Feed" },
                tiktok: { id: 1, value: "feed", name: "Feed" },
                snapchat: { id: 1, value: "feed", name: "Stories" },
                // snapchat: { id: 2, value: "feed", name: "Stories" },
            },
            preview_data: false,
            chat: false
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            open: this.props.open,
            title: this.props.title,
            subtitle: this.props.subtitle,
            loader: this.props.loader,
            large: this.props.large,
            small: this.props.small,
            medium: this.props.medium,
            full: this.props.full,
            submitButtonText: this.props.submitButtonText,
            secondaryButton: this.props.secondaryButton,
            secondaryButtonText: this.props.secondaryButtonText,
            loaderSecondary: this.props.loaderSecondary,
            error: this.props.error,
            success: this.props.success,
            reach: this.props.reach,
            thirdButton: this.props.thirdButton,
            thirdButtonLoader: this.props.thirdButtonLoader,
            thirdButtonWhite: this.props.thirdButtonWhite,
            disabled: this.props.disabled,
            noSubmit: this.props.noSubmit,
            client: this.props.client ? this.props.client : this.state.client,
            preview_data: this.props.preview_data ? this.props.preview_data : this.state.preview_data,
            chat: this.props.chat,
        })
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.open && !this.state.open) {
            await this.promisedSetState({
                open_chat: false,
                OpenAiResult: [],
                ai_chat: false,
                preview_data: false,
            })
        }
        await this.promisedSetState({
            open: nextProps.open,
            title: nextProps.title,
            subtitle: nextProps.subtitle,
            loader: nextProps.loader,
            large: nextProps.large,
            small: nextProps.small,
            medium: nextProps.medium,
            full: nextProps.full,
            submitButtonText: nextProps.submitButtonText,
            secondaryButton: nextProps.secondaryButton,
            secondaryButtonText: nextProps.secondaryButtonText,
            loaderSecondary: nextProps.loaderSecondary,
            error: nextProps.error,
            success: nextProps.success,
            reach: nextProps.reach,
            thirdButton: nextProps.thirdButton,
            thirdButtonLoader: nextProps.thirdButtonLoader,
            thirdButtonWhite: nextProps.thirdButtonWhite,
            disabled: nextProps.disabled,
            name_error: nextProps.name_error,
            noSubmit: nextProps.noSubmit,
            client: nextProps.client,
            chat: nextProps.chat,
            preview_data: nextProps.preview_data,
        })
        if (this.state.chat && this.state.chat.length > 0) {
            await this.promisedSetState({
                OpenAiResult: this.state.chat
            })
        }
        if (this.state.preview_data && this.state.preview_data.preview) {
            this.state.preview[this.state.preview_data.preview_channel] = this.state.preview_data.preview;
            await this.promisedSetState({
                preview: this.state.preview
            })
        }
        //await this.functions.scroll();
        //}

    }

    functions = {
        success: (value) => {
            this.promisedSetState({
                success: value
            });
        },
        loader: (value) => {
            this.promisedSetState({
                loader: value
            });
        },
        scroll: () => {
            return new Promise(async (resolve) => {
                try {
                    let myDiv = document.getElementById('chat');
                    myDiv.scrollTo(0, 100000000000);
                } catch (error) {
                }
                resolve();
            })
        },
        typeWriter: () => {
            return new Promise(async (resolve) => {
                try {

                    if (this.state.OpenAiResult[this.state.OpenAiResult.length - 1].content !== "") {
                        await this.promisedSetState({
                            assistant_index: this.state.OpenAiResult.length - 1
                        });
                        let element = document.getElementById('assistant_' + (this.state.OpenAiResult.length - 1));

                        for (let i = 0; i < this.state.OpenAiResult[this.state.OpenAiResult.length - 1].content.length; i++) {
                            element.innerHTML += this.state.OpenAiResult[this.state.OpenAiResult.length - 1].content.charAt(i);
                            await new Promise(resolve => setTimeout(resolve, 10));
                            await this.functions.scroll();
                        }
                        await this.promisedSetState({
                            assistant_index: 99999
                        });
                    }
                } catch (error) {
                }
                resolve();
            });
        },
        openAi: async (init = false, failed = false, count = 0) => {

            let data = {
                message: this.state.OpenAiResult.filter((item) => { return !item.fake }).map((item) => { return { role: item.role, content: item.content } }),
                language: this.state.language.name,
                channels: this.state.preview_data && this.state.preview_data.utm_channels ? this.state.preview_data.utm_channels : [],
            }
            if (!init && this.state.searchOpenAi !== "") {
                data.message.push(
                    {
                        role: "user",
                        content: this.state.searchOpenAi
                    }
                )
                this.state.OpenAiResult.push({ role: "user", content: this.state.searchOpenAi });
            }
            if (failed) {
                data.message.push(
                    {
                        role: "user",
                        content: "Return in requested JSON format without any other comments"
                    }
                )
                this.state.OpenAiResult.push({ role: "user", content: "Return in requested JSON format without any other comments", not_show: true });
            }


            await this.promisedSetState({
                OpenAiResult: this.state.OpenAiResult,
                ai_loading: true,
                searchOpenAi: ""
            })
            await this.functions.scroll();
            //await this.promisedSetState({ loading_suggest: true });
            try {
                let response = await this.calls.openAi(data, init);
                let temp = JSON.parse(JSON.stringify(response.data));
                let temp2 = JSON.stringify(response.data);
                if (response.data.content.includes("***") || response.data.content.includes("```")) {
                    let regex = /\*\*\*(.*?)\*\*\*/s;
                    if (response.data.content.includes("```")) {
                        regex = /```(.*?)```/s;
                    }
                    temp.not_show = true;
                    this.state.OpenAiResult.push(temp);

                    await this.promisedSetState({
                        OpenAiResult: this.state.OpenAiResult
                    })
                    let buffer = response.data.content.match(regex);
                    if (buffer && buffer[1]) {
                        //console.log(JSON.stringify(buffer[1]).replace(/\\n/g, "   ").trim(), "buffer[1]=====");
                        response.data.content = response.data.content.replace(buffer[0], "*+*");
                        let parts = response.data.content.split("*+*");
                        let headlines = [];
                        let descriptions = [];
                        try {
                            let t = JSON.stringify(buffer[1]).replace(/\\n|\\\\n/g, "").trim()
                            let a = JSON.parse(t);
                            //console.log(JSON.parse(a), "a=======");
                            a = JSON.parse(a);
                            //console.log(a.Facebook, "FACEBOK==");
                            if (a.Facebook) {
                                if (a.Facebook.Headline) {
                                    headlines.push({ channel: "facebook", value: a.Facebook.Headline });
                                }
                                if (a.Facebook.Text) {
                                    descriptions.push({ channel: "facebook", value: a.Facebook.Text });
                                }
                            }
                            if (a.Linkedin) {
                                if (a.Linkedin.Headline) {
                                    headlines.push({ channel: "linkedin", value: a.Linkedin.Headline });
                                }
                                if (a.Linkedin.Text) {
                                    descriptions.push({ channel: "linkedin", value: a.Linkedin.Text });
                                }
                            }
                            if (a.Snapchat) {
                                if (a.Snapchat.Headline) {
                                    headlines.push({ channel: "snapchat", value: a.Snapchat.Headline });
                                }
                            }
                            if (a.Twitter) {
                                if (a.Twitter.Headline) {
                                    headlines.push({ channel: "twitter", value: a.Twitter.Headline });
                                }
                                if (a.Twitter.Text) {
                                    descriptions.push({ channel: "twitter", value: a.Twitter.Text });
                                }
                            }
                            if (a.TikTok) {
                                if (a.TikTok.Text) {
                                    descriptions.push({ channel: "tiktok", value: a.TikTok.Text });
                                }
                            }
                        } catch (error) {

                        }


                        if (parts[0] !== "") {
                            if (headlines.length < 1 || descriptions.length < 1 && count < 2) {
                                this.functions.openAi(false, true, count + 1);
                                return;
                            }
                            this.state.OpenAiResult.push({ role: "assistant", fake: true, content: parts[0] });
                            await this.promisedSetState({
                                OpenAiResult: this.state.OpenAiResult,
                                ai_loading: false,
                            })
                            await this.functions.scroll();
                            await this.functions.typeWriter();
                        }
                        try {

                            //console.log(headlines, "headlines====");
                            //console.log(descriptions, "descriptions====");
                            //let headlines = a.Google && a.Google.Headline ? a.Google.Headline : [];
                            //let descriptions = a.Google && a.Google.Description ? a.Google.Description : [];

                            if (Array.isArray(headlines) && headlines.length > 0 && this.state.ai_action.id === 2) {
                                this.props.onAddHeadlines(headlines);
                            }
                            if (Array.isArray(descriptions) && descriptions.length > 0 && this.state.ai_action.id === 2) {
                                this.props.onAddDescriptions(descriptions);
                            }
                            this.state.OpenAiResult.push({ role: "assistant", fake: true, content: "", headlines: headlines, descriptions: descriptions });
                        } catch (error) {
                            console.log(error, "error");
                        }

                        await this.promisedSetState({
                            OpenAiResult: this.state.OpenAiResult,
                            ai_loading: false,
                        })
                        await this.functions.scroll();
                        await new Promise(resolve => setTimeout(resolve, 1500));
                        if (parts[1] !== "") {
                            this.state.OpenAiResult.push({ role: "assistant", fake: true, content: parts[1].replace(/\n/g, "") });
                            await this.promisedSetState({
                                OpenAiResult: this.state.OpenAiResult,
                                ai_loading: false,
                            })
                            await this.functions.scroll();
                            await this.functions.typeWriter();
                        }
                    }
                } else {
                    this.state.OpenAiResult.push(response.data);
                    await this.promisedSetState({
                        OpenAiResult: this.state.OpenAiResult,
                        ai_loading: false,
                    })
                    await this.functions.scroll();
                    await this.functions.typeWriter();
                }
                this.props.onUpdateChat(this.state.OpenAiResult);
            } catch (error) {
                this.state.OpenAiResult.push({ role: "assistant", fake: true, error: true, content: error.body && error.body.message ? JSON.stringify(error.body.message) : "Somthing went wrong" });
                await this.promisedSetState({
                    ai_loading: false,
                    OpenAiResult: this.state.OpenAiResult,
                })

            }

        },
    };
    renders = {
        sort: (list, sort, order) => {
            if (!Array.isArray(list)) {
                list = [];
            }
            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : 0) : (x[sort] ? x[sort] : 0);
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : 0) : (y[sort] ? y[sort] : 0);
                    if (order == 'descending') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }
            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : "") : (x[sort] ? x[sort] : "");
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : "") : (y[sort] ? y[sort] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (order == 'descending') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }
            if (sort === "keyword" || sort === "competition") {
                return sortCharacter(list);
            } else {
                return sortNumber(list);
            }
        },
    };

    calls = {
        openAi: (data, init) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/openAiAssistant?client=" + this.state.client.id + "&social=true&init=" + init;
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog style={{zIndex: 151}} as="div" className="fixed inset-0 overflow-hidden" onClose={() => { }}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Dialog.Overlay className="absolute inset-0 bg-purple-900 bg-opacity-50" />
                        <div className="pointer-events-none fixed inset-y-0 p-4 right-0 flex max-w-full pl-10 sm:pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className={(this.state.small ? "max-w-2xl " : "") + (this.state.medium ? "max-w-4xl " : "") + (this.props.mediumPlus ? "max-w-5xl " : "") + (this.state.large ? "max-w-6xl " : "") + (this.state.full ? "w-full " : "") + ((!this.state.medium && !this.state.large && !this.state.full && !this.state.small) ? "max-w-md" : "") + " pointer-events-auto rounded-md overflow-hidden w-screen flex"}>
                                    {
                                        true &&
                                        <>
                                            <div className="flex flex-1 flex-col h-full z-53 relative">
                                                <div className={'flex flex-col transition-all duration-100 absolute right-0 ease-in-out h-full ' + (this.state.open_chat ? "w-full bg-white " : "w-0 overflow-hidden")}>
                                                    <div className="flex items-center justify-center">
                                                        <div className='flex flex-1 items-center justify-start ml-2 h-14'>
                                                            <SparklesIcon className="h-6 w-6 text-purple-500" aria-hidden="true" />
                                                            <div className='flex flex-col'>
                                                                <span className='ml-2 font-bold'>Ask Ai-credo</span>
                                                                <span className='ml-2 text-xxxs'>Powered by OpenAi</span>
                                                            </div>
                                                        </div>
                                                        <div onClick={() => {
                                                            this.setState({
                                                                open_chat: false
                                                            })
                                                            //this.props.onChat();
                                                        }} className="flex h-8 w-8 mr-3 bg-red-100 text-red-500 rounded-full items-center justify-center transition-colors ease-in duration-75 hover:bg-red-500 hover:text-white cursor-pointer">
                                                            <XIcon className="h-5 w-5" aria-hidden="true" />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.open_chat && !this.state.ai_chat &&
                                                        <div className='flex mt-5 mx-4'>
                                                            <div className='grid grid-cols-6 gap-4 w-full'>
                                                                <div className="col-span-3">
                                                                    <DropdownTailwind
                                                                        label={"Choose language"}
                                                                        selected={this.state.language}
                                                                        options={
                                                                            [
                                                                                { id: 1, name: "English" },
                                                                                { id: 2, name: "Swedish" },
                                                                                { id: 3, name: "Finnish" },
                                                                                { id: 4, name: "Danish" },
                                                                                { id: 5, name: "Norwegian" },
                                                                                { id: 6, name: "French" },
                                                                                { id: 7, name: "Dutch" },
                                                                                { id: 8, name: "German" },
                                                                                { id: 9, name: "Spanish" },
                                                                            ]}
                                                                        onChange={async (value) => {
                                                                            await this.promisedSetState({
                                                                                language: value
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-span-3">
                                                                    <DropdownTailwind
                                                                        label={"AI action"}
                                                                        selected={this.state.ai_action}
                                                                        options={[
                                                                            { id: 1, name: "Suggestion" },
                                                                            { id: 2, name: "Auto create" }
                                                                        ]}
                                                                        onChange={async (value) => {
                                                                            await this.promisedSetState({
                                                                                ai_action: value
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="col-span-6">
                                                                    <div
                                                                        onClick={async () => {
                                                                            if (this.state.OpenAiResult.length > 0) {
                                                                            } else this.functions.openAi(true);
                                                                            //this.props.onOpenAi({ language: this.state.language, ai_action: this.state.ai_action });
                                                                            await this.promisedSetState({
                                                                                ai_chat: true
                                                                            })

                                                                        }}
                                                                        className={'cursor-pointer items-center bg-purple-500 hover:bg-purple-600 shadow inline-flex relative justify-center rounded-md border border-transparent w-full py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2'
                                                                        }
                                                                    >
                                                                        Continue
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.open_chat && this.state.ai_chat &&
                                                        <div className={`flex h-full flex-col overflow-hidden border-l`}>
                                                            <div className='flex-1 flex h-full bg-white rounded-md border-gray-300 border overflow-hidden'>
                                                                <div style={{ scrollBehavior: "smooth" }} id="chat" className='h-full overflow-scroll flex flex-col flex-1 relative '>
                                                                    {
                                                                        this.state.OpenAiResult.map((item, index) => {
                                                                            return (
                                                                                <Fragment>
                                                                                    {
                                                                                        item.role === "assistant" && !item.not_show &&
                                                                                        <div ref={"index_" + index} id={"index_" + (index + 1)} className={'flex justify-start mt-2 ml-2'}>
                                                                                            <div className='flex flex-col'>
                                                                                                <div className='flex items-center justify-start'>
                                                                                                    <div className='bg-gray-100 rounded-full flex justify-center items-center h-7 w-7'>
                                                                                                        <SparklesIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />
                                                                                                    </div>
                                                                                                    <span className='mt-1 ml-1 text-sm font-bold text-left'>AI-credo:</span>
                                                                                                </div>
                                                                                                <div className='flex items-start bg-gray-100 text-black rounded-md m-2 p-2 w-auto flex-col text-sm'>

                                                                                                    <p className=' whitespace-pre-wrap ' id={"assistant_" + index} dangerouslySetInnerHTML={{ __html: index !== this.state.assistant_index ? item.content.toString().replace(/(?:\r\n|\r|\n)/g, "<br />") : "" }} >
                                                                                                    </p>

                                                                                                    {
                                                                                                        this.state.preview_data && this.state.preview_data.utm_channels &&
                                                                                                        this.state.preview_data.utm_channels.map((channel, index) => {
                                                                                                            if (((item.headlines && Array.isArray(item.headlines) && item.headlines.filter((inner_item) => { return inner_item.channel === channel }).length > 0) || (item.descriptions && Array.isArray(item.descriptions) && item.descriptions.filter((inner_item) => { return inner_item.channel === channel }).length > 0))) {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <div className='flex items-center text-black'>
                                                                                                                            <div className={"bg-" + channel + "-500" + " h-5 w-5 mr-2 rounded-full flex justify-center items-center"}>
                                                                                                                                {
                                                                                                                                    channel === 'facebook' &&
                                                                                                                                    <img className="w-1"
                                                                                                                                        alt=""
                                                                                                                                        src={require('../assets/images/facebook_icon.svg')} />
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    channel === 'linkedin' &&
                                                                                                                                    <img className="w-2"
                                                                                                                                        alt=""
                                                                                                                                        src={require('../assets/images/linkedin_icon.svg')} />
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    channel === 'twitter' &&
                                                                                                                                    <img className="w-2"
                                                                                                                                        alt=""
                                                                                                                                        src={require('../assets/images/twitter_icon.svg')} />
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    channel === 'tiktok' &&
                                                                                                                                    <img className="w-2"
                                                                                                                                        alt=""
                                                                                                                                        src={require('../assets/images/tiktok_icon.png')} />
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    channel === 'snapchat' &&
                                                                                                                                    <img className="w-3"
                                                                                                                                        alt=""
                                                                                                                                        src={require('../assets/images/snapchat.svg')} />
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            {channel.charAt(0).toUpperCase() + channel.slice(1)}
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            item.headlines && Array.isArray(item.headlines) && item.headlines.filter((inner_item) => { return inner_item.channel === channel }).length > 0 &&
                                                                                                                            <>
                                                                                                                                <div className='my-1'><span className='font-bold'>Headline:</span><span className='ml-2'>(Click to add)</span></div>
                                                                                                                                <div className='text-sm'>
                                                                                                                                    {
                                                                                                                                        item.headlines.filter((inner_item) => { return inner_item.channel === channel }).map((headlines, index) => {
                                                                                                                                            return (
                                                                                                                                                headlines.value.map((headline) => {
                                                                                                                                                    return (
                                                                                                                                                        <div className='relative inline-flex'>
                                                                                                                                                            <div onClick={async () => {
                                                                                                                                                                //if (this.state.preview_data.headline_by_channels && this.state.preview_data.headline_by_channels[channel] === "") {
                                                                                                                                                                this.props.onAddHeadlines([{ channel: headlines.channel, value: [headline], force: true }])
                                                                                                                                                                //}
                                                                                                                                                            }} className={'items-center py-1 rounded-full px-2 mr-2 my-1' + (this.state.preview_data.headline_by_channels && this.state.preview_data.headline_by_channels[channel] === headline ? " bg-green-100 text-green-600 border border-green-600" : " bg-gray-400 cursor-pointer")}>
                                                                                                                                                                {headline}
                                                                                                                                                            </div>
                                                                                                                                                            {
                                                                                                                                                                this.state.preview_data.headline_by_channels && this.state.preview_data.headline_by_channels[channel] === headline &&
                                                                                                                                                                <div onClick={async () => {
                                                                                                                                                                    this.props.onRemoveHeadline(headlines);
                                                                                                                                                                }} className={"h-4 w-4 rounded-full bg-gray-400 absolute top-0 right-0 items-center flex justify-center text-gray-700 cursor-pointer"}>
                                                                                                                                                                    <XIcon className='h-3 w-3' />
                                                                                                                                                                </div>
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                })
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            item.descriptions && Array.isArray(item.descriptions) && item.descriptions.filter((inner_item) => { return inner_item.channel === channel }).length > 0 &&
                                                                                                                            <>
                                                                                                                                <div className='my-1'><span className='font-bold'>
                                                                                                                                    {channel === "facebook" ? (this.state.preview_data.merge ? "Body" : "Primary text") : "Text"}:</span><span className='ml-2'>(Click to add)</span></div>
                                                                                                                                <div className='text-sm'>
                                                                                                                                    {
                                                                                                                                        item.descriptions.filter((inner_item) => { return inner_item.channel === channel }).map((descriptions, index) => {
                                                                                                                                            return (
                                                                                                                                                descriptions.value.map((description) => {
                                                                                                                                                    return (
                                                                                                                                                        <div className='relative inline-flex'>
                                                                                                                                                            <div onClick={async () => {
                                                                                                                                                                //if (this.state.preview_data.body_by_channels && this.state.preview_data.body_by_channels[channel] === "") {
                                                                                                                                                                this.props.onAddDescriptions([{ channel: descriptions.channel, value: [description], force: true }]);
                                                                                                                                                                //}
                                                                                                                                                            }} className={'items-center py-1 rounded-md px-2 mr-2 my-1' + (this.state.preview_data.body_by_channels && this.state.preview_data.body_by_channels[channel] === description ? " bg-green-100 text-green-600 border border-green-600" : " bg-gray-400 cursor-pointer")}>
                                                                                                                                                                {description}


                                                                                                                                                            </div>
                                                                                                                                                            {
                                                                                                                                                                this.state.preview_data.body_by_channels && this.state.preview_data.body_by_channels[channel] === description &&
                                                                                                                                                                <div onClick={async () => {
                                                                                                                                                                    this.props.onRemoveDescription(descriptions);
                                                                                                                                                                }} className={"h-4 w-4 rounded-full bg-gray-400 absolute top-0 right-0 items-center flex justify-center text-gray-700 cursor-pointer"}>
                                                                                                                                                                    <XIcon className='h-3 w-3' />
                                                                                                                                                                </div>
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                })
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                    {
                                                                                                        false && item.headlines && Array.isArray(item.headlines) && item.headlines.length > 0 &&
                                                                                                        <>
                                                                                                            <div className='my-1'><span className='font-bold'>Headlines:</span><span className='ml-2'>(Click to add)</span></div>
                                                                                                            <div className='text-sm'>
                                                                                                                {
                                                                                                                    item.headlines.map((headline, index) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <div className='flex items-center text-black'>
                                                                                                                                    <div className={"bg-" + headline.channel + "-500" + " h-5 w-5 mr-2 rounded-full flex justify-center items-center"}>
                                                                                                                                        {
                                                                                                                                            headline.channel === 'facebook' &&
                                                                                                                                            <img className="w-1"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/facebook_icon.svg')} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            headline.channel === 'linkedin' &&
                                                                                                                                            <img className="w-2"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/linkedin_icon.svg')} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            headline.channel === 'twitter' &&
                                                                                                                                            <img className="w-2"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/twitter_icon.svg')} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            headline.channel === 'tiktok' &&
                                                                                                                                            <img className="w-2"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/tiktok_icon.png')} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            headline.channel === 'snapchat' &&
                                                                                                                                            <img className="w-3"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/snapchat.svg')} />
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    {headline.channel.charAt(0).toUpperCase() + headline.channel.slice(1)}
                                                                                                                                </div>
                                                                                                                                <div className='relative inline-flex'>
                                                                                                                                    <div onClick={async () => {
                                                                                                                                        //if (this.state.group.headlines && this.state.group.headlines.filter((inner_item) => { return inner_item.text === headline }).length === 0) {
                                                                                                                                        //    this.props.onAddHeadlines([headline]);
                                                                                                                                        // }
                                                                                                                                    }} className={'items-center py-1 rounded-full px-2 mr-2 my-1' + (false ? " bg-green-100 text-green-600 border border-green-600" : " bg-gray-400 cursor-pointer")}>
                                                                                                                                        {headline.value}


                                                                                                                                    </div>
                                                                                                                                    {/*
                                                                                                                                    this.state.group.headlines && this.state.group.headlines.filter((inner_item) => { return inner_item.text === headline }).length > 0 &&
                                                                                                                                    <div onClick={async () => {
                                                                                                                                        this.props.onRemoveHeadline(headline);
                                                                                                                                    }} className={"h-4 w-4 rounded-full bg-gray-400 absolute top-0 right-0 items-center flex justify-center text-gray-700 cursor-pointer"}>
                                                                                                                                        <XIcon className='h-3 w-3' />
                                                                                                                                    </div>
                                                                                                                                */}
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                    {
                                                                                                        false && item.descriptions && Array.isArray(item.descriptions) && item.descriptions.length > 0 &&
                                                                                                        <>
                                                                                                            <div className='my-1'><span className='font-bold'>Descriptions:</span><span className='ml-2'>(Click to add)</span></div>
                                                                                                            <div className='text-sm'>
                                                                                                                {
                                                                                                                    item.descriptions.map((description, index) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <div className='flex items-center text-black'>
                                                                                                                                    <div className={"bg-" + description.channel + "-500" + " h-5 w-5 mr-2 rounded-full flex justify-center items-center"}>
                                                                                                                                        {
                                                                                                                                            description.channel === 'facebook' &&
                                                                                                                                            <img className="w-1"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/facebook_icon.svg')} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            description.channel === 'linkedin' &&
                                                                                                                                            <img className="w-2"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/linkedin_icon.svg')} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            description.channel === 'twitter' &&
                                                                                                                                            <img className="w-2"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/twitter_icon.svg')} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            description.channel === 'tiktok' &&
                                                                                                                                            <img className="w-2"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/tiktok_icon.png')} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            description.channel === 'snapchat' &&
                                                                                                                                            <img className="w-3"
                                                                                                                                                alt=""
                                                                                                                                                src={require('../assets/images/snapchat.svg')} />
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    {
                                                                                                                                        description.channel.charAt(0).toUpperCase() + description.channel.slice(1)
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                                <div className='relative inline-flex'>
                                                                                                                                    <div onClick={async () => {
                                                                                                                                        //if (this.state.group.bodies && this.state.group.bodies.filter((inner_item) => { return inner_item.text === description }).length === 0) {
                                                                                                                                        //this.props.onAddDescriptions([description]);
                                                                                                                                        //}
                                                                                                                                    }} className={'relative items-center py-1 rounded-md px-2 mr-2 my-1' + (false ? " bg-green-100 text-green-600 border border-green-600" : " bg-gray-400 cursor-pointer")}>
                                                                                                                                        {description.value}


                                                                                                                                    </div>
                                                                                                                                    {/*
                                                                                                                                        this.state.group.bodies && this.state.group.bodies.filter((inner_item) => { return inner_item.text === description }).length > 0 &&
                                                                                                                                        <div onClick={async () => {
                                                                                                                                            this.props.onRemoveDescription(description);
                                                                                                                                        }} className={"h-4 w-4 rounded-full bg-gray-400 absolute top-0 right-0 items-center flex justify-center text-gray-700 cursor-pointer"}>
                                                                                                                                            <XIcon className='h-4 w-4' />
                                                                                                                                        </div>
                                                                                                                                    */}
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.role === "user" && !item.not_show &&
                                                                                        <div ref={"index_" + index} id={"index_" + (index + 1)} className={'flex justify-end'}>
                                                                                            <div className='flex flex-col'>
                                                                                                <div className='flex items-end bg-purple-500 text-white rounded-md m-2 p-2 w-auto typewriter text-sm'>
                                                                                                    <p className=' whitespace-pre-wrap ' dangerouslySetInnerHTML={{ __html: item.content.toString().replace(/(?:\r\n|\r|\n)/g, "<br />") }} >
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    }
                                                                                </Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        this.state.ai_loading &&
                                                                        <div className={'flex justify-start'}>
                                                                            <div id={"index_" + (this.state.OpenAiResult.length + 1)} className='flex flex-col ml-2 mt-2'>
                                                                                <div className='flex items-center justify-start'>
                                                                                    <div className='bg-gray-100 rounded-full flex text-purple-500 justify-center items-center h-7 w-7'>
                                                                                        <SparklesIcon className="h-5 w-5" aria-hidden="true" />
                                                                                    </div>
                                                                                    <span className='mt-1 ml-1 text-sm font-bold text-left'>AI-credo:</span>
                                                                                </div>
                                                                                <div className='flex items-start bg-gray-100 text-white rounded-md m-2 p-2 w-20 relative'>
                                                                                    <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-800 opacity-75 top-0 left-0"></span>
                                                                                    <div className=' w-4 h-4 rounded-full bg-gray-300 animate-pulse mr-2'>
                                                                                    </div>
                                                                                    <div className=' w-4 h-4 rounded-full bg-gray-500 animate-pulse mr-2'>
                                                                                    </div>
                                                                                    <div className=' w-4 h-4 rounded-full bg-gray-700 animate-pulse'>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='w-full'>
                                                                <div className='flex flex-row h-full mt-2 pb-2'>
                                                                    <div className='flex flex-1 w-full'>
                                                                        <div className='w-full inline-flex relative'>

                                                                            <textarea
                                                                                rows={2}
                                                                                className={" bg-custom-input rounded-md block w-full border-1.5 pr-10 px-2 py-2 focus:outline-none sm:text-sm"}
                                                                                value={this.state.searchOpenAi}
                                                                                onChange={async (event) => {
                                                                                    if (event.target.value !== "") { this.functions.scroll(); }
                                                                                    if (!this.state.ai_loading) { await this.promisedSetState({ searchOpenAi: event.target.value }) }

                                                                                }}
                                                                                onKeyDown={async (event) => {
                                                                                    try {
                                                                                        if (event.keyCode === 13 && this.state.searchOpenAi !== "" && !this.state.ai_loading) {
                                                                                            if (!event.shiftKey) {
                                                                                                await this.functions.scroll();
                                                                                                this.functions.openAi(false);
                                                                                            }
                                                                                        }
                                                                                    } catch (e) { }
                                                                                }}
                                                                            />
                                                                            <button
                                                                                onClick={async () => {
                                                                                    if (this.state.searchOpenAi !== "" && !this.state.ai_loading) {
                                                                                        this.functions.scroll();
                                                                                        this.functions.openAi(false);
                                                                                    }
                                                                                }}
                                                                                className={(this.state.ai_loading ? "bg-gray-300 cursor-not-allowed" : "bg-purple-500 hover:bg-purple-600 cursor-pointer") + " items-center h-full inline-flex shadow absolute right-0 bottom-0 justify-center rounded-md border border-transparent  py-2 px-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                            >
                                                                                <PaperAirplaneIcon className='h-5 w-5' style={{ transform: "rotate(90deg)" }} />
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div >
                                                    }
                                                </div>
                                            </div>
                                            <div className={`flex flex-col w-120 bg-white shadow-2xl z-54 relative ${!this.state.open_chat ? "rounded-l-md" : "rounded-none"}`}>
                                                <div className='absolute right-0 top-0 z-60 max-w-12 flex flex-col jusify-center items-center h-full'>
                                                    <div className='flex flex-1 border-r-1.5 h-full w-full'></div>
                                                    <div className='flex flex-1 flex-col h-auto w-full'>
                                                        {this.state.preview_data &&
                                                            this.state.preview_data.utm_channels.map((item, index) => (
                                                                <>
                                                                    <div
                                                                        onClick={() => {
                                                                            this.props.onChannel(item);
                                                                        }}
                                                                        key={item}
                                                                        style={item === this.state.preview_data.channel_menu ? { borderRightColor: "transparent" } : {}}
                                                                        className={(item === this.state.preview_data.channel_menu ? "bg-gray-50 scale-110" : "bg-white border-r-1.5 ") + " h-12 transition duration-500 ease-in-out  transform  border-b-1.5 border-t-1.5 border-l-1.5 justify-center flex py-8 px-2 items-center rounded-l-lg cursor-pointer overflow-hidden" + (this.state.preview_data.validation[item] ? " border-red-500" : "")}
                                                                        aria-current={item === this.state.preview_data.channel_menu ? 'page' : undefined}
                                                                    >
                                                                        <div className={"bg-" + item + "-500" + " h-7 w-7 rounded-full flex justify-center items-center"}>
                                                                            {
                                                                                item === 'facebook' &&
                                                                                <img className="w-2"
                                                                                    alt=""
                                                                                    src={require('../assets/images/facebook_icon.svg')} />
                                                                            }
                                                                            {
                                                                                item === 'linkedin' &&
                                                                                <img className="w-3"
                                                                                    alt=""
                                                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                                                            }
                                                                            {
                                                                                item === 'twitter' &&
                                                                                <img className="w-3"
                                                                                    alt=""
                                                                                    src={require('../assets/images/twitter_icon.svg')} />
                                                                            }
                                                                            {
                                                                                item === 'tiktok' &&
                                                                                <img className="w-3"
                                                                                    alt=""
                                                                                    src={require('../assets/images/tiktok_icon.png')} />
                                                                            }
                                                                            {
                                                                                item === 'snapchat' &&
                                                                                <img className="w-5"
                                                                                    alt=""
                                                                                    src={require('../assets/images/snapchat.svg')} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className={(index === this.state.preview_data.utm_channels.length - 1 ? "flex-1 " : "") + 'w-full h-2 border-r-1.5'}></div>
                                                                </>
                                                            ))}
                                                    </div>
                                                    <div className='flex flex-1 border-r-1.5 h-full w-full'></div>

                                                </div>
                                                {
                                                    this.state.preview_data && !this.state.preview_data.creative &&
                                                    <div className={" h-12 absolute left-0 -ml-8 mt-40 z-60 rounded-full bg-purple-600" + (this.state.open_chat ? " w-0" : " w-12 transition duration-500 ease-in-out hover:scale-110 transform hover:-translate-y-1")}>
                                                        <div onClick={() => {
                                                            if (!this.state.open_chat) {
                                                                this.setState({
                                                                    open_chat: true
                                                                })
                                                            }
                                                            //this.props.onChat();
                                                        }} className="flex flex-1 justify-center items-center h-full w-full cursor-pointer ">
                                                            <SparklesIcon className="h-6 w-6 text-white" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="flex m-3 items-center">

                                                    {
                                                        this.state.preview_data &&
                                                        <>
                                                            <div className='flex flex-1 w-full'>
                                                                <div className="w-full">
                                                                    <DropdownTailwind
                                                                        channel={this.state.preview_data.preview_channel}
                                                                        label={"Preview"}
                                                                        selected={this.state.preview[this.state.preview_data.preview_channel]}
                                                                        options={Array.isArray(this.state.preview_data.previews[this.state.preview_data.preview_channel]) ? this.state.preview_data.previews[this.state.preview_data.preview_channel].filter((preview) => {
                                                                            if (preview.ratio === "1.91:1") {
                                                                                return this.state.preview_data.merge && this.state.preview_data.merge.files.filter((file) => {
                                                                                    return file.ratio === "1.91:1"
                                                                                }).length > 0
                                                                            } else if (preview.ratio === "1:1") {
                                                                                return this.state.preview_data.merge && this.state.preview_data.merge.files.filter((file) => {
                                                                                    return file.ratio === "1:1"
                                                                                }).length > 0
                                                                            } else if (preview.ratio === "9:16") {
                                                                                return this.state.preview_data.merge && this.state.preview_data.merge.files.filter((file) => {
                                                                                    return file.ratio === "9:16"
                                                                                }).length > 0
                                                                            } else {
                                                                                return true;
                                                                            }
                                                                        }) : []}
                                                                        onChange={(value) => {
                                                                            this.state.preview[this.state.preview_data.preview_channel] = value;
                                                                            this.setState({
                                                                                preview: this.state.preview
                                                                            })
                                                                            // this.props.changePreview(value);
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>
                                                            {
                                                                this.state.preview_data.channel_menu === "facebook" &&
                                                                <div className='flex flex-1 w-full ml-2'>
                                                                    <div className="w-full">
                                                                        <DropdownTailwind
                                                                            channel={this.state.preview_data.preview_channel}
                                                                            label={"Channel"}
                                                                            selected={[{ id: 1, name: "Facebook", value: "facebook" }, { id: 2, name: "Instagram", value: "instagram" }].filter((item) => { return item.value === this.state.preview_data.preview_channel })[0]}
                                                                            options={[{ id: 1, name: "Facebook", value: "facebook" }, { id: 2, name: "Instagram", value: "instagram" }]}
                                                                            onChange={async (value) => {
                                                                                this.state.preview_data.preview_channel = value.value;
                                                                                if (value.value === "instagram") {
                                                                                    if (!["story_instagram", "feed_instagram"].includes(this.state.preview.instagram.value)) {
                                                                                        this.state.preview.instagram = this.state.preview_data.previews.instagram[0];
                                                                                        await this.promisedSetState({
                                                                                            preview: this.state.preview
                                                                                        })
                                                                                    }
                                                                                }
                                                                                await this.promisedSetState({
                                                                                    preview_data: this.state.preview_data
                                                                                })
                                                                                this.props.onPreviewChannel(this.state.preview_data.preview_channel);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {/*
                                                                    <div className="flex flex-1 items-center justify-center">
                                                                        <div onClick={async () => {
                                                                            this.state.preview_data.preview_channel = "facebook";
                                                                            await this.promisedSetState({
                                                                                preview_data: this.state.preview_data
                                                                            });
                                                                            

                                                                        }}
                                                                            className={(this.state.preview_data.preview_channel === "facebook" ? ("bg-facebook-500 text-white") : "text-white bg-gray-300 hover:bg-gray-400 cursor-pointer") + " py-2 px-2 flex flex-row bg-custom-input overflow-hidden rounded-md items-center"}>
                                                                            <img className="w-2"
                                                                                src={require('../assets/images/facebook_icon.svg')} />
                                                                            {
                                                                                <span
                                                                                    className={"px-2 text-sm font-medium w-24 text-center capitalize"}>
                                                                                    Facebook
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex flex-1 items-center justify-center">
                                                                        <div onClick={async () => {
                                                                            this.state.preview_data.preview_channel = "instagram";
                                                                            await this.promisedSetState({
                                                                                preview_data: this.state.preview_data
                                                                            });
                                                                            this.props.onPreviewChannel(this.state.preview_data.preview_channel);

                                                                        }}
                                                                            className={(this.state.preview_data.preview_channel === "instagram" ? ("bg-instagram-500 text-white") : "text-white bg-gray-300 hover:bg-gray-400 cursor-pointer") + " py-2 px-2 flex flex-row bg-custom-input overflow-hidden rounded-md items-center"}>
                                                                            <img className="w-5"
                                                                                src={require('../assets/images/instagram_icon.svg')} />
                                                                            {
                                                                                <span
                                                                                    className={"px-2 text-sm font-medium w-24 text-center capitalize"}>
                                                                                    Instagram
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                        */}
                                                                </div>
                                                            }

                                                        </>
                                                    }
                                                </div>
                                                <div className="w-full flex flex-1 overflow-hidden">
                                                    <div className="w-full relative overflow-y-scroll">
                                                        <div className="flex flex-col justify-center items-center">
                                                            {
                                                                this.state.preview_data && this.state.preview_data.preview_channel === "tiktok" && this.state.preview_data.files.filter((item) => { return item.type === "image" }).length > 0 &&
                                                                <div className="flex-1 w-full p-3 ml-5 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                                                    OBS! Image format is not supported.
                                                                </div>
                                                            }
                                                            {
                                                                this.state.preview_data && this.state.preview_data.merge &&
                                                                <div className="mr-4">
                                                                    <PreviewSectionFacebook
                                                                        ad={{
                                                                            headlines: this.state.preview_data.merge.headlines,
                                                                            bodies: this.state.preview_data.merge.bodies,
                                                                            descriptions: this.state.preview_data.merge.descriptions,
                                                                            caption: this.state.preview_data.caption,
                                                                            links: this.state.preview_data.merge.links,
                                                                            call_to_actions: this.state.preview_data.merge.call_to_actions,
                                                                            page: this.state.preview_data.preview_channel === "instagram" ? (this.state.preview_data.page.instagram.id !== 0 ? this.state.preview_data.page.instagram : { id: 0, name: "Select page" }) : (this.state.preview_data.page.facebook.id !== 0 ? this.state.preview_data.page.facebook : { id: 0, name: "Select page" }),
                                                                            ad_type: { id: 1, name: 'Merge ad', value: "merge_ad" },
                                                                            // preview: this.state.preview_data.merge.image ? { value: "image_" + this.state.preview[this.state.preview_data.preview_channel].value, ratio: this.state.preview[this.state.preview_data.preview_channel].ratio } : { value: "video_" + this.state.preview[this.state.preview_data.preview_channel].value, ratio: this.state.preview[this.state.preview_data.preview_channel].ratio },
                                                                            preview: this.state.preview_data.merge.image ? { value: "image_" + this.state.preview[this.state.preview_data.preview_channel].value, ratio: this.state.preview[this.state.preview_data.preview_channel].ratio } : { value: "video_" + this.state.preview[this.state.preview_data.preview_channel].value, ratio: this.state.preview[this.state.preview_data.preview_channel].ratio },
                                                                            slides: this.state.preview_data.merge.files.map((inner_file) => {
                                                                                let slide = {};
                                                                                slide = inner_file;
                                                                                slide.image = (inner_file.type === "image");
                                                                                slide.video = (inner_file.type === "video");
                                                                                if (inner_file.type === "image") {
                                                                                    slide.picture = inner_file.url;
                                                                                }
                                                                                if (inner_file.type === "video") {
                                                                                    //slide.video_data = inner_file;
                                                                                }
                                                                                return slide;
                                                                            }),
                                                                        }}
                                                                        channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                    // is_post={true}
                                                                    />
                                                                </div>
                                                            }

                                                            {
                                                                this.state.preview_data && this.state.preview_data.files && !this.state.preview_data.merge && !this.state.preview_data.instantExperience &&
                                                                this.state.preview_data.files.map((item, index) => {
                                                                    let ad = {
                                                                        picture: item.url,
                                                                        video: item.url,
                                                                        colors: item.colors ? item.colors : [],
                                                                        title: (this.state.preview_data.preview_channel === "instagram" ? this.state.preview_data.headline_by_channels.facebook : this.state.preview_data.headline_by_channels[this.state.preview_data.preview_channel]),
                                                                        // title: (this.state.preview_data.preview_channel === "instagram" ? this.state.preview_data.headline_by_channels.facebook : this.state.preview_data.preview_channel === "linkedin" && item.type === "posts" ? item.title : this.state.preview_data.headline_by_channels[this.state.preview_data.preview_channel]),
                                                                        body: (this.state.preview_data.preview_channel === "tiktok" || this.state.preview_data.preview_channel === "snapchat") && !this.state.preview_data.body ? "" : item.type === "posts" ? item.body : (this.state.preview_data.preview_channel === "instagram" ? this.state.preview_data.body_by_channels.facebook : this.state.preview_data.body_by_channels[this.state.preview_data.preview_channel]),
                                                                        description: this.state.preview_data.description,
                                                                        caption: this.state.preview_data.caption,
                                                                        link: this.state.preview_data.website_by_channels[this.state.preview_data.preview_channel],
                                                                        cta: !this.state.preview_data.cta ? { id: 0, name: "No Button", type: "NO_BUTTON" } : this.state.preview_data.preview_channel === "instagram" && this.state.preview_data.cta.facebook && this.state.preview_data.cta.facebook.id !== 0 ? this.state.preview_data.cta.facebook : (this.state.preview_data.cta[this.state.preview_data.preview_channel] && this.state.preview_data.cta[this.state.preview_data.preview_channel].id !== 0 ? this.state.preview_data.cta[this.state.preview_data.preview_channel] : { id: 0, name: "No Button", type: "NO_BUTTON" }),
                                                                        page: this.state.preview_data.preview_channel === "instagram" ? (this.state.preview_data.page.instagram.id !== 0 ? this.state.preview_data.page.instagram : { id: 0, name: "Select page" })
                                                                            : (this.state.preview_data.page[this.state.preview_data.preview_channel] && this.state.preview_data.page[this.state.preview_data.preview_channel].id !== 0 ? this.state.preview_data.page[this.state.preview_data.preview_channel]
                                                                                : { id: 0, name: "Select page" }),
                                                                        ad_type: (
                                                                            (this.state.preview_data.catalog || this.state.preview_data.carousel) ?
                                                                                { id: 1, name: (this.state.preview_data.catalog ? "Catalog ad" : "Carousel ad"), value: (this.state.preview_data.catalog ? "catalog_ad" : "carousel_ad") } :
                                                                                (item.type === "image" ?
                                                                                    { id: 1, name: 'Image ad', value: "image_ad" } :
                                                                                    (item.type === "video" ?
                                                                                        { id: 2, name: 'Video ad', value: "video_ad" } :
                                                                                        (item.type === "posts" ?
                                                                                            { id: 3, name: 'posts', value: "posts", type: item.ad_type } :
                                                                                            { id: 0, name: 'error ad type', value: "error" }
                                                                                        )
                                                                                    )
                                                                                )
                                                                        ),
                                                                        preview: (
                                                                            (this.state.preview_data.catalog || this.state.preview_data.carousel) ?
                                                                                { value: "carousel_feed" } :
                                                                                (item.type === "image" ?
                                                                                    { value: "image_" + this.state.preview[this.state.preview_data.preview_channel].value } :
                                                                                    (item.type === "video" ?
                                                                                        { value: "video_" + this.state.preview[this.state.preview_data.preview_channel].value } :
                                                                                        (item.type === "posts" && item.files ?
                                                                                            { value: "carousel_feed" } :
                                                                                            (item.ad_type && item.ad_type === "image" ?
                                                                                                { value: "image_feed" } :
                                                                                                { value: "video_feed" }
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                        ),
                                                                        preview_products: item.preview_products ? item.preview_products : [],
                                                                        slides: this.state.preview_data && this.state.preview_data.carousel && !this.state.preview_data.carousel.files ? this.state.preview_data.carousel.slides : this.state.preview_data && this.state.preview_data.carousel && Array.isArray(this.state.preview_data.carousel.files) && this.state.preview_data.carousel.files.length > 0 ?
                                                                            this.state.preview_data.carousel.files.map((inner_file) => {
                                                                                let slide = {};
                                                                                slide = inner_file;
                                                                                if (this.state.preview_data.cta && this.state.preview_data.cta[this.state.preview_data.preview_channel] && this.state.preview_data.cta[this.state.preview_data.preview_channel].id !== 0) {
                                                                                    slide.cta = this.state.preview_data.cta[this.state.preview_data.preview_channel];
                                                                                }
                                                                                slide.headline = inner_file.title;
                                                                                slide.description = inner_file.body;
                                                                                slide.image = (inner_file.type === "image");
                                                                                slide.video = (inner_file.type === "video");
                                                                                if (inner_file.type === "image") {
                                                                                    slide.picture = inner_file.url;
                                                                                }
                                                                                if (inner_file.type === "video") {
                                                                                    //slide.video_data = inner_file;
                                                                                }
                                                                                return slide;
                                                                            }) :
                                                                            [],
                                                                        files: item.type === "posts" && item.files ? item.files : (this.state.preview_data.carousel ? this.state.preview_data.carousel.files : false),
                                                                        file: item

                                                                    };
                                                                    return (
                                                                        <div className="flex flex-1 mt-5">
                                                                            {

                                                                                (this.state.preview_data.preview_channel === "facebook" || this.state.preview_data.preview_channel === "instagram") &&
                                                                                <>
                                                                                    <PreviewSectionFacebook
                                                                                        ad={ad}
                                                                                        channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                                        is_post={ad && ad.ad_type && ad.ad_type.value === "posts" ? true : false}

                                                                                    />
                                                                                </>
                                                                            }
                                                                            {
                                                                                this.state.preview_data.preview_channel === "linkedin" &&
                                                                                <PreviewSectionLinkedin
                                                                                    ad={ad}
                                                                                    channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                                    is_post={ad.is_post ? ad.is_post : false}
                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.preview_data.preview_channel === "twitter" &&
                                                                                <PreviewSectionTwitter
                                                                                    ad={ad}
                                                                                    channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                                />
                                                                            }
                                                                            {
                                                                                ((this.state.preview_data.preview_channel === "tiktok" && item.type === "video") || (this.state.preview_data.preview_channel === "tiktok" && item.type === "posts")) &&
                                                                                <PreviewSectionTikTok
                                                                                    ad={{
                                                                                        video: item.url,
                                                                                        title: this.state.preview_data.headline_by_channels.tiktok,
                                                                                        body: this.state.preview_data.body_by_channels.tiktok,
                                                                                        description: "",
                                                                                        caption: "",
                                                                                        link: this.state.preview_data.website_by_channels.tiktok,
                                                                                        cta: this.state.preview_data.cta.tiktok.id !== 0 ? this.state.preview_data.cta.tiktok : { id: 0, name: "No Button", type: "NO_BUTTON" },
                                                                                        page: this.state.preview_data.page.tiktok.id !== 0 ? this.state.preview_data.page.tiktok : { id: 0, name: "Select page" },
                                                                                        ad_type: { id: 2, name: 'Video ad', value: "video_ad" },
                                                                                        preview: { value: "video_" + this.state.preview[this.state.preview_data.preview_channel].value }
                                                                                    }}
                                                                                    channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                                    is_post={ad.is_post ? ad.is_post : false}

                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.preview_data.preview_channel === "snapchat" && item.type === "video" &&
                                                                                <>
                                                                                    <PreviewSectionSnapchat
                                                                                        ad={{
                                                                                            video: item.url,
                                                                                            title: this.state.preview_data.headline_by_channels && this.state.preview_data.headline_by_channels.snapchat ? this.state.preview_data.headline_by_channels.snapchat : "",
                                                                                            body: this.state.preview_data.body_by_channels && this.state.preview_data.body_by_channels.snapchat ? this.state.preview_data.body_by_channels.snapchat : "",
                                                                                            description: "",
                                                                                            caption: "",
                                                                                            link: this.state.preview_data.website_by_channels.snapchat,
                                                                                            cta: this.state.preview_data.cta.snapchat.id !== 0 ? this.state.preview_data.cta.snapchat : { id: 0, name: "No Button", type: "NO_BUTTON" },
                                                                                            page: this.state.preview_data.page.snapchat.id !== 0 ? this.state.preview_data.page.snapchat : { id: 0, name: "Select page" },
                                                                                            ad_type: { id: 2, name: 'Video ad', value: "video_ad" },
                                                                                            preview: { value: "video_" + this.state.preview[this.state.preview_data.preview_channel].value },
                                                                                            brandname: this.state.preview_data.snapchat_brandname,
                                                                                            attachment: this.state.preview_data.snapchat_attachment,
                                                                                            status: this.state.preview_data.snapchat_status ? this.state.preview_data.snapchat_status.value : "",
                                                                                            shareable: this.state.preview_data.snapchat_shareable ? this.state.preview_data.snapchat_shareable.value : "",
                                                                                            autofill: this.state.preview_data.snapchat_autofill ? this.state.preview_data.snapchat_autofill.value : "",
                                                                                            prefetching: this.state.preview_data.snapchat_prefetching ? this.state.preview_data.snapchat_prefetching.value : "",
                                                                                            snapchat_phone: this.state.preview_data.snapchat_phone ? this.state.preview_data.snapchat_phone : "",
                                                                                            snapchat_message: this.state.preview_data.snapchat_message ? this.state.preview_data.snapchat_message : "",
                                                                                        }}
                                                                                        channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                                    />
                                                                                </>
                                                                            }
                                                                            {
                                                                                this.state.preview_data.preview_channel === "snapchat" && item.type === "image" &&
                                                                                <>
                                                                                    <PreviewSectionSnapchat
                                                                                        ad={{
                                                                                            picture: item.url,
                                                                                            title: this.state.preview_data.headline_by_channels && this.state.preview_data.headline_by_channels.snapchat ? this.state.preview_data.headline_by_channels.snapchat : "",
                                                                                            body: this.state.preview_data.body_by_channels && this.state.preview_data.body_by_channels.snapchat ? this.state.preview_data.body_by_channels.snapchat : "",
                                                                                            description: "",
                                                                                            caption: "",
                                                                                            link: this.state.preview_data.website_by_channels.snapchat,
                                                                                            cta: this.state.preview_data.cta.snapchat.id !== 0 ? this.state.preview_data.cta.snapchat : { id: 0, name: "No Button", type: "NO_BUTTON" },
                                                                                            page: this.state.preview_data.page.snapchat.id !== 0 ? this.state.preview_data.page.snapchat : { id: 0, name: "Select page" },
                                                                                            ad_type: { id: 1, name: 'Image ad', value: "image_ad" },
                                                                                            preview: { value: "image_" + this.state.preview[this.state.preview_data.preview_channel].value },
                                                                                            brandname: this.state.preview_data.snapchat_brandname,
                                                                                            attachment: this.state.preview_data.snapchat_attachment,
                                                                                            status: this.state.preview_data.snapchat_status ? this.state.preview_data.snapchat_status.value : "",
                                                                                            shareable: this.state.preview_data.snapchat_shareable ? this.state.preview_data.snapchat_shareable.value : "",
                                                                                            autofill: this.state.preview_data.snapchat_autofill ? this.state.preview_data.snapchat_autofill.value : "",
                                                                                            prefetching: this.state.preview_data.snapchat_prefetching ? this.state.preview_data.snapchat_prefetching.value : "",
                                                                                            snapchat_phone: this.state.preview_data.snapchat_phone ? this.state.preview_data.snapchat_phone : "",
                                                                                            snapchat_message: this.state.preview_data.snapchat_message ? this.state.preview_data.snapchat_message : "",

                                                                                        }}
                                                                                        channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                                    />
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    )

                                                                })

                                                            }
                                                            {
                                                                this.state.preview_data && this.state.preview_data.files && this.state.preview_data.instantExperience &&
                                                                this.state.preview_data.files.map((item) => {
                                                                    let ad = {
                                                                        collection_ad: true,
                                                                        picture: this.state.preview_data.instantExperience.elements && this.state.preview_data.instantExperience.elements.photo ? this.state.preview_data.instantExperience.elements.photo : (item.type === "image" ? item.url : null),
                                                                        video: this.state.preview_data.instantExperience.elements && this.state.preview_data.instantExperience.elements.video ? this.state.preview_data.instantExperience.elements.video : (item.type === "video" ? item.url : null),
                                                                        colors: item.colors ? item.colors : [],
                                                                        title: (this.state.preview_data.preview_channel === "instagram" ? this.state.preview_data.headline_by_channels.facebook : this.state.preview_data.headline_by_channels[this.state.preview_data.preview_channel]),
                                                                        body: item.type === "posts" ? item.body : (this.state.preview_data.preview_channel === "instagram" ? this.state.preview_data.body_by_channels.facebook : this.state.preview_data.body_by_channels[this.state.preview_data.preview_channel]),
                                                                        description: this.state.preview_data.description,
                                                                        caption: this.state.preview_data.caption,
                                                                        link: this.state.preview_data.website_by_channels[this.state.preview_data.preview_channel],
                                                                        cta: this.state.preview_data.preview_channel === "instagram" && this.state.preview_data.cta.facebook && this.state.preview_data.cta.facebook.id !== 0 ? this.state.preview_data.cta.facebook : (this.state.preview_data.cta[this.state.preview_data.preview_channel] && this.state.preview_data.cta[this.state.preview_data.preview_channel].id !== 0 ? this.state.preview_data.cta[this.state.preview_data.preview_channel] : { id: 0, name: "No Button", type: "NO_BUTTON" }),
                                                                        page: this.state.preview_data.preview_channel === "instagram" ? (this.state.preview_data.page.instagram.id !== 0 ? this.state.preview_data.page.instagram : { id: 0, name: "Select page" }) : (this.state.preview_data.page[this.state.preview_data.preview_channel] && this.state.preview_data.page[this.state.preview_data.preview_channel].id !== 0 ? this.state.preview_data.page[this.state.preview_data.preview_channel] : { id: 0, name: "Select page" }),
                                                                        ad_type: ({ id: 1, name: "Catalog ad", value: "catalog_ad" }),
                                                                        preview: (
                                                                            (this.state.preview_data.catalog || this.state.preview_data.carousel) ?
                                                                                { value: "carousel_feed" } :
                                                                                (item.type === "image" ?
                                                                                    { value: "image_" + this.state.preview[this.state.preview_data.preview_channel].value } :
                                                                                    (item.type === "video" ?
                                                                                        { value: "video_" + this.state.preview[this.state.preview_data.preview_channel].value } :
                                                                                        (item.type === "posts" && item.files ?
                                                                                            { value: "carousel_feed" } :
                                                                                            (item.ad_type && item.ad_type === "image" ?
                                                                                                { value: "image_feed" } :
                                                                                                { value: "video_feed" }
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                        ),
                                                                        instantExperience: this.state.preview_data.instantExperience,
                                                                        slides: [],
                                                                        files: item.type === "posts" && item.files ? item.files : (this.state.preview_data.carousel ? this.state.preview_data.carousel.files : false),
                                                                        file: item
                                                                    };

                                                                    return (
                                                                        <div className="mr-4">
                                                                            {

                                                                                (this.state.preview_data.preview_channel === "facebook" || this.state.preview_data.preview_channel === "instagram") &&

                                                                                <PreviewSectionFacebook
                                                                                    ad={ad}
                                                                                    channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                            {
                                                                this.state.preview_data && this.state.preview_data.creative &&
                                                                <div className="mr-4">
                                                                    {

                                                                        (this.state.preview_data.preview_channel === "facebook" || this.state.preview_data.preview_channel === "instagram") &&

                                                                        <PreviewSectionFacebook
                                                                            ad={this.state.preview_data.creative}
                                                                            channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                            for_report={true}
                                                                            is_post={this.state.preview_data.creative.is_post}
                                                                            preview_placement={this.state.preview[this.state.preview_data.preview_channel].value}
                                                                        />
                                                                    }
                                                                    {
                                                                        this.state.preview_data.preview_channel === "linkedin" &&
                                                                        <PreviewSectionLinkedin
                                                                            ad={this.state.preview_data.creative}
                                                                            channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                            for_report={true}
                                                                            preview_placement={"fake"}
                                                                        />
                                                                    }
                                                                    {
                                                                        this.state.preview_data.preview_channel === "snapchat" &&
                                                                        <PreviewSectionSnapchat
                                                                            ad={this.state.preview_data.creative}
                                                                            channel={{ name: this.state.preview_data.preview_channel, value: this.state.preview_data.preview_channel }}
                                                                            for_report={true}
                                                                            preview_placement={"story"}
                                                                        />
                                                                    }
                                                                    {
                                                                        this.state.preview_data.preview_channel === "tiktok" &&
                                                                        <PreviewTikTokFeed
                                                                            video={this.state.preview_data.creative.video || null}
                                                                            cta={tiktok_cta.find((cta => cta.id.includes(this.state.preview_data.creative.call_to_action)))}
                                                                            page={(this.state.preview_data.creative.page) || null}
                                                                            text={(this.state.preview_data.creative.text) || null}
                                                                            thumbnail={null}
                                                                        />
                                                                    }
                                                                </div>
                                                            }
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    }
                                    <div className={(this.state.open_chat ? "flex-1 " : "flex-2 ") + "transition-all duration-100 flex h-full flex-col bg-white z-55"}>
                                        <div className="py-3 px-4 sm:px-6 bg-purple-900 ">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="flex flex-1 flex-row pr-14">
                                                    <div className="text-xl flex-1 flex leading-6 font-semibold text-white">
                                                        {this.state.title}
                                                    </div>
                                                    {
                                                        this.state.reach && this.state.reach.estimate_ready &&
                                                        <div className="flex">
                                                            <div className="flex flex-row">
                                                                <UserGroupIcon className="w-6 h-6 text-white mr-4" />
                                                                <div className="">
                                                                    <div className="text-xl leading-6 font-semibold text-white">
                                                                        {!this.state.reach.estimate_not_count ?
                                                                            (this.state.reach.users_lower_bound + "-" + this.state.reach.users_upper_bound) :
                                                                            <div className="text-xs font-normal">Potential reach estimates may not be available depending on your audience configuration.</div>
                                                                        }

                                                                    </div>
                                                                    <div className="text-xs text-white">
                                                                        Estimated audience size
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </Dialog.Title>
                                                <div onClick={() => {
                                                    if (this.props.onCancel && !this.state.loader) {
                                                        this.props.onCancel();
                                                    }
                                                }} className="ml-3 cursor-pointer h-8 w-8 flex justify-center transition-colors ease-in duration-75 hover:bg-red-700 text-white items-center rounded-full bg-red-500 ">
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-1 flex-col overflow-hidden">
                                            <div className="flex flex-1 relative flex-col justify-between overflow-x-scroll">
                                                <div className={(this.props.noPadding ? "" : "p-6") + " pb-0 pt-6 h-full bg-gray-50"}>
                                                    {this.props.children}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex relative flex-shrink-0 justify-end p-6 border-t">
                                            {
                                                this.state.error &&
                                                <div style={{ bottom: '90px' }} className="absolute inset-x-0 px-6">
                                                    <div className="rounded-md bg-red-100 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <p className="text-sm font-medium text-red-800">{this.state.error}</p>
                                                            </div>
                                                            <div className="ml-auto pl-3">
                                                                <div className="-mx-2 -my-2">
                                                                    <button
                                                                        onClick={() => {
                                                                            if (this.props.onError) {
                                                                                this.props.onError(false);
                                                                            }
                                                                        }}
                                                                        type="button"
                                                                        className="inline-flex bg-green-50 rounded-md p-2 text-red-500 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-100 focus:ring-red-600"
                                                                    >
                                                                        <span className="sr-only">Dismiss</span>
                                                                        <XIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.thirdButton &&
                                                <div className="flex flex-1 justify-start">
                                                    <button
                                                        className={(this.state.thirdButtonWhite ? "rounded-md relative border-1.5 py-2 w-48 text-sm bg-white-500 font-medium text-gray-800 hover:bg-gray-200 focus:outline-none" : "rounded-md relative shadow py-2 px-4 text-sm bg-purple-500 font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2")}
                                                        onClick={() => {
                                                            if (this.props.onThirdButton && !this.state.thirdButtonLoader) {
                                                                this.props.onThirdButton();
                                                            }
                                                        }}
                                                    >
                                                        {this.props.thirdButtonText}
                                                        {
                                                            this.state.thirdButtonLoader &&
                                                            <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                            }
                                            {
                                                this.state.secondaryButton &&
                                                <button
                                                    className="rounded-md shadow relative overflow-hidden border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                    onClick={() => {
                                                        if (this.props.onSecondarySubmit && !this.state.loader) {
                                                            this.props.onSecondarySubmit();
                                                        }
                                                    }}
                                                >
                                                    {this.state.secondaryButtonText}
                                                    {
                                                        this.state.loaderSecondary &&
                                                        <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </button>
                                            }
                                            {
                                                !this.state.noSubmit &&
                                                <button
                                                    onClick={() => {
                                                        if (this.props.onSubmit && !this.state.loader && !this.state.disabled) {
                                                            this.props.onSubmit();
                                                        }
                                                    }}
                                                    className={(this.state.success ? "cursor-pointer bg-green-700 text-white" : (!this.state.disabled ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600")) + " shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                    }
                                                >
                                                    {this.state.success ? "Success!" : this.state.submitButtonText}
                                                    {
                                                        this.state.loader &&
                                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default SlideoutTailwindAsset;
