import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import SwitchTailwind from './switchTailwind';
import currencies from "../assets/json/currencies.json";
import CopyButton from './copyButton';

class AccountCm360 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            me: {},
            agency: {},
            agency_user: {},
            message: "",
            throttling: {},
            loading: false,
            loading_user_profiles: false,
            loading_advertisers: false,
            is_manager: false,
            user_profile: {},
            user_profiles: [],
            advertiser: {},
            advertisers: [],
            agents: [],
            agent: { id: 0, name: "Select agent" },
            ocid: "",
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            healthcheck: {},
            include_adaccounts: { id: 1, name: "Include all" },
            include_campaigns: { id: 1, name: "Include all" },
            owner: false,
            disabled: false,
            syncMessage: "",
            syncManagers: false,
            currency: {},
            currencies: currencies,
            advertisers_next: null
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            agency_user: this.props.agencyUser ? this.props.agencyUser : {},
            agency: this.props.agency ? this.props.agency : {},
            me: this.props.me ? this.props.me : {},
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.cm360 && !this.state.agency.cm360) {
            this.functions.getAgents(true);
        }
    }

    async componentWillReceiveProps(nextprops) {
        await this.promisedSetState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            agency_user: nextprops.agencyUser ? nextprops.agencyUser : {},
            agency: nextprops.agency ? nextprops.agency : {},
            me: nextprops.me ? nextprops.me : {},
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    componentWillUnmount() {

    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    channel: "cm360",
                    token: token
                });

                //TEMP SOLUTION
                window.location.reload();
                //TEMP SOLUTION

                await self.promisedSetState({
                    user: { ...this.state.user, ...{ cm360: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "cm360"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ cm360: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        listUserProfiles: async () => {
            await this.promisedSetState({
                loading_user_profiles: true
            });
            try {
                let response = await this.calls.listUserProfiles();
                await this.promisedSetState({
                    user_profiles: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_user_profiles: false
            });
        },
        listAdvertisers: async (init) => {
            await this.promisedSetState({
                loading_advertisers: true,
                loading_advertisers_pagination: this.state.advertisers_next
            });
            try {
                let response = await this.calls.listAdvertisers(init);
                await this.promisedSetState({
                    advertisers: init ? response.data : this.state.advertisers.concat(response.data),
                    advertisers_next: response.meta.nextPageToken
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_advertisers: false,
                loading_advertisers_pagination: false
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.advertiser.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    user_profile: this.state.user_profile,
                    advertiser: this.state.advertiser,
                    ocid: this.state.ocid
                });
                this.state.client.cm360 = {
                    defaultAgent: this.state.agent,
                    userProfileId: this.state.user_profile.id,
                    userProfileName: this.state.user_profile.name,
                    advertiserId: this.state.advertiser.id,
                    advertiserName: this.state.advertiser.name,
                    floodlight_configuration_id: this.state.advertiser.floodlight_configuration_id,
                    currency: this.state.currency.id,
                    ocid: this.state.ocid
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    user_profile: {},
                    currency: {},
                    client: this.state.client
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.cm360;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    user_profile: {},
                    ocid: "",
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        connectToAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                let response = await this.calls.connectToAgency({
                    channel: 'cm360'
                });
                this.state.agency.cm360 = {
                    cm360_connected: response.data.cm360_connected,
                    matching_user_profiles: response.data.matching_user_profiles,
                    matching_accounts: response.data.matching_accounts,
                    authenticated: true
                };
                this.promisedSetState({
                    user_profile: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromAgency({
                    channel: 'cm360'
                });
                this.state.agency.cm360 = { authenticated: false }
                await this.promisedSetState({
                    user_profile: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/cm360/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/cm360/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        connectToAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/connectAgencyAccount";
            return apiRegister.call(options, url);
        },
        disconnectFromAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyAccount";
            return apiRegister.call(options, url);
        },
        listUserProfiles: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/cm360/userProfiles?client=" + this.state.client.id + (this.state.agency_user.id ? ("&agent=" + this.state.agency_user.id) : ("&agent=" + this.state.agent.id));
            return apiRegister.call(options, url);
        },
        listAdvertisers: (init) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/cm360/advertisers?client=" + this.state.client.id + "&user_profile=" + this.state.user_profile.id + "&agent=" + this.state.agent.id + (!init && this.state.advertisers_next !== "" ? "&nextPageToken=" + this.state.advertisers_next : "");
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=cm360";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative">

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout CM 360"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 rounded-md flex justify-center items-center">
                        <div className="w-6 h-6">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/cm360-logo.png') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            CM 360
                        </div>
                        {
                            !this.state.agency.cm360 &&
                            !this.state.user.cm360 &&
                            <div className="text-sm text-gray-500">
                                Connect CM 360 to this client
                            </div>
                        }
                        {
                            !this.state.agency.cm360 &&
                            this.state.user.cm360 &&
                            <div className="text-sm text-gray-500">
                                Connect CM 360 to this account
                            </div>
                        }
                        {
                            this.state.agency.cm360 &&
                            <div className="text-sm text-gray-500">
                                Connect CM 360 to agency
                            </div>
                        }
                    </div>
                    {
                        (
                            this.state.agency.cm360 ||
                            (
                                !this.state.user.id &&
                                this.state.client.cm360 &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.cm360 && this.state.client.cm360.defaultAgent && this.state.client.cm360.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={!this.state.agency.cm360 ? (this.state.client.cm360 ? true : false) : this.state.agency.cm360.authenticated}
                                onSwitch={async () => {
                                    if (this.state.agency.cm360) {
                                        if (!this.state.agency.cm360.authenticated) {
                                            this.functions.connectToAgency();
                                        } else {
                                            this.functions.disconnectFromAgency();
                                        }
                                    } else {
                                        if (!this.state.client.cm360) {
                                            this.state.client.cm360 = {};
                                            await this.promisedSetState({
                                                client: this.state.client
                                            });
                                        } else {
                                            this.functions.disconnectFromClient();
                                        }
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account || this.state.syncManagers) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    !this.state.agency.cm360 &&
                    !this.state.user.id &&
                    < div className="mt-5">
                        <DropdownTailwind
                            label={"Default agent"}
                            small={false}
                            locked={this.state.client.cm360}
                            skipInternalSearch={this.state.is_manager}
                            loader={this.state.loading_agents}
                            loadingPagination={this.state.loading_agents_pagination}
                            loadingSearch={this.state.loading_agents_search}
                            total={this.state.total}
                            searchInput={true}
                            placeholder={"..."}
                            defaultOptions={this.state.me.cm360 && this.state.me.cm360.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                            pagination={this.state.total > (this.state.page * this.state.limit)}
                            selected={this.state.client.cm360 && this.state.client.cm360.defaultAgent ? this.state.client.cm360.defaultAgent : this.state.agent}
                            options={this.state.agents}
                            onChange={async (value) => {
                                await this.promisedSetState({
                                    agent: value,
                                    user_profile: {},
                                    advertiser: {},
                                    ocid: ""
                                });
                                this.functions.listUserProfiles();
                            }}
                            onPagination={async () => {
                                if (!this.state.loading_agents_pagination) {
                                    await this.promisedSetState({
                                        page: this.state.page + 1
                                    });
                                    this.functions.getAgents(false, true, false);
                                }
                            }}
                            onSearch={async (value) => {
                                if (value && value === "") {
                                    await this.promisedSetState({
                                        search: "",
                                        page: 1
                                    });
                                    this.functions.getAgents(false, false, true);
                                } else {
                                    if (this.state.throttling.current) {
                                        //SKIP
                                    } else {
                                        if (value && value === "") {
                                            await this.promisedSetState({
                                                search: value,
                                                page: 1
                                            });
                                            this.functions.getAgents(false, false, true);
                                        } else {
                                            this.state.throttling.current = true;
                                            await this.promisedSetState({
                                                search: value,
                                                throttling: this.state.throttling
                                            });
                                            setTimeout(async () => {
                                                this.state.throttling.current = false;
                                                await this.promisedSetState({
                                                    throttling: this.state.throttling
                                                });
                                                await this.promisedSetState({
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            }, 300);
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                }
                {
                    ((this.state.client.id && (this.state.client.cm360 || this.state.agent.id !== 0))) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                label={!this.state.agency.cm360 ? (this.state.client.cm360 && this.state.client.cm360.userProfileId ? "User profile" : "User profiles") : (this.state.agency.cm360 && this.state.agency.cm360.userProfileId ? "User profile" : "User profiles")}
                                loader={this.state.loading_user_profiles}
                                searchInput={true}
                                locked={!this.state.agency.cm360 ? this.state.client.cm360 : this.state.agency.cm360.userProfileId}
                                selected={!this.state.agency.cm360 ? (this.state.client.cm360 && this.state.client.cm360.userProfileId ? { name: this.state.client.cm360.userProfileName } : (this.state.user_profile.id ? this.state.user_profile : { name: "Select user profile" })) : (this.state.agency.cm360 && this.state.agency.cm360.userProfileId ? { name: this.state.agency.cm360.userProfileName } : (this.state.user_profile.id ? this.state.user_profile : { name: "Select user profile" }))}
                                options={this.state.user_profiles}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        user_profile: value,
                                        advertiser: {},
                                        ocid: ""
                                    });
                                    if (!this.state.agency.cm360) {
                                        this.functions.listAdvertisers(true);
                                    }
                                }}
                            />
                        </div>
                        <CopyButton
                            buttonText="Copy"
                            disabled={!((this.state.client.cm360 && this.state.client.cm360.userProfileId) || this.state.user_profile.id)}
                            copyText={(this.state.client.cm360 && this.state.client.cm360.userProfileId) ? this.state.client.cm360.userProfileId : this.state.user_profile.id}
                        />
                    </div>
                }
                {
                    !this.state.agency.cm360 &&
                    this.state.client.id &&
                    (this.state.user_profile.id || (this.state.client.cm360 && this.state.client.cm360.userProfileId)) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                label={this.state.client.cm360 && this.state.client.cm360.clientId ? "Advertiser" : "Advertisers"}
                                skipInternalSearch={false}
                                loader={this.state.loading_advertisers}
                                loadingPagination={this.state.loading_advertisers_pagination}
                                searchInput={true}
                                pagination={this.state.advertisers_next}
                                dontShowPaginationAmount={true}
                                placeholder={"..."}
                                selected={this.state.client.cm360 && this.state.client.cm360.advertiserId ? { name: this.state.client.cm360.advertiserName } : (this.state.advertiser.id ? this.state.advertiser : { name: "Select Advertiser" })}
                                options={this.state.advertisers}
                                onChange={async (value) => {
                                    this.setState({
                                        advertiser: value
                                    });
                                }}
                                onPagination={async () => {
                                    this.functions.listAdvertisers();
                                }}
                                onSearch={async (value) => {

                                }}
                            />
                        </div>
                        <CopyButton
                            buttonText="Copy"
                            disabled={!((this.state.client.cm360 && this.state.client.cm360.advertiserId) || this.state.advertiser.id)}
                            copyText={(this.state.client.cm360 && this.state.client.cm360.advertiserId) ? this.state.client.cm360.advertiserId : this.state.advertiser.id}
                        />
                    </div>
                }
                {
                    this.state.client.id &&
                    (this.state.advertiser.id || this.state.client.cm360) &&
                    <div className="mt-5">
                        <DropdownTailwind
                            searchInput={true}
                            label={"Currency"}
                            locked={this.state.client.cm360}
                            selected={this.state.client.cm360 && this.state.client.cm360.advertiserId ? { name: this.state.client.cm360.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                            options={this.state.currencies}
                            onChange={async (value) => {
                                this.setState({
                                    currency: value
                                });
                            }}
                        />
                    </div>
                }
                {
                    ((this.state.agency.cm360 && this.state.agency.cm360.authenticated && this.state.user_profile.id) || (this.state.client.id &&
                        !this.state.client.cm360 &&
                        this.state.advertiser.id &&
                        this.state.user_profile.id)) &&
                    <div className="flex w-full flex-row">
                        <div className="flex flex-1">
                            <button
                                onClick={() => {
                                    if (!this.state.loading_account) {
                                        if (!this.state.agency.cm360) {
                                            this.functions.connectToClient();
                                        } else {
                                            this.functions.connectToAgency();
                                        }
                                    }
                                }}
                                className={(((this.state.agency.cm360 && this.state.agency.cm360.authenticated && this.state.user_profile.id) || this.state.advertiser.id) ? "bg-dv360-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Save CM 360 account</span>
                                {
                                    this.state.loading_account &&
                                    <div className={"w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    </div>
                }
                {
                    !this.state.agency.cm360 &&
                    !this.state.client.id &&
                    (!this.state.user.cm360 || (this.state.user.cm360 && !this.state.user.cm360.authenticated)) &&
                    <div onClick={() => {
                        let self = this;
                        const script = document.createElement('script');
                        script.setAttribute("id", "google_api");
                        script.src = "https://accounts.google.com/gsi/client";
                        script.async = true;
                        script.defer = true;
                        document.body.appendChild(script);

                        script.onload = () => {
                            let client = window.google.accounts.oauth2.initCodeClient({
                                client_id: '990379132046-j4o9gdd0kdlof86lh2adc58rqgb1a4v0.apps.googleusercontent.com',
                                scope: 'https://www.googleapis.com/auth/dfatrafficking https://www.googleapis.com/auth/ddmconversions https://www.googleapis.com/auth/dfareporting',
                                callback: handleCredentialResponse,
                                grant_type: 'authorization_code'
                            });

                            client.requestCode()
                        };

                        const handleCredentialResponse = (response) => {
                            console.log(response)
                            self.functions.login(response.code);
                            document.body.removeChild(script);
                        };
                    }} className="mt-5">
                        <img className="w-48 cursor-pointer" src={require("../assets/images/google_signin_button_2.png")}/>
                    </div>
                }
                {/* TEMP */}
                {
                    false &&
                    !this.state.agency.cm360 &&
                    !this.state.client.id &&
                    (!this.state.user.cm360 || (this.state.user.cm360 && !this.state.user.cm360.authenticated)) &&
                    <div className="mt-5">
                        <button onClick={() => {
                            let self = this;
                            const script = document.createElement('script');
                            script.setAttribute("id", "google_api");
                            script.src = "https://apis.google.com/js/platform.js";
                            script.async = true;
                            script.defer = true;
                            script.onload = () => window.gapi.load('auth2', initClient);
                            document.body.appendChild(script);
                            const initClient = () => {
                                window['gapi'].auth2.init({
                                    client_id: '990379132046-j4o9gdd0kdlof86lh2adc58rqgb1a4v0.apps.googleusercontent.com',
                                    cookiepolicy: 'single_host_origin',
                                    scope: 'https://www.googleapis.com/auth/dfatrafficking https://www.googleapis.com/auth/ddmconversions https://www.googleapis.com/auth/dfareporting'
                                }).then(() => {
                                    const auth = window['gapi'].auth2.getAuthInstance();
                                    auth.grantOfflineAccess().then(res => {
                                        self.functions.login(res.code);
                                        document.body.removeChild(script);
                                    }).catch(function (error) {
                                        document.body.removeChild(script);
                                    });
                                });
                            };
                        }} className={(!(this.state.user.cm360 && this.state.user.cm360.authenticated) ?
                            "cursor-pointer text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                        }
                        >
                            <span>Login with CM 360</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.cm360 && this.state.user.cm360.authenticated) ? "" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.client.id &&
                    this.state.user.cm360 && this.state.user.cm360.authenticated &&
                    <div className="mt-5">
                        <button
                            onClick={() => {
                                this.setState({
                                    remove: true
                                });
                            }}
                            className={(!(this.state.user.cm360 && this.state.user.cm360.authenticated) ?
                                "cursor-pointer bg-dv360-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Remove</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.cm360 && this.state.user.cm360.authenticated) ? "" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    false &&
                    this.state.agency.cm360 &&
                    this.state.agency.cm360.authenticated &&
                    <div className="mt-5 w-full">
                        <dl className={"mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3"}>
                            {[{ name: "Clients", stat: this.state.agency.cm360.cm360_connected }, { name: "Managers", stat: this.state.agency.cm360.matching_user_profiles }, { name: "Ad accounts", stat: this.state.agency.cm360.matching_accounts }].map((item) => (
                                <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                    <dt className="text-sm font-medium text-gray-500">{item.name}</dt>
                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                }
                {
                    this.state.agency.cm360 &&
                    this.state.agency.cm360.authenticated &&
                    this.state.syncManagers &&
                    <div className="bg-yellow-100 p-4 mt-4 text-yellow-500 text-sm font-medium rounded-md">
                        {this.state.syncMessage}
                    </div>
                }
                {
                    this.state.error &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        {this.state.message}
                    </div>
                }
                {
                    !this.state.agency.cm360 &&
                    this.state.healthcheck && this.state.healthcheck.cm360 && this.state.healthcheck.cm360.status != 200 &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        Token Check: {this.state.healthcheck.cm360.message}. We recommend to sign out and sign in again.
                    </div>
                }
                {
                    !this.state.agency.cm360 &&
                    !this.state.user.id &&
                    this.state.client.cm360 &&
                    !(this.state.is_manager || (!this.state.is_manager && this.state.client.cm360 && this.state.client.cm360.defaultAgent && this.state.client.cm360.defaultAgent.id === this.state.me.id)) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You are not "default agent" or "agency manager"
                    </div>
                }
                {
                    !this.state.agency.cm360 &&
                    !this.state.user.id &&
                    !this.state.is_manager &&
                    !this.state.client.cm360 &&
                    !(this.state.me.cm360 && this.state.me.cm360.authenticated) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You have not authenticated cm360, go to My Profile.
                    </div>
                }
                {
                    !this.state.agency.cm360 &&
                    !this.state.user.id &&
                    this.state.is_manager &&
                    !this.state.client.cm360 &&
                    this.state.agents.length < 1 &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! No available agents who has authenticated cm360
                    </div>
                }
            </div >
        )
    }
}

export default AccountCm360;
