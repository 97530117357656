import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, ChevronUpIcon, CubeIcon, ExclamationCircleIcon, PlusIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrder from '../moduleFiles/createOrder';
import SideNavigationAgent from '../moduleFiles/sideNavigationAgent';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WizardModal from '../moduleFiles/wizardModal';
import { clientRegister } from '../services/clientRegister';
import CreateCustomMetric from '../modules/createCustomMetric';
import CreateMetric from '../moduleFiles/createMetric';
import CreateCustomNewMetric from '../moduleFiles/createCustomMetric';
import ShareMetric from "../moduleFiles/shareMetric";
import {userRegister} from "../services/userRegister";

class MetricList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            closed: true,
            search: "",
            sort: "created",
            direction: "descending",
            create_wizard: false,
            wizard_name: "",
            orders: [],
            columns: [
                { name: 'name', value: 'name', min_width: '350px' },
                { name: 'type', value: 'type', min_width: '150px', noSort: true },
                { name: 'channels', value: 'channels', noSort: true },
                { name: 'goal', value: 'goal', min_width: '200px', noSort: true },
                { name: 'created', value: 'created', min_width: '150px' },
                { name: 'owner', value: 'owner', min_width: '350px', noSort: true }
            ],
            status: {},
            client: {},
            clients: [],
            stats: [],
            filters: [],
            throttling: {},
            user: {},
        }
    };

    componentDidMount() {
        console.log("hej");
        this.functions.query();
        if (this.props.updateStatistics) {
            this.props.updateStatistics();
        }
        this.functions.user();
    }

    functions = {
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
        },
        query: async () => {

            //CHECK IF ADVANVED, STANDARD OR TEMPLATES
            if (window.location.pathname.indexOf("custom") !== -1) {
                await this.promisedSetState({
                    status: { id: 1, name: "custom", value: "custom" }
                })
            } else if (window.location.pathname.indexOf("calculated") !== -1) {
                await this.promisedSetState({
                    status: { id: 2, name: "calculated", value: "calculated" }
                })
            } else if (window.location.pathname.indexOf("default") !== -1) {
                await this.promisedSetState({
                    status: { id: 3, name: "default", value: "default" }
                })
            }

            this.functions.orders(true);
        },
        /*
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
            this.functions.query();
        },
        */
        orders: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.orders();
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ orders: response.data, meta: response.meta });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders });
            }
            this.functions.orders();
            this.props.updateStatistics();
        },
        menu: async (option, row) => {
            if (option === "Edit") {
                await this.promisedSetState({
                    metric: row,
                    create_metric: true,
                });
            } else if (option === "Delete") {
                await this.promisedSetState({
                    remove: row
                });
            } else if (option === "Share metric") {
                await this.promisedSetState({
                    shared_metric: row
                });
            }
        },
    };

    calls = {
        orders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listCustomMetrics?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&type=" + this.state.status.value + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v2/metrics/" + id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={async (type) => {
                            if (type === "create") {
                                await this.promisedSetState({
                                    metric: null,
                                    create_metric: true
                                });
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.orders();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.orders();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.orders(false, value);
                                }
                            }, 400);
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={!this.state.loading}
                        filters={[]}
                        hideUserDropdown={true}
                        breadcrumb={"Orders"}
                        buttons={[
                            { text: "Create new", icon: AdjustmentsIcon, value: "create", loading: false, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={false}
                        lockClient={false}
                        onClient={async (client) => {
                            /*
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.orders();
                            this.props.updateStatistics(client);
                            */
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading metrics ...</div>
                    </div>
                }

                {/*CREATE DEFAULT METRIC*/}
                <SlideoutTailwind
                    title={this.state.metric ? "Edit " + this.state.status.name + " Metric" : "Create " + this.state.status.name + " metric"}
                    submitButtonText={this.state.metric ? "Update" : "Create"}
                    large={false}
                    medium={true}
                    noPadding={true}
                    secondaryButton={false}
                    open={this.state.create_metric ? true : false}
                    loader={this.state.create_metric_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_metric: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_metric_loading: true
                        });
                        this.refs.CreateMetric.functions.createMetric();
                    }}
                >
                    {
                        this.state.create_metric &&
                        <CreateCustomNewMetric
                            ref="CreateMetric"
                            metric={this.state.metric}
                            fixedType={this.state.status.value}
                            onCreated={async () => {
                                await this.promisedSetState({
                                    metric: null,
                                    create_metric: false,
                                    create_metric_loading: false,
                                    sort: "created",
                                    direction: "descending"
                                });
                                this.functions.orders();
                            }}
                            onError={async (message) => {
                                await this.promisedSetState({
                                    create_metric_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*SHARE METRIC*/}
                <SlideoutTailwind
                    open={!!this.state.shared_metric}
                    title="Share metric"
                    submitButtonText={"Share"}
                    secondaryButton={false}
                    loader={this.state.loading_share}
                    onCancel={() => {
                        this.promisedSetState({ shared_metric: null });
                    }}
                    onSubmit={async () => {
                        this.setState({
                            loading_share: true
                        });
                        this.refs.ShareMetric.functions.share();
                    }}
                >
                    <ShareMetric
                        metric={this.state.shared_metric}
                        ref="ShareMetric"
                        onCreated={async () => {
                            await this.promisedSetState({
                                loading_share: false,
                                shared_metric: null,
                            });
                        }}
                        onError={() => {
                            this.setState({
                                loading_share: false
                            });
                        }}
                    >

                    </ShareMetric>
                </SlideoutTailwind>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-lg w-full overflow-hidden">

                            {/*NO METRICS*/}
                            {
                                this.state.orders.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No {this.state.status.name} metrics available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.orders.length > 0 &&
                                <div className="relative overflow-x-auto table-overflow">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className=""></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                if (!item.noSort) {
                                                                    await this.promisedSetState({
                                                                        page: 1,
                                                                        sort: item.value,
                                                                        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                    });
                                                                    this.functions.orders();
                                                                }
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " border-l px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                {
                                                                    !item.noSort &&
                                                                    <div className="flex flex-col">
                                                                        <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                        <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.orders.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr
                                                                onMouseEnter={() => {
                                                                    item.hover = true;
                                                                    this.setState({
                                                                        orders: this.state.orders
                                                                    })
                                                                }}
                                                                onMouseLeave={() => {
                                                                    item.hover = false;
                                                                    this.setState({
                                                                        orders: this.state.orders
                                                                    })
                                                                }}
                                                                className="border-b" key={item.id}>
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={(item.hover ? "bg-gray-50 " : "bg-white ") + " px-4 sm:px-6 py-4 flex flex-row justify-center items-center  border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative h-10 w-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className={`border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                {/* <Menu.Items className={`${this.state.orders.length - (index + 1) < 5 && this.state.orders.length > 5 ? "bottom-100 left-100" : ""} border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}> */}

                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Edit", "Delete", "Share metric"].filter((option) => {
                                                                                            if (option === "Share metric") {
                                                                                                return this.state.user.agency_admin_access;
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }).map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.functions.menu(option, item);
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td onClick={() => {
                                                                    this.functions.menu("Edit", item);
                                                                }} className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50" : "") + " border-l px-4 sm:px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    {item.name ? item.name : "-"}
                                                                </td>
                                                                <td className={((item.hover) ? "bg-gray-50" : "") + " border-l px-4 sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    <span
                                                                        className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                //["bg-green-100 text-green-500"]: item.status === "active",
                                                                                //["bg-purple-100 text-purple-500"]: item.status === "upcoming",
                                                                                ["bg-gray-100 text-gray-500"]: true,
                                                                                //["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused"
                                                                            })
                                                                        }>
                                                                        {item.calculated ? "calculated" : (item.default ? "default" : "custom")}
                                                                    </span>
                                                                </td>
                                                                <td className={((item.hover) ? "bg-gray-50" : "") + " border-l px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <div className="flex flex-row pl-2">
                                                                        {item.channels && item.channels.adform &&
                                                                            <div
                                                                                className="w-8 h-8 bg-adform-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.google_search_console &&
                                                                            <div
                                                                                className="w-8 h-8 bg-google_search_console-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_search_console.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.facebook &&
                                                                            <div
                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.linkedin &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.google &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.tiktok &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.twitter &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.google_analytics &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.google_analytics_4 &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.snapchat &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.bing &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-bing-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.bidtheatre &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-bidtheatre-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.dv360 &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-dv360-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {item.channels && item.channels.cm360 &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-cm360-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/cm360-logo.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.channels && Object.keys(item.channels).length > 0 &&
                                                                            Object.keys(item.channels).filter((item) => {
                                                                                return item !== "adform" && item !== "google_search_console" && item !== "facebook" && item !== "tiktok" && item !== "linkedin" && item !== "google" && item !== "twitter" && item !== "google_analytics" && item !== "google_analytics_4" && item !== "snapchat" && item !== "bing" && item !== "bidtheatre" && item !== "dv360"
                                                                            }).map((key) => {
                                                                                return (
                                                                                    <div
                                                                                        style={{ backgroundColor: "#" + item.channels[key].color }}
                                                                                        className={'w-8 h-8  rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + item.channels[key].logo + ")" }}></div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className={((this.state.sort === 'goal' || item.hover) ? "bg-gray-50" : "") + " border-l px-4 font-medium  sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    {item.goal ? item.goal.name : '-'}
                                                                </td>
                                                                <td className={((this.state.sort === 'created' || item.hover) ? "bg-gray-50" : "") + " border-l px-4 font-medium  sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    {item.created ? moment(item.created).format('YYYY-MM-DD') : '-'}
                                                                </td>
                                                                <td className={((this.state.sort === 'owner' || item.hover) ? "bg-gray-50" : "") + " border-l px-4 sm:px-6 py-4 font-medium  items-center text-sm   border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                            {
                                                                                item.owner &&
                                                                                <img src={item.owner.logo} className="bg-cover" />
                                                                            }
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            {item.owner ? item.owner.name : "-"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {/* PAGINATION */}
                            {
                                this.state.orders.length > 0 &&
                                <div className="flex flex-row p-4 items-center">
                                    <div className="flex justify-center items-center">
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                if (this.state.page !== 1) {
                                                    await this.promisedSetState({
                                                        page: this.state.page - 1
                                                    });
                                                    this.functions.orders();
                                                }
                                            }}
                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                            Previous
                                        </button>
                                    </div>
                                    <div className="flex flex-1 items-center justify-center">
                                        {
                                            (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                            <p className="text-sm text-gray-700">
                                                Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                            </p>
                                        }
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={async () => {
                                                    if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                        await this.promisedSetState({
                                                            page: this.state.page + 1
                                                        });
                                                        this.functions.orders();
                                                    }
                                                }}
                                                className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <span>Next</span>
                                                <ArrowRightIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default MetricList;
