import React, { Fragment } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { Editor, EditorState, getDefaultKeyBinding, RichUtils, convertFromHTML, ContentState, DefaultDraftBlockRenderMap, getSafeBodyFromHTML } from 'draft-js';
import FeatherIcon from 'feather-icons-react';
import { DotsVerticalIcon, XIcon } from '@heroicons/react/outline';

export default class AdvancedReportCellText extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: this.initialState(this.props.data),
            htmlState: this.props.data ? this.props.data : '',
            textAlign: this.props.source ? this.props.source.textAlign : 'LEFT',
            verticalAlign: this.props.source ? this.props.source.verticalAlign : false,
            transparent_cells: this.props.transparent_cells ? this.props.transparent_cells : false,
            report_background_color: this.props.report_background_color,
            cell: this.props.source ? this.props.source : {},
            page: this.props.page ? this.props.page : {},
            show: false
        };
        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => {
            const htmlState = stateToHTML(this.state.editorState.getCurrentContent(), {});
            this.setState({
                editorState,
                htmlState: htmlState
            });
            this.props.onInput(htmlState, this.state.textAlign, this.state.verticalAlign);
        };
        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleHeadingType = this._toggleHeadingType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    async componentDidMount() {

        await this.promisedSetState({
            editorState: this.initialState(this.props.data),
            htmlState: this.props.data ? this.props.data : '',
            transparent_cells: this.props.transparent_cells ? this.props.transparent_cells : false,
            report_background_color: this.props.report_background_color,
            cell: this.props.source ? this.props.source : {},
            page: this.props.page ? this.props.page : {},
            show_component: this.props.show_component
        });

        //ONLY SHOW CONTENT IN VIEW
        this.functions.showInView();

    }

    async componentWillReceiveProps(nextProps, nextContext) {
        await this.promisedSetState({
            transparent_cells: nextProps.transparent_cells ? nextProps.transparent_cells : false,
            report_background_color: nextProps.report_background_color,
            cell: nextProps.source ? nextProps.source : {},
            page: nextProps.page ? nextProps.page : {}
        });

    }

    functions = {
        showInView: async () => {
            let self = this;
            if (this.state.show_component) {
                self.setState({ show: true });
            } else {
                await self.functions.sleep(2000);
                try {
                    let box = null;
                    if (window.innerWidth <= 767) {
                        box = document.getElementById(self.state.cell.i + "_preview_mobile");
                    } else {
                        box = document.getElementById(self.state.cell.i + "_preview");
                    }
                    const rect = box.getBoundingClientRect();
                    const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                        rect.bottom > 0 &&
                        rect.right > 0;
                    if (isInView) {
                        self.setState({ show: isInView });
                    }
                } catch (error) {
                    self.setState({ show: true });
                }
                document.addEventListener('scroll', async function () {
                    try {
                        let box = null;
                        if (window.innerWidth <= 767) {
                            box = document.getElementById(self.state.cell.i + "_preview_mobile");
                        } else {
                            box = document.getElementById(self.state.cell.i + "_preview");
                        }
                        const rect = box.getBoundingClientRect();
                        const isInView = rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
                            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
                            rect.bottom > 0 &&
                            rect.right > 0;
                        self.setState({ show: isInView });
                    } catch (error) {
                        self.setState({ show: true });
                    }
                }, {
                    capture: true,
                });
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }

    initialState = (html) => {
        if (html && typeof html === 'string') {
            const blockRenderMap = DefaultDraftBlockRenderMap.set('br', { element: 'br' });
            const blocksFromHTML = convertFromHTML(html, getSafeBodyFromHTML, blockRenderMap);
            const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            return EditorState.createWithContent(state);
        } else {
            return EditorState.createEmpty();
        }
    };

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _mapKeyToEditorCommand(e) {
        /*
        if (e.keyCode === 9) {
            const newEditorState = RichUtils.onTab(e, this.state.editorState, 4);
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        */
        return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    }

    _toggleHeadingType(blockType) {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
    }

    alignText = () => {
        if (!this.state.verticalAlign) {
            return this.state.textAlign ? { textAlign: this.state.textAlign } : {};
        } else {
            if (this.state.textAlign == "LEFT") {
                return { display: 'flex', height: '100%', justifyContent: "left", alignItems: "center" }
            } else if (this.state.textAlign == "CENTER") {
                return { display: 'flex', height: '100%', justifyContent: "center", alignItems: "center", textAlign: 'center' }
            } else if (this.state.textAlign == "RIGHT") {
                return { display: 'flex', height: '100%', justifyContent: "right", alignItems: "center" }
            } else {
                return { display: 'flex', height: '100%', justifyContent: "left", alignItems: "center" }
            }
        }
    }

    backgroundColor = () => {
        let color = "#FFFFFF";
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.transparent) {
            if (this.state.page && this.state.page.enable_background) {
                color = "#" + this.state.page.background_color;
            } else {
                color = "#" + this.state.report_background_color;
            }
        } else if (this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color) {
            color = "#" + this.state.cell.settings.background_color;
        }
        return color;
    };

    fontColor = () => {
        let color = "#000000";
        if (this.state.cell && this.state.cell.settings && this.state.cell.settings.font_color) {
            color = "#" + this.state.cell.settings.font_color;
        }
        return color;
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        const { editorState } = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = ''; //'py-3 h-full';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            this.state.show ? <div
                style={this.state.cell && this.state.cell.settings && !this.state.cell.settings.transparent ? { backgroundColor: this.backgroundColor(), color: this.fontColor() } : { color: this.fontColor() }}
                className={(!(this.state.cell.settings && (this.state.cell.settings.disable_margin_top || this.state.cell.settings.disable_margin_left)) ? " rounded-tl-md " : "") + (!(this.state.cell.settings && (this.state.cell.settings.disable_margin_top || this.state.cell.settings.disable_margin_right)) ? " rounded-tr-md " : "") + (!(this.state.cell.settings && (this.state.cell.settings.disable_margin_bottom || this.state.cell.settings.disable_margin_right)) ? " rounded-br-md " : "") + (!(this.state.cell.settings && (this.state.cell.settings.disable_margin_bottom || this.state.cell.settings.disable_margin_left)) ? " rounded-bl-md " : "") + " RichEditor-root"}
                onClick={async () => {
                    try {
                        this.refs.editor.focus();
                    } catch (error) { }
                }}
            >
                {
                    !this.props.static &&
                    <div style={{ right: '6px', overflowY: 'scroll', maxHeight: '95%' }} className="absolute z-60 overflow-hidden rounded">
                        <div className="flex flex-col justify-end">
                            <div className="flex flex-row justify-end">
                                {
                                    this.state.headings &&
                                    <HeadingStyleControls cell={this.state.cell} editorState={editorState} onToggle={this.toggleHeadingType} />
                                }
                                <div onClick={() => {
                                    this.setState({
                                        headings: !this.state.headings
                                    })
                                }} className={((this.state.cell.w < 2 || this.state.cell.h < 2) ? "w-6 h-6 " : "w-10 h-10 ") + (this.state.headings ? "" : "rounded") + " flex justify-center items-center text-sm font-medium text-purple-500 hover:bg-purple-200 cursor-pointer duration-200 bg-purple-100"}>
                                    {
                                        !this.state.headings &&
                                        <DotsVerticalIcon className={(this.state.cell.w < 2 || this.state.cell.h < 2) ? "h-3" : "h-5"} />
                                    }
                                    {
                                        this.state.headings &&
                                        <XIcon className={(this.state.cell.w < 2 || this.state.cell.h < 2) ? "w-3" : "w-5"} />
                                    }
                                </div>
                            </div>
                            {
                                this.state.headings &&
                                <HeadingStyleControlsSecond cell={this.state.cell} editorState={editorState} onToggle={this.toggleHeadingType} />
                            }
                            <div className="flex flex-col justify-end">
                                <div className="flex flex-row justify-end">
                                    {
                                        this.state.headings &&
                                        <InlineStyleControls cell={this.state.cell} editorState={editorState} onToggle={this.toggleInlineStyle} />
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col justify-end flex-1">
                                <div className="flex flex-row justify-end flex-1">
                                    {
                                        this.state.headings &&
                                        <PositionStyleControls textAlign={this.state.textAlign} cell={this.state.cell} editorState={editorState} onToggle={(style) => {
                                            this.setState({
                                                textAlign: style
                                            })
                                        }} />
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col justify-end flex-1">
                                <div className="flex flex-row justify-end flex-1">
                                    {
                                        this.state.headings &&
                                        <VerticalStyleControls verticalAlign={this.state.verticalAlign} cell={this.state.cell} editorState={editorState} onToggle={(style) => {
                                            this.setState({
                                                verticalAlign: !this.state.verticalAlign
                                            })
                                        }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div style={this.alignText()} className={className}
                    onClick={() => {
                        this.focus();
                    }}
                >
                    <Editor
                        readOnly={this.props.static}
                        blockStyleFn={getBlockStyle}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        keyBindingFn={this.mapKeyToEditorCommand}
                        onChange={this.onChange}
                        placeholder=""
                        ref="editor"
                        spellCheck={false}
                    />
                </div>
            </div> : <div
                style={this.state.cell && this.state.cell.settings && this.state.cell.settings.background_color ? { backgroundColor: "#" + this.state.cell.settings.background_color } : { backgroundColor: "#FFFFFF" }}
                className="w-full h-full relative flex flex-col justify-center items-center">
                <div className="bg-transparent">
                    <div style={{ borderTopColor: "transparent" }}
                        class="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return 'RichEditor-blockquote';
        default:
            return null;
    }
}

class StyleButton extends React.Component {

    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {

        let className = 'hover:bg-purple-200 cursor-pointer duration-200 bg-purple-100 flex justify-center items-center font-medium';

        if (this.props.cell.w < 2 || this.props.cell.h < 2) {
            className += ' h-6';
            className += ' w-6 min-w-6';
        } else {
            className += ' h-10';
            className += ' w-10 min-w-10';
        }

        if (this.props.active) {
            className += ' text-purple-500';
        } else {
            className += ' text-gray-700';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.icon &&
                    <span>
                        <FeatherIcon className="stroke-current" size={(this.props.cell.w < 2 || this.props.cell.h < 2) ? 10 : 16} icon={this.props.icon} />
                    </span>
                }
                {!this.props.icon &&
                    <span className={(this.props.cell.w < 2 || this.props.cell.h < 2) ? "text-xxs" : ""}>{this.props.label}</span>
                }
            </span>
        );
    }
}

class StyleButtonText extends React.Component {

    constructor() {
        super();
        this.state = {};
    }

    render() {
        let className = 'hover:bg-purple-200 cursor-pointer duration-200 bg-purple-100 flex justify-center items-center font-medium';
        if (this.props.cell.w < 2 || this.props.cell.h < 2) {
            className += ' h-6';
            className += ' flex flex-1 ';
        } else {
            className += ' h-10';
            className += ' flex flex-1 ';
        }
        if (this.props.active) {
            className += ' text-purple-500';
        } else {
            className += ' text-gray-700';
        }
        return (
            <div className={className} onMouseDown={() => {
                if (this.props.onToggle) {
                    this.props.onToggle(this.props.style);
                }
            }}>
                <span className={(this.props.cell.w < 2 || this.props.cell.h < 2) ? "text-xxs" : ""}>{this.props.label}</span>
            </div>
        );
    }
}

const HeadingStyleControls = (props) => {
    const HEADING_TYPES = [
        { label: 'P', style: 'unstyled' },
        { label: 'H1', style: 'header-one' },
        { label: 'H2', style: 'header-two' }
    ];
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
    return (
        <div className="heading-control--wrapper">
            <div className={"heading-drop--wrapper relative justify-end flex flex-row"}>
                {HEADING_TYPES.map((type) => (
                    <StyleButton cell={props.cell} key={type.label} active={type.style === blockType} label={type.label} onToggle={props.onToggle} style={type.style} />
                ))}
            </div>
        </div>
    );
}

const HeadingStyleControlsSecond = (props) => {
    const HEADING_TYPES_SECOND = [
        { label: 'H3', style: 'header-three' },
        { label: 'H4', style: 'header-four' },
        { label: 'UL', style: 'unordered-list-item', icon: 'list' },
        { label: 'OL', style: 'ordered-list-item', icon: '' }
    ];
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
    return (
        <div className="heading-control--wrapper">
            <div className={"heading-drop--wrapper relative flex justify-end flex-row"}>
                {HEADING_TYPES_SECOND.map((type) => (
                    <StyleButton cell={props.cell} key={type.label} active={type.style === blockType} label={type.label} onToggle={props.onToggle} style={type.style} />
                ))}
            </div>
        </div>
    );
}

const InlineStyleControls = (props) => {
    var INLINE_STYLES = [
        { label: 'Bold', style: 'BOLD', icon: 'bold' },
        { label: 'Italic', style: 'ITALIC', icon: 'italic' },
        { label: 'Underline', style: 'UNDERLINE', icon: 'underline' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH', icon: 'minus' }
    ];
    const currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className="flex flex-row text-xs items-stretch">
            {INLINE_STYLES.map((type) => (
                <StyleButton cell={props.cell} key={type.label} active={currentStyle.has(type.style)} label={type.label} icon={type.icon} onToggle={props.onToggle} style={type.style} />
            ))}
        </div>
    );
};

const PositionStyleControls = (props) => {
    var POSITION_STYlE = [
        { label: 'Left', style: 'LEFT' },
        { label: 'Center', style: 'CENTER' },
        { label: 'Right', style: 'RIGHT' }
    ];
    return (
        <div className="flex flex-row text-xs items-stretch flex-1">
            {POSITION_STYlE.map((type) => (
                <StyleButtonText cell={props.cell} key={type.label} active={props.textAlign === type.style} label={type.label} onToggle={props.onToggle} style={type.style} />
            ))}
        </div>
    );
};

const VerticalStyleControls = (props) => {
    var POSITION_STYlE = [
        { label: 'Vertical Align', style: 'VERTICAL_ALIGN' }
    ];
    return (
        <div className="flex flex-row text-xs items-stretch flex-1">
            {POSITION_STYlE.map((type) => (
                <StyleButtonText cell={props.cell} key={type.label} active={props.verticalAlign} label={type.label} onToggle={props.onToggle} style={type.style} />
            ))}
        </div>
    );
};
