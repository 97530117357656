import React, { Component, Fragment } from 'react';
import { ArrowSmDownIcon, ArrowSmLeftIcon, ArrowSmRightIcon, ArrowSmUpIcon, CheckCircleIcon, CheckIcon, ChipIcon, PaperAirplaneIcon, PlusIcon, SelectorIcon, SparklesIcon, SpeakerphoneIcon, UserGroupIcon, UserIcon, UsersIcon, XCircleIcon, XIcon } from '@heroicons/react/outline'
import { Listbox, Transition, Dialog } from '@headlessui/react'
import PreviewGoogleSearch from '../previews/google/search';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';


class SlideoutTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            subtitle: "",
            open: false,
            loader: false,
            loaderSecondary: false,
            error: false,
            success: false,
            reach: {},
            disabled: false,
            noSubmit: false,
            group: {
                combinations: false
            },
            search_ad: false,
            keywordList: false,
            sort_suggestions: 'keyword',
            direction_suggestions: 'ascending',
            OpenAiResult: [],
            open_chat: false,
            language: { id: 1, name: "English" },
            ai_action: { id: 2, name: "Auto create" },
            client: {},
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            open: this.props.open,
            title: this.props.title,
            subtitle: this.props.subtitle,
            loader: this.props.loader,
            large: this.props.large,
            small: this.props.small,
            medium: this.props.medium,
            full: this.props.full,
            submitButtonText: this.props.submitButtonText,
            secondaryButton: this.props.secondaryButton,
            secondaryButtonText: this.props.secondaryButtonText,
            loaderSecondary: this.props.loaderSecondary,
            error: this.props.error,
            success: this.props.success,
            reach: this.props.reach,
            thirdButton: this.props.thirdButton,
            thirdButtonLoader: this.props.thirdButtonLoader,
            thirdButtonWhite: this.props.thirdButtonWhite,
            disabled: this.props.disabled,
            noSubmit: this.props.noSubmit,
            search_ad: this.props.search_ad,
            group: this.props.group ? this.props.group : this.state.group,
            keywordList: this.props.keywordList ? this.props.keywordList : this.state.keywordList,
            client: this.props.client ? this.props.client : this.state.client,
            chat: this.props.chat,
        })
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.open && !this.state.open) {
            await this.promisedSetState({
                open_chat: false,
                OpenAiResult: [],
                ai_chat: false,
            })
        }
        await this.promisedSetState({
            open: nextProps.open,
            title: nextProps.title,
            subtitle: nextProps.subtitle,
            loader: nextProps.loader,
            large: nextProps.large,
            small: nextProps.small,
            medium: nextProps.medium,
            full: nextProps.full,
            submitButtonText: nextProps.submitButtonText,
            secondaryButton: nextProps.secondaryButton,
            secondaryButtonText: nextProps.secondaryButtonText,
            loaderSecondary: nextProps.loaderSecondary,
            error: nextProps.error,
            success: nextProps.success,
            reach: nextProps.reach,
            thirdButton: nextProps.thirdButton,
            thirdButtonLoader: nextProps.thirdButtonLoader,
            thirdButtonWhite: nextProps.thirdButtonWhite,
            disabled: nextProps.disabled,
            name_error: nextProps.name_error,
            noSubmit: nextProps.noSubmit,
            search_ad: nextProps.search_ad,
            group: nextProps.group,
            keywordList: nextProps.keywordList,
            chat: nextProps.chat,
            client: nextProps.client
        })
        if (this.state.chat && this.state.chat.length > 0) {
            await this.promisedSetState({
                OpenAiResult: this.state.chat
            })
            //await this.functions.scroll();
        }

    }

    functions = {
        success: (value) => {
            this.promisedSetState({
                success: value
            });
        },
        loader: (value) => {
            this.promisedSetState({
                loader: value
            });
        },
        scroll: () => {
            return new Promise(async (resolve) => {
                try {
                    let myDiv = document.getElementById('chat');
                    myDiv.scrollTo(0, 100000000000);
                } catch (error) {
                }
                resolve();
            })
        },
        typeWriter: () => {
            return new Promise(async (resolve) => {
                try {

                    if (this.state.OpenAiResult[this.state.OpenAiResult.length - 1].content !== "") {
                        await this.promisedSetState({
                            assistant_index: this.state.OpenAiResult.length - 1
                        });
                        let element = document.getElementById('assistant_' + (this.state.OpenAiResult.length - 1));

                        for (let i = 0; i < this.state.OpenAiResult[this.state.OpenAiResult.length - 1].content.length; i++) {
                            element.innerHTML += this.state.OpenAiResult[this.state.OpenAiResult.length - 1].content.charAt(i);
                            await new Promise(resolve => setTimeout(resolve, 10));
                            await this.functions.scroll();
                        }
                        await this.promisedSetState({
                            assistant_index: 99999
                        });
                    }
                } catch (error) {
                }
                resolve();
            });
        },
        openAi: async (init = false, failed = false, count = 0) => {

            let data = {
                message: this.state.OpenAiResult.filter((item) => { return !item.fake }).map((item) => { return { role: item.role, content: item.content } }),
                language: this.state.language.name,
                keyword_list: this.state.keywordList ? this.state.keywordList.keywords.map((item) => { return item.keyword }) : [],
            }
            if (!init && this.state.searchOpenAi !== "") {
                data.message.push(
                    {
                        role: "user",
                        content: this.state.searchOpenAi
                    }
                )
                this.state.OpenAiResult.push({ role: "user", content: this.state.searchOpenAi });
            }
            if (failed) {
                data.message.push(
                    {
                        role: "user",
                        content: "Return in requested JSON format without any other comments"
                    }
                )
                this.state.OpenAiResult.push({ role: "user", content: "Return in requested JSON format without any other comments", not_show: true});
            }


            await this.promisedSetState({
                OpenAiResult: this.state.OpenAiResult,
                ai_loading: true,
                searchOpenAi: ""
            })
            await this.functions.scroll();
            //await this.promisedSetState({ loading_suggest: true });
            try {
                let response = await this.calls.openAi(data, init);
                let temp = JSON.parse(JSON.stringify(response.data));
                if (response.data.content.includes("***") || response.data.content.includes("```")) {
                    let regex = /\*\*\*(.*?)\*\*\*/s;
                    if (response.data.content.includes("```")) {
                        regex = /```(.*?)```/s;
                    }
                    temp.not_show = true;
                    this.state.OpenAiResult.push(temp);

                    await this.promisedSetState({
                        OpenAiResult: this.state.OpenAiResult
                    })
                    let buffer = response.data.content.match(regex);
                    if (buffer && buffer[1]) {
                        response.data.content = response.data.content.replace(buffer[0], "*+*");
                        let a = JSON.parse(buffer[1]);
                        let headlines = a.Google && a.Google.Headline ? a.Google.Headline : [];
                        let descriptions = a.Google && a.Google.Description ? a.Google.Description : [];
                        let parts = response.data.content.split("*+*");
                        if (parts[0] !== "") {
                            if (headlines.length < 1 && descriptions.length < 1 && count<2) {
                                //this.state.OpenAiResult.push({ role: "user", fake: true, content: parts[0] });
                                this.functions.openAi(false, true, count + 1);
                                return;

                            } else {
                                this.state.OpenAiResult.push({ role: "assistant", fake: true, content: parts[0] });
                                await this.promisedSetState({
                                    OpenAiResult: this.state.OpenAiResult,
                                    ai_loading: false,
                                })
                                await this.functions.scroll();
                                await this.functions.typeWriter();
                            }
                        }


                        if (Array.isArray(headlines) && headlines.length > 0 && this.state.ai_action.id === 2) {
                            this.props.onAddHeadlines(headlines);
                        }
                        if (Array.isArray(descriptions) && descriptions.length > 0 && this.state.ai_action.id === 2) {
                            this.props.onAddDescriptions(descriptions);
                        }
                        this.state.OpenAiResult.push({ role: "assistant", fake: true, content: "", headlines: headlines, descriptions: descriptions });
                        await this.promisedSetState({
                            OpenAiResult: this.state.OpenAiResult,
                            ai_loading: false,
                        })
                        await this.functions.scroll();
                        await new Promise(resolve => setTimeout(resolve, 1500));
                        if (parts[1] !== "") {
                            this.state.OpenAiResult.push({ role: "assistant", fake: true, content: parts[1].replace(/\n/g, "") });
                            await this.promisedSetState({
                                OpenAiResult: this.state.OpenAiResult,
                                ai_loading: false,
                            })
                            await this.functions.scroll();
                            await this.functions.typeWriter();
                        }
                    }
                } else {
                    this.state.OpenAiResult.push(response.data);
                    await this.promisedSetState({
                        OpenAiResult: this.state.OpenAiResult,
                        ai_loading: false,
                    })
                    await this.functions.scroll();
                    await this.functions.typeWriter();
                }
                this.props.onUpdateChat(this.state.OpenAiResult);
                console.log(response, "response");
            } catch (error) {

            }

        },
    };
    renders = {
        sort: (list, sort, order) => {
            if (!Array.isArray(list)) {
                list = [];
            }
            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : 0) : (x[sort] ? x[sort] : 0);
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : 0) : (y[sort] ? y[sort] : 0);
                    if (order == 'descending') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }
            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[sort] ? x.row_values[sort] : "") : (x[sort] ? x[sort] : "");
                    let y_value = y.row_values ? (y.row_values[sort] ? y.row_values[sort] : "") : (y[sort] ? y[sort] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (order == 'descending') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }
            if (sort === "keyword" || sort === "competition") {
                return sortCharacter(list);
            } else {
                return sortNumber(list);
            }
        },
    };

    calls = {
        openAi: (data, init) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/openAiAssistant?client=" + this.state.client.id + "&init=" + init;
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog style={{zIndex: 151}} as="div" className="fixed inset-0 overflow-hidden" onClose={() => { }}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Dialog.Overlay className="absolute inset-0 bg-purple-900 bg-opacity-50" />
                        <div className="pointer-events-none fixed inset-y-0 p-4 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className={(this.state.small ? "max-w-2xl " : "") + (this.state.medium ? "max-w-4xl " : "") + (this.props.mediumPlus ? "max-w-5xl " : "") + (this.state.large ? "max-w-6xl " : "") + (this.state.full ? "w-full " : "") + ((!this.state.medium && !this.state.large && !this.state.full && !this.state.small) ? "max-w-md" : "") + " pointer-events-auto rounded-md overflow-hidden w-screen flex"}>
                                    {
                                        this.state.search_ad &&
                                        <div className="flex flex-1 flex-col h-full relative ">
                                            <div className={'flex flex-col transition-all duration-100 absolute right-0 ease-in-out h-full ' + (this.state.search_ad && this.state.open_chat ? "w-full bg-white " : "w-0 overflow-hidden")}>
                                                <div className="flex items-center justify-center">
                                                    <div className='flex flex-1 items-center justify-start ml-2 h-14'>
                                                        <SparklesIcon className="h-6 w-6 text-purple-500" aria-hidden="true" />
                                                        <div className='flex flex-col'>
                                                            <span className='ml-2 font-bold'>Ask Ai-credo</span>
                                                            <span className='ml-2 text-xxxs'>Powered by OpenAi</span>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => {
                                                        this.setState({
                                                            open_chat: false
                                                        })
                                                        //this.props.onChat();
                                                    }} className="flex h-8 w-8 mr-3 bg-red-100 text-red-500 rounded-full items-center justify-center transition-colors ease-in duration-75 hover:bg-red-500 hover:text-white cursor-pointer">
                                                        <XIcon className="h-5 w-5" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.open_chat && !this.state.ai_chat &&
                                                    <div className='flex mt-5 mx-4'>
                                                        <div className='grid grid-cols-6 gap-4 w-full'>
                                                            <div className="col-span-3">
                                                                <DropdownTailwind
                                                                    label={"Choose language"}
                                                                    selected={this.state.language}
                                                                    options={
                                                                        [
                                                                            { id: 1, name: "English" },
                                                                            { id: 2, name: "Swedish" },
                                                                            { id: 3, name: "Finnish" },
                                                                            { id: 4, name: "Danish" },
                                                                            { id: 5, name: "Norwegian" },
                                                                            { id: 6, name: "French" },
                                                                            { id: 7, name: "Dutch" },
                                                                            { id: 8, name: "German" },
                                                                            { id: 9, name: "Spanish" },
                                                                        ]}
                                                                    onChange={async (value) => {
                                                                        await this.promisedSetState({
                                                                            language: value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-span-3">
                                                                <DropdownTailwind
                                                                    label={"AI action"}
                                                                    selected={this.state.ai_action}
                                                                    options={[
                                                                        { id: 1, name: "Suggestion" },
                                                                        { id: 2, name: "Auto create" }
                                                                    ]}
                                                                    onChange={async (value) => {
                                                                        await this.promisedSetState({
                                                                            ai_action: value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="col-span-6">
                                                                <div
                                                                    onClick={async () => {
                                                                        if (this.state.OpenAiResult.length > 0) {
                                                                        } else this.functions.openAi(true);
                                                                        //this.props.onOpenAi({ language: this.state.language, ai_action: this.state.ai_action });
                                                                        await this.promisedSetState({
                                                                            ai_chat: true
                                                                        })

                                                                    }}
                                                                    className={'cursor-pointer items-center bg-purple-500 hover:bg-purple-600 shadow inline-flex relative justify-center rounded-md border border-transparent w-full py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2'
                                                                    }
                                                                >
                                                                    Continue
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.open_chat && this.state.ai_chat &&
                                                    <div className={`flex h-full flex-col overflow-hidden border-l`}>
                                                        <div className='flex-1 flex h-full bg-white rounded-md border-gray-300 border overflow-hidden'>
                                                            <div style={{ scrollBehavior: "smooth" }} id="chat" className='h-full overflow-scroll flex flex-col flex-1 relative '>
                                                                {
                                                                    this.state.OpenAiResult.map((item, index) => {
                                                                        return (
                                                                            <Fragment>
                                                                                {
                                                                                    item.role === "assistant" && !item.not_show &&
                                                                                    <div ref={"index_" + index} id={"index_" + (index + 1)} className={'flex justify-start mt-2 ml-2'}>
                                                                                        <div className='flex flex-col'>
                                                                                            <div className='flex items-center justify-start'>
                                                                                                <div className='bg-gray-100 rounded-full flex justify-center items-center h-7 w-7'>
                                                                                                    <SparklesIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />
                                                                                                </div>
                                                                                                <span className='mt-1 ml-1 text-sm font-bold text-left'>AI-credo:</span>
                                                                                            </div>
                                                                                            <div className='flex items-start bg-gray-100 text-black rounded-md m-2 p-2 w-auto flex-col text-sm'>

                                                                                                <p className=' whitespace-pre-wrap ' id={"assistant_" + index} dangerouslySetInnerHTML={{ __html: index !== this.state.assistant_index ? item.content.toString().replace(/(?:\r\n|\r|\n)/g, "<br />") : "" }} >
                                                                                                </p>
                                                                                                {
                                                                                                    item.headlines && Array.isArray(item.headlines) && item.headlines.length > 0 &&
                                                                                                    <>
                                                                                                        <div className='my-1'><span className='font-bold'>Headlines:</span><span className='ml-2'>(Click to add)</span></div>
                                                                                                        <div className='text-sm'>
                                                                                                            {
                                                                                                                item.headlines.map((headline, index) => {
                                                                                                                    return (
                                                                                                                        <div className='relative inline-flex'>
                                                                                                                            <div onClick={async () => {
                                                                                                                                if (this.state.group.headlines && this.state.group.headlines.filter((inner_item) => { return inner_item.text === headline }).length === 0) {
                                                                                                                                    this.props.onAddHeadlines([headline]);
                                                                                                                                }
                                                                                                                            }} className={'items-center py-1 rounded-full px-2 mr-2 my-1' + (this.state.group.headlines && this.state.group.headlines.filter((inner_item) => { return inner_item.text === headline }).length > 0 ? " bg-green-100 text-green-600 border border-green-600" : " bg-gray-400 cursor-pointer")}>
                                                                                                                                {headline}


                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                this.state.group.headlines && this.state.group.headlines.filter((inner_item) => { return inner_item.text === headline }).length > 0 &&
                                                                                                                                <div onClick={async () => {
                                                                                                                                    this.props.onRemoveHeadline(headline);
                                                                                                                                }} className={"h-4 w-4 rounded-full bg-gray-400 absolute top-0 right-0 items-center flex justify-center text-gray-700 cursor-pointer"}>
                                                                                                                                    <XIcon className='h-3 w-3' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                                {
                                                                                                    item.descriptions && Array.isArray(item.descriptions) && item.descriptions.length > 0 &&
                                                                                                    <>
                                                                                                        <div className='my-1'><span className='font-bold'>Descriptions:</span><span className='ml-2'>(Click to add)</span></div>
                                                                                                        <div className='text-sm'>
                                                                                                            {
                                                                                                                item.descriptions.map((description, index) => {
                                                                                                                    return (
                                                                                                                        <div className='relative inline-flex'>
                                                                                                                            <div onClick={async () => {
                                                                                                                                if (this.state.group.bodies && this.state.group.bodies.filter((inner_item) => { return inner_item.text === description }).length === 0) {
                                                                                                                                    this.props.onAddDescriptions([description]);
                                                                                                                                }
                                                                                                                            }} className={'relative items-center py-1 rounded-md px-2 mr-2 my-1' + (this.state.group.bodies && this.state.group.bodies.filter((inner_item) => { return inner_item.text === description }).length > 0 ? " bg-green-100 text-green-600 border border-green-600" : " bg-gray-400 cursor-pointer")}>
                                                                                                                                {description}


                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                this.state.group.bodies && this.state.group.bodies.filter((inner_item) => { return inner_item.text === description }).length > 0 &&
                                                                                                                                <div onClick={async () => {
                                                                                                                                    this.props.onRemoveDescription(description);
                                                                                                                                }} className={"h-4 w-4 rounded-full bg-gray-400 absolute top-0 right-0 items-center flex justify-center text-gray-700 cursor-pointer"}>
                                                                                                                                    <XIcon className='h-4 w-4' />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item.role === "user" && !item.not_show &&
                                                                                    <div ref={"index_" + index} id={"index_" + (index + 1)} className={'flex justify-end'}>
                                                                                        <div className='flex flex-col'>
                                                                                            <div className='flex items-end bg-purple-500 text-white rounded-md m-2 p-2 w-auto typewriter text-sm'>
                                                                                                <p className=' whitespace-pre-wrap ' dangerouslySetInnerHTML={{ __html: item.content.toString().replace(/(?:\r\n|\r|\n)/g, "<br />") }} ></p>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    this.state.ai_loading &&
                                                                    <div className={'flex justify-start'}>
                                                                        <div id={"index_" + (this.state.chat.length + 1)} className='flex flex-col ml-2 mt-2'>
                                                                            <div className='flex items-center justify-start'>
                                                                                <div className='bg-gray-100 rounded-full flex text-purple-500 justify-center items-center h-7 w-7'>
                                                                                    <SparklesIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </div>
                                                                                <span className='mt-1 ml-1 text-sm font-bold text-left'>AI-credo:</span>
                                                                            </div>
                                                                            <div className='flex items-start bg-gray-100 text-white rounded-md m-2 p-2 w-20 relative'>
                                                                                <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-800 opacity-75 top-0 left-0"></span>
                                                                                <div className=' w-4 h-4 rounded-full bg-gray-300 animate-pulse mr-2'>
                                                                                </div>
                                                                                <div className=' w-4 h-4 rounded-full bg-gray-500 animate-pulse mr-2'>
                                                                                </div>
                                                                                <div className=' w-4 h-4 rounded-full bg-gray-700 animate-pulse'>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='w-full'>
                                                            <div className='flex flex-row h-full mt-2 pb-2'>
                                                                <div className='flex flex-1 w-full'>
                                                                    <div className='w-full inline-flex relative'>

                                                                        <textarea
                                                                            rows={2}
                                                                            className={" bg-custom-input rounded-md block w-full border-1.5 pr-10 px-2 py-2 focus:outline-none sm:text-sm"}
                                                                            value={this.state.searchOpenAi}
                                                                            onChange={async (event) => {
                                                                                if (event.target.value !== "") { this.functions.scroll(); }
                                                                                if (!this.state.ai_loading) { await this.promisedSetState({ searchOpenAi: event.target.value }) }

                                                                            }}
                                                                            onKeyDown={async (event) => {
                                                                                try {
                                                                                    if (event.keyCode === 13 && this.state.searchOpenAi !== "" && !this.state.ai_loading) {
                                                                                        if (!event.shiftKey) {
                                                                                            await this.functions.scroll();
                                                                                            this.functions.openAi(false);
                                                                                        }
                                                                                    }
                                                                                } catch (e) { }
                                                                            }}
                                                                        />
                                                                        <button
                                                                            onClick={async () => {
                                                                                if (this.state.searchOpenAi !== "" && !this.state.ai_loading) {
                                                                                    this.functions.scroll();
                                                                                    this.functions.openAi(false);
                                                                                }
                                                                            }}
                                                                            className={(this.state.ai_loading ? "bg-gray-300 cursor-not-allowed" : "bg-purple-500 hover:bg-purple-600 cursor-pointer") + " items-center h-full inline-flex shadow absolute right-0 bottom-0 justify-center rounded-md border border-transparent  py-2 px-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                        >
                                                                            <PaperAirplaneIcon className='h-5 w-5' style={{ transform: "rotate(90deg)" }} />
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div >
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.search_ad &&
                                        <div className={`flex flex-col flex-1 bg-white shadow-2xl relative ${!this.state.open_chat ? "rounded-l-md" : "rounded-none"}`}>
                                            <div className="flex flex-row m-3 items-center">
                                                <div className={" h-12 absolute left-0 -ml-8 mt-56 z-60 rounded-full bg-purple-600" + (this.state.open_chat ? " w-0" : " w-12 transition duration-500 ease-in-out hover:scale-110 transform hover:-translate-y-1")}>
                                                    <div onClick={() => {
                                                        if (!this.state.open_chat) {
                                                            this.setState({
                                                                open_chat: true
                                                            })
                                                        }
                                                        //this.props.onChat();
                                                    }} className="flex flex-1 justify-center items-center h-full w-full cursor-pointer ">
                                                        <SparklesIcon className="h-6 w-6 text-white" />
                                                    </div>
                                                </div>
                                                <div className="flex flex-1 text-sm font-medium bg-white">
                                                    Preview
                                                </div>
                                                {
                                                    Array.isArray(this.state.group.combinations) &&
                                                    this.state.group.format && this.state.group.format.id === 1 &&
                                                    <div className="flex flex-row">
                                                        <div onClick={() => {
                                                            if (!("combi_index" in this.state.group)) {
                                                                this.state.group.combi_index = 1;
                                                            } else {
                                                                if (this.state.group.combi_index > 0) {
                                                                    this.state.group.combi_index = this.state.group.combi_index - 1;
                                                                }
                                                            }
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }} className={(!("combi_index" in this.state.group) || this.state.group.combi_index === 0 ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-8 w-12 mr-4 rounded-full flex items-center justify-center"}>
                                                            <ArrowSmLeftIcon className="w-5 h-5" />
                                                        </div>
                                                        <div onClick={() => {
                                                            if (!("combi_index" in this.state.group)) {
                                                                this.state.group.combi_index = 1;
                                                            } else {
                                                                if (this.state.group.combi_index < this.state.group.combinations.length - 1) {
                                                                    this.state.group.combi_index = this.state.group.combi_index + 1;
                                                                }
                                                            }
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }} className={(this.state.group.combi_index === (this.state.group.combinations.length - 1) ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-8 w-12 rounded-full flex items-center justify-center"}>
                                                            <ArrowSmRightIcon className="w-5 h-5" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="w-full px-3">
                                                <div className="w-full relative">
                                                    {
                                                        Array.isArray(this.state.group.combinations) &&
                                                        this.state.group.format && this.state.group.format.id === 1 &&
                                                        this.state.group.combinations.filter((item, index) => {
                                                            return (!("combi_index" in this.state.group) && index === 0) || (this.state.group.combi_index === index)
                                                        }).map((item) => {
                                                            return (
                                                                <div className="mb-1">
                                                                    <PreviewGoogleSearch
                                                                        headlineFirst={item.headlineFirst.text}
                                                                        headlineSecond={item.headlineSecond.text}
                                                                        headlineThird={item.headlineThird.text}
                                                                        bodyOne={item.bodyOne.text}
                                                                        bodySecond={item.bodySecond.text}
                                                                        link={this.state.group.link}
                                                                        path1={this.state.group.path1}
                                                                        path2={this.state.group.path2}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    {
                                                        !Array.isArray(this.state.group.combinations) &&
                                                        this.state.group.format && this.state.group.format.id === 1 &&
                                                        <div className="w-full h-full flex items-center justify-center">
                                                            <div className="text-sm font-medium">Add minimum 3 headlines and 1 description for preview</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.state.keywordList &&
                                                <div className="flex flex-col px-3 h-auto relative overflow-x-scroll">
                                                    <div className="rounded-md overflow-scroll border mt-2 relative flex flex-1">
                                                        <table className="min-w-full divide-y divide-gray-300">
                                                            <thead className="bg-gray-50 sticky top-0">
                                                                <tr>
                                                                    <th scope="col" className="p-4 text-left whitespace-no-wrap text-xs font-medium text-gray-900">
                                                                        <div onClick={() => {
                                                                            this.setState({
                                                                                sort_suggestions: 'keyword',
                                                                                direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                                            })
                                                                        }} className="flex flex-row items-center cursor-pointer">
                                                                            <div className="mr-1">Keyword</div>
                                                                            <div className="flex flex-col">
                                                                                {
                                                                                    this.state.sort_suggestions === 'keyword' && this.state.direction_suggestions === 'ascending' &&
                                                                                    <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                                                }
                                                                                {
                                                                                    this.state.sort_suggestions === 'keyword' && this.state.direction_suggestions === 'descending' &&
                                                                                    <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th scope="col" className="p-4 text-left cursor-pointer text-xs whitespace-no-wrap font-medium text-gray-900">
                                                                        <div onClick={() => {
                                                                            this.setState({
                                                                                sort_suggestions: 'avg_monthly_searches',
                                                                                direction_suggestions: this.state.direction_suggestions === 'ascending' ? "descending" : "ascending"
                                                                            })
                                                                        }} className="flex flex-row items-center cursor-pointer">
                                                                            <div className="mr-1">Searches</div>
                                                                            <div className="flex flex-col">
                                                                                {
                                                                                    this.state.sort_suggestions === 'avg_monthly_searches' && this.state.direction_suggestions === 'ascending' &&
                                                                                    <ArrowSmUpIcon className="h-4 w-4 cursor-pointer" />
                                                                                }
                                                                                {
                                                                                    this.state.sort_suggestions === 'avg_monthly_searches' && this.state.direction_suggestions === 'descending' &&
                                                                                    <ArrowSmDownIcon className="h-4 w-4 cursor-pointer" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th scope="col" className="p-4 text-left whitespace-no-wrap text-xs font-medium text-gray-900">
                                                                        <div className="mr-1">Headline</div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                                {this.renders.sort(this.state.keywordList.keywords, this.state.sort_suggestions, this.state.direction_suggestions).map((person) => (
                                                                    <tr className="" key={person.id}>
                                                                        <td style={{ whiteSpace: "nowrap" }} className="whitespace-nowrap p-4 text-xs font-medium text-gray-900">
                                                                            {person.keyword}
                                                                        </td>
                                                                        <td className="whitespace-nowrap p-4 text-xs">{person.avg_monthly_searches}</td>
                                                                        <td className="whitespace-nowrap p-4 text-xs">
                                                                            <div onClick={() => {
                                                                                if (!person.inside_headline) {
                                                                                    this.props.onAddKeyword(person);
                                                                                }
                                                                            }} className={(person.inside_headline ? " bg-green-100 text-green-700" : "bg-blue-200 text-blue-700 cursor-pointer ") + " rounded-md h-5 flex justify-center items-center px-2"}>
                                                                                {(person.inside_headline ? <CheckIcon className='w-4 h-4 mr-2' /> : <PlusIcon className="w-4 h-4 mr-2" />)}
                                                                                Headline
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div className={"flex-1 flex h-full flex-col bg-white shadow-2xl"}>
                                        <div className="py-4 px-4 sm:px-6 bg-purple-900 ">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="flex flex-1 flex-row pr-14">
                                                    <div className="text-xl flex-1 flex leading-6 font-semibold text-white">
                                                        {this.state.title}
                                                    </div>
                                                    {
                                                        this.state.reach && this.state.reach.estimate_ready &&
                                                        <div className="flex">
                                                            <div className="flex flex-row">
                                                                <UserGroupIcon className="w-6 h-6 text-white mr-4" />
                                                                <div className="">
                                                                    <div className="text-xl leading-6 font-semibold text-white">
                                                                        {!this.state.reach.estimate_not_count ?
                                                                            (this.state.reach.users_lower_bound + "-" + this.state.reach.users_upper_bound) :
                                                                            <div className="text-xs font-normal">Potential reach estimates may not be available depending on your audience configuration.</div>
                                                                        }

                                                                    </div>
                                                                    <div className="text-xs text-white">
                                                                        Estimated audience size
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </Dialog.Title>
                                                <div onClick={() => {
                                                    if (this.props.onCancel && !this.state.loader) {
                                                        this.props.onCancel();
                                                    }
                                                }} className="ml-3 cursor-pointer h-12 w-12 flex justify-center transition-colors ease-in duration-75 hover:bg-red-700 text-white items-center rounded-full bg-red-500 ">
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-1 flex-col overflow-hidden">
                                            <div className="flex flex-1 relative flex-col justify-between overflow-x-scroll">
                                                <div className={(this.props.noPadding ? "" : "p-6") + " pb-0 pt-6 h-full"}>
                                                    {this.props.children}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex relative flex-shrink-0 justify-end p-6 border-t">
                                            {
                                                this.state.error &&
                                                <div style={{ bottom: '90px' }} className="absolute inset-x-0 px-6">
                                                    <div className="rounded-md bg-red-100 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <p className="text-sm font-medium text-red-800">{this.state.error}</p>
                                                            </div>
                                                            <div className="ml-auto pl-3">
                                                                <div className="-mx-2 -my-2">
                                                                    <button
                                                                        onClick={() => {
                                                                            if (this.props.onError) {
                                                                                this.props.onError(false);
                                                                            }
                                                                        }}
                                                                        type="button"
                                                                        className="inline-flex bg-green-50 rounded-md p-2 text-red-500 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-100 focus:ring-red-600"
                                                                    >
                                                                        <span className="sr-only">Dismiss</span>
                                                                        <XIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.thirdButton &&
                                                <div className="flex flex-1 justify-start">
                                                    <button
                                                        className={(this.state.thirdButtonWhite ? "rounded-md relative border-1.5 py-2 w-48 text-sm bg-white-500 font-medium text-gray-800 hover:bg-gray-200 focus:outline-none" : "rounded-md relative shadow py-2 px-4 text-sm bg-purple-500 font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2")}
                                                        onClick={() => {
                                                            if (this.props.onThirdButton && !this.state.thirdButtonLoader) {
                                                                this.props.onThirdButton();
                                                            }
                                                        }}
                                                    >
                                                        {this.props.thirdButtonText}
                                                        {
                                                            this.state.thirdButtonLoader &&
                                                            <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                            }
                                            {
                                                this.state.secondaryButton &&
                                                <button
                                                    className="rounded-md shadow relative overflow-hidden border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                    onClick={() => {
                                                        if (this.props.onSecondarySubmit && !this.state.loader) {
                                                            this.props.onSecondarySubmit();
                                                        }
                                                    }}
                                                >
                                                    {this.state.secondaryButtonText}
                                                    {
                                                        this.state.loaderSecondary &&
                                                        <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </button>
                                            }
                                            {
                                                !this.state.noSubmit &&
                                                <button
                                                    onClick={() => {
                                                        if (this.props.onSubmit && !this.state.loader && !this.state.disabled) {
                                                            this.props.onSubmit();
                                                        }
                                                    }}
                                                    className={(this.state.success ? "cursor-pointer bg-green-700 text-white" : (!this.state.disabled ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600")) + " shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                    }
                                                >
                                                    {this.state.success ? "Success!" : this.state.submitButtonText}
                                                    {
                                                        this.state.loader &&
                                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default SlideoutTailwind;
