import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import SlideDown from "react-slidedown";

class SelectCellChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            channels: {},
            accounts: {},
            campaigns: {},
            adgroups: {},
            ads: {}
        };
    };

    componentDidMount() {
        this.setState({
            tab: this.props.tab ? this.props.tab : {},
            channels: this.props.selectedChannels ? this.props.selectedChannels : {},
            accounts: this.props.selectedAccounts ? this.props.selectedAccounts : {},
            campaigns: this.props.selectedCampaigns ? this.props.selectedCampaigns : {},
            adgroups: this.props.selectedAdgroups ? this.props.selectedAdgroups : {},
            ads: this.props.selectedAds ? this.props.selectedAds : {},
            open: this.props.open
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab ? nextProps.tab : {},
            channels: nextProps.selectedChannels ? nextProps.selectedChannels : {},
            accounts: nextProps.selectedAccounts ? nextProps.selectedAccounts : {},
            campaigns: nextProps.selectedCampaigns ? nextProps.selectedCampaigns : {},
            adgroups: nextProps.selectedAdgroups ? nextProps.selectedAdgroups : {},
            ads: nextProps.selectedAds ? nextProps.selectedAds : {},
            open: nextProps.open
        })
    }

    functions = {
        valid: () => {
            return true;
        },
        chartTypes: () => {
            let chartTypes = [
                {
                    title: "Bar",
                    subtitle: "Display data in a bar chart",
                    icon: "bar-chart-2",
                    value: "bar"
                },
                {
                    title: "Line",
                    subtitle: "Display data in a line chart",
                    icon: "activity",
                    value: "line"
                },
                {
                    title: "Donut",
                    subtitle: "Display data in a donut chart",
                    icon: "pie-chart",
                    value: "donut"
                },
            ];
            return chartTypes;
        },
        donutFormValidation: () => {
            let amount_of_datasources = 0;
            let breakdowns = {};
            let channels = {};
            amount_of_datasources += Object.keys(this.state.channels).length;
            amount_of_datasources += Object.keys(this.state.accounts).length;
            amount_of_datasources += Object.keys(this.state.campaigns).length;
            amount_of_datasources += Object.keys(this.state.adgroups).length;
            amount_of_datasources += Object.keys(this.state.ads).length;
            Object.keys(this.state.accounts).map((item) => {
                channels[this.state.accounts[item].channel] = true;
            });
            Object.keys(this.state.campaigns).map((item) => {
                channels[this.state.campaigns[item].channel] = true;
            });
            Object.keys(this.state.adgroups).map((item) => {
                channels[this.state.adgroups[item].channel] = true;
            });
            Object.keys(this.state.ads).map((item) => {
                channels[this.state.ads[item].channel] = true;
            });
            for (let channel in this.state.tab.channel_breakdowns) {
                for (let breakdown in this.state.tab.channel_breakdowns[channel]) {
                    if (breakdown !== "basic") {
                        breakdowns[breakdown] = channel;
                    }
                }
            }
            if (amount_of_datasources > 0 && Object.keys(channels).length > 1 && ("overview" in breakdowns) && Object.keys(breakdowns).length === 1) {
                return true;
            } else if (amount_of_datasources > 0 && Object.keys(channels).length === 1) {
                return true;
            } else if (amount_of_datasources === 1) {
                return true;
            } else {
                return false;
            }
        }
    };

    render() {
        return (
            <>
                <div
                    className={(this.functions.valid() ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select chart type
                            </h3>
                            {
                                !this.state.tab.chart &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    There are <span
                                    className="font-bold ml-1 mr-1">{this.functions.chartTypes().length}</span> metrics
                                    available
                                </p>
                            }
                            {
                                this.state.tab.chart && this.state.tab.chart.value &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected <span
                                    className="font-bold ml-1 mr-1">{this.state.tab.chart.title}</span>
                                </p>
                            }
                        </div>
                        {
                            this.functions.valid() &&
                            <div className="flex flex-row">
                                {
                                    this.state.tab.chart &&
                                    <div
                                        className=" text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                                     icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                     className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                                     icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                                     icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="">
                            {
                                this.functions.chartTypes().map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div
                                                className={(((this.functions.donutFormValidation() && item.value === "donut") || item.value !== "donut") ? "cursor-pointer " : "cursor-not-allowed opacity-50 ") + (i !== (this.functions.chartTypes().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                <div
                                                    className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'}
                                                                 size={17}
                                                                 icon={item.icon} />
                                                </div>
                                                <div onClick={() => {
                                                    if ((this.functions.donutFormValidation() && item.value === "donut") || item.value !== "donut") {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = {
                                                                title: "",
                                                                chart_setting: { title: "Breakdown/Metric", subtitle: "Combine breakdown and metrics", value: "breakdown/metric" },
                                                                chart_numbers: { title: "Disabled", subtitle: "Inline values will only show on hover", value: "disabled" }
                                                            }
                                                        } else {
                                                            if (item.value === "donut" || item.value === "line") {
                                                                try {
                                                                    delete this.state.tab.settings.groupBreakdowns;
                                                                    delete this.state.tab.settings.groupBreakdownsGroups;
                                                                    delete this.state.tab.settings.groupBreakdownsType;
                                                                } catch (error) { }
                                                            }
                                                        }
                                                        this.state.tab.chart = item;
                                                        this.props.onTab(this.state.tab);
                                                        this.props.onNext();
                                                    }
                                                }}
                                                     className={((this.state.tab.chart && this.state.tab.chart.value === item.value) ? "border-purple-500" : "") + " h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                    {
                                                        this.state.tab.chart && this.state.tab.chart.value === item.value &&
                                                        <div
                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className="flex flex-1 flex-col">
                                                    <div className="font-medium text-sm">
                                                        {item.title}
                                                    </div>
                                                    <div className="text-xxs">
                                                        {item.subtitle}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.chart && this.state.tab.chart.value &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}

export default SelectCellChart