import React, { Component, Fragment } from 'react';
import fuzzysort from "fuzzysort";
import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";
import FeatherIcon from "feather-icons-react";
import SlideDown from "react-slidedown";
import InputTailwind from "../inputTailwind";

class SelectCellMetric extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            metrics: [],
            default_metrics: [],
            categories: [{ name: "custom" }, { name: "calculated" }],
            selected_channels_internal: {},
            //categories: [{ name: 'default' }, { name: "custom" }, { name: "calculated" }],
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            search_metric: ""
        };
    };

    componentDidMount() {
        let metrics = { ...(this.props.metrics ? this.props.metrics : {}) }
        this.setState({
            metrics: metrics,
            tab: this.props.tab ? this.props.tab : {},
            open: this.props.open,
            selected_channels_internal: this.props.selectedChannels ? this.props.selectedChannels : {},
            selected_accounts_internal: this.props.selectedAccounts ? this.props.selectedAccounts : {},
            selected_campaigns_internal: this.props.selectedCampaigns ? this.props.selectedCampaigns : {},
            selected_adgroups_internal: this.props.selectedAdgroups ? this.props.selectedAdgroups : {},
            selected_ads_internal: this.props.selectedAdsInternal ? this.props.selectedAdsInternal : {}
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let metrics = { ...(nextProps.metrics ? nextProps.metrics : {}) }
        this.setState({
            metrics: metrics,
            tab: nextProps.tab ? nextProps.tab : {},
            open: nextProps.open,
            selected_channels_internal: nextProps.selectedChannels ? nextProps.selectedChannels : {},
            selected_accounts_internal: nextProps.selectedAccounts ? nextProps.selectedAccounts : {},
            selected_campaigns_internal: nextProps.selectedCampaigns ? nextProps.selectedCampaigns : {},
            selected_adgroups_internal: nextProps.selectedAdgroups ? nextProps.selectedAdgroups : {},
            selected_ads_internal: nextProps.selectedAds ? nextProps.selectedAds : {}
        })
    }

    functions = {
        valid: () => {
            return this.state.tab && this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0;
        },
        color: (item) => {
            let colors = {
                hex: "",
                rgb: ""
            }
            const channelColor = {
                facebook: "#3A5998",
                google: "#4384F4",
                linkedin: "#0D65A1",
                google_analytics: "#e8710a",
                google_analytics_4: "#e8710a",
                google_shopping: "#34A853",
                adform: "#60C1A4",
                bidtheatre: "#f48e22",
                tiktok: "#000000",
                twitter: "#50ABF1",
                snapchat: "#FFFC00",
                bing: "#0C8484",
                dv360: "#34A853",
                cm360: "#ffffff",
                google_search_console: "#34A145",
            }
            colors.hex = channelColor[item.channel] || "#3A5998";

            colors.rgb = colors.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16));
            return colors;
        },
        getMetrics: () => {
            this.setState({
                loading: true
            }, () => {
                this.calls.getMetrics().then((response) => {
                    let metrics = { ...(response.data ? response.data : {}) }
                    this.setState({
                        metrics: metrics,
                        loading: true
                    });
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        },
        search: (search, options) => {
            if (!search || search == "") {
                return options;
            }
            let result = fuzzysort.go(search, options.map((item) => { return item.name }));
            let keywords = [];
            let old_keywords = JSON.parse(JSON.stringify(options));
            result.map((item) => {
                for (let i = 0; i < old_keywords.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_keywords[i].html = html_string;
                        keywords.push(old_keywords[i]);
                        old_keywords.splice(i, 1);
                        break;
                    }
                }
            });
            return keywords;
        },
        calculatedMetric: (metric) => {
            let new_string = JSON.parse(JSON.stringify(metric.calculation));
            for (let key in metric.metrics) {
                new_string = new_string.replace("<" + key + ">", metric.metrics[key].name);
            }
            return new_string;
        }
    };

    calls = {
        getMetrics: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v2/metrics?custom=true";
            return apiRegister.call(options, url);
        },
    };

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer truncate">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select metrics
                            </h3>
                            {
                                this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0 &&
                                <div className="text-xxs truncate pr-3">
                                    You have selected <span className="font-bold">{Object.keys(this.state.tab.metrics).join(", ")}</span>
                                </div>
                                ||
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Choose between default, custom and calculated
                                </p>
                            }
                        </div>
                        {
                            <div className="flex flex-row">
                                {
                                    this.functions.valid() &&
                                    <div
                                        className="mr-2 text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                                     icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                     className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                                     icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                                     icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="flex flex-1 mb-4 flex-col justify-center">
                            <InputTailwind
                                label={null}
                                placeholder={"Search metrics ..."}
                                value={this.state.search_metric}
                                onChange={(value) => {
                                    this.setState({
                                        search_metric: value
                                    });
                                }}
                            />
                        </div>
                        <div className="">
                            {
                                this.state.categories.map((category) => {
                                    return (
                                        <Fragment>
                                            <div className="">
                                                <div
                                                    className={"cursor-pointer flex flex-1 flex-row justify-center mb-2"}>
                                                    <div onClick={() => {
                                                        category.open = !category.open;
                                                        this.setState({
                                                            categories: this.state.categories
                                                        })
                                                    }}
                                                         className="text-purple-500 mr-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                                        {
                                                            !category.open &&
                                                            <FeatherIcon className={'stroke-current'} size={16}
                                                                         icon={"plus"} />
                                                        }
                                                        {
                                                            category.open &&
                                                            <FeatherIcon className={'stroke-current'} size={16}
                                                                         icon={"minus"} />
                                                        }
                                                    </div>
                                                    <div className="flex flex-1 flex-col truncate">
                                                        <div className="font-medium text-sm capitalize">
                                                            {category.name}
                                                        </div>
                                                        {
                                                            this.state.tab.metrics && this.state.metrics[category.name] && this.state.metrics[category.name].filter((item) => { return this.state.tab.metrics[item.name] }).length > 0 &&
                                                            <div className="text-xxs truncate">
                                                                You have selected <span className="font-bold">{Object.keys(this.state.tab.metrics).filter((metric_name) => { return this.state.metrics[category.name].filter((inner_item) => { return inner_item.name == metric_name }).length > 0 }).join(", ")}</span>
                                                            </div>
                                                            ||
                                                            <div className="text-xxs">
                                                                Your have {this.state.metrics[category.name] && this.state.metrics[category.name].length} metrics available
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <SlideDown closed={!category.open && this.state.search_metric == ""}>
                                                    {
                                                        this.state.metrics[category.name] && this.functions.search(this.state.search_metric, this.state.metrics[category.name]).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div className="flex flex-row pl-12">
                                                                        <div
                                                                            className={"cursor-pointer mb-2 flex flex-1 flex-row justify-center"}>
                                                                            {
                                                                                ((this.state.tab.celltype && this.state.tab.celltype.value === "number") || (this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.chart && this.state.tab.chart.value === "donut")) &&
                                                                                (
                                                                                    <div onClick={() => {

                                                                                        if (this.state.tab.metrics) {
                                                                                            this.state.tab.metrics = {};
                                                                                        }

                                                                                        //SET COLORS FOR EACH DATASOURCE
                                                                                        if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                                                                                            for (let key in this.state.selected_accounts_internal) {
                                                                                                if (!this.state.selected_accounts_internal[key].metric_color) {
                                                                                                    this.state.selected_accounts_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_accounts_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_accounts_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                                                                                            for (let key in this.state.selected_campaigns_internal) {
                                                                                                if (!this.state.selected_campaigns_internal[key].metric_color) {
                                                                                                    this.state.selected_campaigns_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_campaigns_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_campaigns_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                                                                                            for (let key in this.state.selected_adgroups_internal) {
                                                                                                if (!this.state.selected_adgroups_internal[key].metric_color) {
                                                                                                    this.state.selected_adgroups_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_adgroups_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_adgroups_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                                                                                            for (let key in this.state.selected_ads_internal) {
                                                                                                if (!this.state.selected_ads_internal[key].metric_color) {
                                                                                                    this.state.selected_ads_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_ads_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_ads_internal[key]);
                                                                                            }
                                                                                        }

                                                                                        this.state.tab.metrics[item.name] = item;
                                                                                        this.props.onTab(this.state.tab);
                                                                                        if (!(this.state.tab.celltype && this.state.tab.celltype.id === "performance")) {
                                                                                            this.props.onNext();
                                                                                        }

                                                                                    }}
                                                                                         className={(this.state.tab.metrics && this.state.tab.metrics[item.name] ? "border-purple-500" : "") + " h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                        {
                                                                                            this.state.tab.metrics && this.state.tab.metrics[item.name] &&
                                                                                            <div
                                                                                                className="w-full h-full rounded-full bg-purple-500" />
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                                ||
                                                                                (
                                                                                    <div onClick={() => {
                                                                                        if (!this.state.tab.metrics) {
                                                                                            this.state.tab.metrics = {};
                                                                                        }
                                                                                        if (!this.state.tab.table_metrics_index) {
                                                                                            this.state.tab.table_metrics_index = [];
                                                                                        }
                                                                                        if (item.name in this.state.tab.metrics) {
                                                                                            delete this.state.tab.metrics[item.name];
                                                                                            this.state.tab.table_metrics_index = this.state.tab.table_metrics_index.filter((metric) => {
                                                                                                return metric.name !== item.name
                                                                                            });
                                                                                        } else {
                                                                                            item.metric_type = "custom";
                                                                                            this.state.tab.metrics[item.name] = item;
                                                                                            this.state.tab.table_metrics_index.push(item);
                                                                                        }

                                                                                        //SET COLORS FOR EACH DATASOURCE
                                                                                        if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                                                                                            for (let key in this.state.selected_accounts_internal) {
                                                                                                if (!this.state.selected_accounts_internal[key].metric_color) {
                                                                                                    this.state.selected_accounts_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_accounts_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_accounts_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                                                                                            for (let key in this.state.selected_campaigns_internal) {
                                                                                                if (!this.state.selected_campaigns_internal[key].metric_color) {
                                                                                                    this.state.selected_campaigns_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_campaigns_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_campaigns_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                                                                                            for (let key in this.state.selected_adgroups_internal) {
                                                                                                if (!this.state.selected_adgroups_internal[key].metric_color) {
                                                                                                    this.state.selected_adgroups_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_adgroups_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_adgroups_internal[key]);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                                                                                            for (let key in this.state.selected_ads_internal) {
                                                                                                if (!this.state.selected_ads_internal[key].metric_color) {
                                                                                                    this.state.selected_ads_internal[key].metric_color = {};
                                                                                                }
                                                                                                this.state.selected_ads_internal[key].metric_color[item.id] = this.functions.color(this.state.selected_ads_internal[key]);
                                                                                            }
                                                                                        }

                                                                                        this.props.onTab(this.state.tab);
                                                                                    }}
                                                                                         className={(this.state.tab.metrics && this.state.tab.metrics[item.name] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                        {
                                                                                            this.state.tab.metrics && this.state.tab.metrics[item.name] &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={16}
                                                                                                icon="check" />
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            <div
                                                                                className="flex flex-1 flex-col">
                                                                                <div
                                                                                    className="font-medium text-sm">
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="text-xxs capitalize flex flex-wrap">
                                                                                    {
                                                                                        (category.name == "custom" || category.name == "default") &&
                                                                                        item.channels &&
                                                                                        Object.keys(item.channels).map((channel, index) => {
                                                                                            let last = index === (Object.keys(item.channels).length - 1);
                                                                                            return (<span className="mr-1">{channel.replace("_", " ").replace("_", " ")}{!last ? "," : ""}</span>)
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        category.name == "calculated" &&
                                                                                        <span>{this.functions.calculatedMetric(item)}</span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.tab.celltype && this.state.tab.celltype.id === "performance" &&
                                                                        this.state.tab.metrics && this.state.tab.metrics[item.name] &&
                                                                        <div className="w-full pl-12 mb-4">
                                                                            <InputTailwind
                                                                                label={item.name + " Target"}
                                                                                value={this.state.tab.settings && this.state.tab.settings.performance_target ? this.state.tab.settings.performance_target[item.name] : ""}
                                                                                onChange={(value) => {
                                                                                    if (!this.state.tab.settings) {
                                                                                        this.state.tab.settings = { title: "" };
                                                                                    }
                                                                                    if (!this.state.tab.settings.performance_target) {
                                                                                        this.state.tab.settings.performance_target = {};
                                                                                    }
                                                                                    this.state.tab.settings.performance_target[item.name] = value;
                                                                                    this.props.onTab(this.state.tab);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </SlideDown>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 0 &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}

export default SelectCellMetric