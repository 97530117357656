import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { Dialog, Transition } from '@headlessui/react'
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, PencilAltIcon, XIcon } from '@heroicons/react/outline'
import { BeatLoader } from 'react-spinners';
import SwitchTailwind from './switchTailwind';
import SingleDatepicker from '../modules/singleDatepicker';
import SlideDown from 'react-slidedown';
import ReactTooltip from 'react-tooltip';
import InputTailwind from './inputTailwind';
import DropdownTailwind from './dropdownTailwind';

class AdvancedReportSlideInDatasource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            tab: {},
            selected_channels: {},
            selected_accounts: {},
            selected_campaigns: {},
            selected_adgroups: {},
            selected_ads: {},
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            selected_cells: [],
            channels: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            preview: false,
            creative: false,
            input: false,
            updated_settings: {},
            cell_background_color: "ffffff",
            cell_font_color: "000000",
            cell_enable_styling: false,
            cell_shadow: false,
            cell_transparent: false,
            daterange: {},
            test_123: false,
        };
    };

    async componentDidMount() {
        this.setState({
            preview: this.props.previewSelected,
            creative: this.props.creativeSelected,
            input: this.props.inputSelected,
            master_template: this.props.masterTemplate,
            template: this.props.template,
            only_dates: this.props.onlyDates
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed,
            tab: nextProps.tab,
            ads: nextProps.ads ? nextProps.ads : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            accounts: nextProps.accounts ? nextProps.accounts : [],
            channels: nextProps.channels ? nextProps.channels : [],
            selected_cells: nextProps.selectedCells ? nextProps.selectedCells : [],
            preview: nextProps.previewSelected,
            creative: nextProps.creativeSelected,
            input: nextProps.inputSelected,
            cell_background_color: "ffffff",
            cell_font_color: "000000",
            cell_enable_styling: false,
            master_template: nextProps.masterTemplate,
            template: nextProps.template,
            only_dates: nextProps.onlyDates
        }, () => {
            if (!nextProps.closed) {
                this.setState({
                    cell_enable_styling: false,
                    selected_channels_internal: {},
                    selected_accounts_internal: {},
                    selected_campaigns_internal: {},
                    selected_adgroups_internal: {},
                    selected_ads_internal: {},
                    //selected_cells: [],
                    daterange: {},
                    //daterange: nextProps.tab.daterange ? nextProps.tab.daterange : {},
                    cell_background_color: "ffffff",
                    cell_font_color: "000000",
                    cell_shadow: false,
                    cell_transparent: false
                })
            }
        });
    }

    functions = {
        valid: () => {
            return true;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }
    //style={{ zIndex: 9999, position: "absolute" }}

    render() {
        return (
            <Transition.Root
                show={!this.state.closed} as={Fragment}
            >
                <Dialog as="div" style={{ zIndex: 9999, position: "absolute" }} className="absolute inset-0 overflow-hidden" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidde">
                        <Dialog.Overlay className="absolute inset-0 transition duration-500 ease-in-out bg-purple-900 bg-opacity-50" />
                        <div className="fixed inset-y-0 p-4 max-w-full right-0 flex pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-xl overflow-hidden rounded-md">
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="py-4 px-4 sm:px-6 bg-purple-900 ">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="flex flex-1 flex-row pr-14">
                                                        <div className="text-xl flex-1 flex leading-6 font-semibold text-white">
                                                            Update cells
                                                        </div>
                                                    </Dialog.Title>
                                                    <div onClick={() => {
                                                        this.props.toggle();
                                                    }} className="ml-3 cursor-pointer h-12 w-12 flex justify-center transition-colors ease-in duration-75 hover:bg-red-700 text-white items-center rounded-full bg-red-500 ">
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">

                                                {
                                                    !this.state.input && !this.state.creative &&
                                                    !this.state.closed &&
                                                    <SelectCellDaterange
                                                        tab={{ daterange: this.state.daterange }}
                                                        selectedCells={this.state.selected_cells}
                                                        onTab={async (tab) => {
                                                            await this.promisedSetState({
                                                                daterange: tab.daterange
                                                            });
                                                            if (this.state.daterange && this.state.daterange.value === "7_days") {
                                                                this.state.daterange.start_date = moment().subtract(7, 'days').format("YYYY-MM-DD");
                                                                this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                            } else if (this.state.daterange && this.state.daterange.value === "10_days") {
                                                                this.state.daterange.start_date = moment().subtract(10, 'days').format("YYYY-MM-DD");
                                                                this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                            } else if (this.state.daterange && this.state.daterange.value === "14_days") {
                                                                this.state.daterange.start_date = moment().subtract(14, 'days').format("YYYY-MM-DD");
                                                                this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                            } else if (this.state.daterange && this.state.daterange.value === "20_days") {
                                                                this.state.daterange.start_date = moment().subtract(20, 'days').format("YYYY-MM-DD");
                                                                this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                            } else if (this.state.daterange && this.state.daterange.value === "30_days") {
                                                                this.state.daterange.start_date = moment().subtract(30, 'days').format("YYYY-MM-DD");
                                                                this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                            } else if (this.state.daterange && this.state.daterange.value === "last_week") {
                                                                this.state.daterange.start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
                                                                this.state.daterange.end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD");
                                                            } else if (this.state.daterange && this.state.daterange.value === "last_month") {
                                                                this.state.daterange.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                                                                this.state.daterange.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                                                            } else if (this.state.daterange && this.state.daterange.value === "this_month") {
                                                                this.state.daterange.start_date = moment().startOf('month').format("YYYY-MM-DD");
                                                                if (moment().startOf('month').format("DD") == "01") {
                                                                    this.state.daterange.end_date = moment().format("YYYY-MM-DD");
                                                                } else {
                                                                    this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                                }
                                                            } else if (this.state.daterange && this.state.daterange.value === "custom_dates") {

                                                            } else if (this.state.daterange && this.state.daterange.value === "x_days") {
                                                                if (this.state.daterange.days && this.state.daterange.days.value) {
                                                                    this.state.daterange.start_date = moment().subtract(this.state.daterange.days.value, 'days').format("YYYY-MM-DD");
                                                                    this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                                }
                                                            }
                                                            await this.promisedSetState({
                                                                daterange: this.state.daterange
                                                            });
                                                        }}
                                                    />
                                                }

                                                {/* CHILD COMPONENT */}
                                                {
                                                    !this.state.only_dates &&
                                                    !this.state.closed &&
                                                    <SelectCellDatasource
                                                        masterTemplate={this.state.master_template}
                                                        template={this.state.template}
                                                        hideSettings={this.state.master_template && !this.state.template}
                                                        preview={this.state.preview}
                                                        creative={this.state.creative}
                                                        input={this.state.input}
                                                        tab={this.state.tab}
                                                        channels={this.state.channels}
                                                        accounts={this.state.accounts}
                                                        campaigns={this.state.campaigns}
                                                        adgroups={this.state.adgroups}
                                                        ads={this.state.ads}
                                                        selectedChannelsInternal={this.state.selected_channels_internal}
                                                        selectedAccountsInternal={this.state.selected_accounts_internal}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        selectedAdsInternal={this.state.selected_ads_internal}
                                                        enable_styling={JSON.parse(JSON.stringify(this.state.cell_enable_styling))}
                                                        background_color={JSON.parse(JSON.stringify(this.state.cell_background_color))}
                                                        font_color={JSON.parse(JSON.stringify(this.state.cell_font_color))}
                                                        shadow={JSON.parse(JSON.stringify(this.state.cell_shadow))}
                                                        transparent={JSON.parse(JSON.stringify(this.state.cell_transparent))}
                                                        open={this.state.datasource}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        getAdgroups={(campaign) => {
                                                            if (this.props.getAdgroups) {
                                                                this.props.getAdgroups(campaign);
                                                            }
                                                        }}
                                                        getAds={(adgroup) => {
                                                            if (this.props.getAds) {
                                                                this.props.getAds(adgroup);
                                                            }
                                                        }}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                datasource: !value
                                                            })
                                                        }}
                                                        onAccounts={(accounts) => {
                                                            this.setState({
                                                                accounts: accounts
                                                            })
                                                        }}
                                                        onCampaigns={(campaigns) => {
                                                            this.setState({
                                                                campaigns: campaigns
                                                            })
                                                        }}
                                                        onSelectedChannels={(channels) => {
                                                            this.setState({
                                                                selected_channels_internal: channels
                                                            })
                                                        }}
                                                        onSelectedAccounts={(accounts) => {
                                                            this.setState({
                                                                selected_accounts_internal: accounts
                                                            })
                                                        }}
                                                        onSelectedCampaigns={(campaigns) => {
                                                            this.setState({
                                                                selected_campaigns_internal: campaigns
                                                            })
                                                        }}
                                                        onAdgroups={(adgroups) => {
                                                            this.setState({
                                                                adgroups: adgroups
                                                            })
                                                        }}
                                                        onSelectedAdgroups={(adgroups) => {
                                                            this.setState({
                                                                selected_adgroups_internal: adgroups
                                                            })
                                                        }}
                                                        onAds={(ads) => {
                                                            this.setState({
                                                                ads: ads
                                                            })
                                                        }}
                                                        onSelectedAds={(ads) => {
                                                            this.setState({
                                                                selected_ads_internal: ads
                                                            })
                                                        }}
                                                        onNext={() => {

                                                        }}
                                                        ref="SelectCellDatasource"
                                                        onBackgroundColor={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_background_color: value,
                                                            })
                                                        }}
                                                        onFontColor={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_font_color: value,
                                                            })
                                                        }}
                                                        onShadow={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_shadow: value,
                                                            })
                                                        }}
                                                        onTransparent={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_transparent: value,
                                                            })
                                                        }}
                                                        onEnableStyling={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_enable_styling: value,
                                                            })
                                                        }}
                                                    />
                                                }

                                                {
                                                    !this.state.only_dates &&
                                                    !this.state.closed &&
                                                    <SelectCellSettings
                                                        masterTemplate={this.state.master_template}
                                                        template={this.state.template}
                                                        hideSettings={this.state.master_template && !this.state.template}
                                                        preview={this.state.preview}
                                                        creative={this.state.creative}
                                                        input={this.state.input}
                                                        tab={this.state.tab}
                                                        channels={this.state.channels}
                                                        accounts={this.state.accounts}
                                                        campaigns={this.state.campaigns}
                                                        adgroups={this.state.adgroups}
                                                        ads={this.state.ads}
                                                        selectedChannelsInternal={this.state.selected_channels_internal}
                                                        selectedAccountsInternal={this.state.selected_accounts_internal}
                                                        selectedCampaignsInternal={this.state.selected_campaigns_internal}
                                                        selectedAdgroupsInternal={this.state.selected_adgroups_internal}
                                                        selectedAdsInternal={this.state.selected_ads_internal}
                                                        enable_styling={JSON.parse(JSON.stringify(this.state.cell_enable_styling))}
                                                        background_color={JSON.parse(JSON.stringify(this.state.cell_background_color))}
                                                        font_color={JSON.parse(JSON.stringify(this.state.cell_font_color))}
                                                        shadow={JSON.parse(JSON.stringify(this.state.cell_shadow))}
                                                        transparent={JSON.parse(JSON.stringify(this.state.cell_transparent))}
                                                        open={this.state.datasource}
                                                        onTab={(tab) => {
                                                            this.setState({
                                                                tab: tab
                                                            })
                                                        }}
                                                        getAdgroups={(campaign) => {
                                                            if (this.props.getAdgroups) {
                                                                this.props.getAdgroups(campaign);
                                                            }
                                                        }}
                                                        getAds={(adgroup) => {
                                                            if (this.props.getAds) {
                                                                this.props.getAds(adgroup);
                                                            }
                                                        }}
                                                        onToggle={(value) => {
                                                            this.setState({
                                                                datasource: !value
                                                            })
                                                        }}
                                                        onAccounts={(accounts) => {
                                                            this.setState({
                                                                accounts: accounts
                                                            })
                                                        }}
                                                        onCampaigns={(campaigns) => {
                                                            this.setState({
                                                                campaigns: campaigns
                                                            })
                                                        }}
                                                        onSelectedChannels={(channels) => {
                                                            this.setState({
                                                                selected_channels_internal: channels
                                                            })
                                                        }}
                                                        onSelectedAccounts={(accounts) => {
                                                            this.setState({
                                                                selected_accounts_internal: accounts
                                                            })
                                                        }}
                                                        onSelectedCampaigns={(campaigns) => {
                                                            this.setState({
                                                                selected_campaigns_internal: campaigns
                                                            })
                                                        }}
                                                        onAdgroups={(adgroups) => {
                                                            this.setState({
                                                                adgroups: adgroups
                                                            })
                                                        }}
                                                        onSelectedAdgroups={(adgroups) => {
                                                            this.setState({
                                                                selected_adgroups_internal: adgroups
                                                            })
                                                        }}
                                                        onAds={(ads) => {
                                                            this.setState({
                                                                ads: ads
                                                            })
                                                        }}
                                                        onSelectedAds={(ads) => {
                                                            this.setState({
                                                                selected_ads_internal: ads
                                                            })
                                                        }}
                                                        onNext={() => {

                                                        }}
                                                        ref="SelectCellDatasource"
                                                        onBackgroundColor={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_background_color: value,
                                                            })
                                                        }}
                                                        onFontColor={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_font_color: value,
                                                            })
                                                        }}
                                                        onShadow={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_shadow: value,
                                                            })
                                                        }}
                                                        onTransparent={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_transparent: value,
                                                            })
                                                        }}
                                                        onEnableStyling={async (value) => {
                                                            await this.promisedSetState({
                                                                cell_enable_styling: value,
                                                            })
                                                        }}
                                                    />
                                                }

                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            <button
                                                type="button"
                                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => {
                                                    this.props.toggle()
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={async () => {
                                                    if (this.functions.valid()) {
                                                        this.props.onCells({
                                                            daterange: this.state.daterange,
                                                            channels: this.state.selected_channels_internal,
                                                            accounts: this.state.selected_accounts_internal,
                                                            campaigns: this.state.selected_campaigns_internal,
                                                            adgroups: this.state.selected_adgroups_internal,
                                                            ads: this.state.selected_ads_internal,
                                                            enable_styling: this.state.cell_enable_styling,
                                                            background_color: this.state.cell_background_color,
                                                            font_color: this.state.cell_font_color,
                                                            shadow: this.state.cell_shadow,
                                                            transparent: this.state.cell_transparent
                                                        });
                                                        this.props.toggle();
                                                    }
                                                }}
                                                className={(this.functions.valid() ? "" : "opacity-50 cursor-not-allowed") + " ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                Update cells
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

}

class SelectCellDaterange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            channels: {},
            accounts: {},
            campaigns: {},
            adgroups: {},
            ads: {},
            dateranges: [],
            x_days: []
        };
    };

    componentDidMount() {
        let dateranges = [
            {
                title: "Last 7 days",
                subtitle: ("From " + moment().subtract(7, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "7_days",
                disabled: false
            },
            {
                title: "Last 10 days",
                subtitle: ("From " + moment().subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "10_days",
                disabled: false
            },
            {
                title: "Last 14 days",
                subtitle: ("From " + moment().subtract(14, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "14_days",
                disabled: false
            },
            {
                title: "last 20 days",
                subtitle: ("From " + moment().subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "20_days",
                disabled: false
            },
            {
                title: "Last 30 days",
                subtitle: ("From " + moment().subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "30_days",
                disabled: false
            },
            {
                title: "Last X days",
                subtitle: "Select X amounts of days",
                icon: "calendar",
                value: "x_days",
                disabled: false
            },
            {
                title: "Last week",
                subtitle: ("From " + moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_week",
                disabled: false
            },
            {
                title: "Last month",
                subtitle: ("From " + moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_month",
                disabled: false
            },
            {
                title: "This month",
                subtitle: ("From " + moment().startOf('month').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "this_month",
                disabled: false
            },
            {
                title: "Custom dates",
                subtitle: "Add custom start date and end date",
                icon: "calendar",
                value: "custom_dates",
                disabled: false
            }
        ];
        this.setState({
            x_days: Array.from({ length: 364 }, (_, i) => i + 2).map((item) => { return { id: item, title: item, name: item + " days", value: item } }),
            dateranges: dateranges,
            tab: this.props.tab ? this.props.tab : {},
            channels: this.props.selectedChannels ? this.props.selectedChannels : {},
            accounts: this.props.selectedAccounts ? this.props.selectedAccounts : {},
            campaigns: this.props.selectedCampaigns ? this.props.selectedCampaigns : {},
            adgroups: this.props.selectedAdgroups ? this.props.selectedAdgroups : {},
            ads: this.props.selectedAds ? this.props.selectedAds : {},

            preview: this.props.preview,
            creative: this.props.creative,
            chart: this.props.chart,
            input: this.props.input
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab ? nextProps.tab : {},
            channels: nextProps.selectedChannels ? nextProps.selectedChannels : {},
            accounts: nextProps.selectedAccounts ? nextProps.selectedAccounts : {},
            campaigns: nextProps.selectedCampaigns ? nextProps.selectedCampaigns : {},
            adgroups: nextProps.selectedAdgroups ? nextProps.selectedAdgroups : {},
            ads: nextProps.selectedAds ? nextProps.selectedAds : {},

            preview: nextProps.preview,
            creative: nextProps.creative,
            chart: nextProps.chart,
            input: nextProps.input
        })
    }

    functions = {
        valid: () => {
            return true;
        },
        dailySelected: () => {
            let daily_selected = false;
            if (this.state.tab.channel_breakdowns) {
                for (let channel in this.state.tab.channel_breakdowns) {
                    for (let breakdown in this.state.tab.channel_breakdowns[channel]) {
                        if (breakdown == "date" || breakdown == "ga:date") {
                            daily_selected = true;
                        }
                    }
                }
            }
            return daily_selected;
        },
        compareFormValidation: () => {
            let amount_of_datasources = 0;
            amount_of_datasources += Object.keys(this.state.channels).length;
            amount_of_datasources += Object.keys(this.state.accounts).length;
            amount_of_datasources += Object.keys(this.state.campaigns).length;
            amount_of_datasources += Object.keys(this.state.adgroups).length;
            amount_of_datasources += Object.keys(this.state.ads).length;
            if ((amount_of_datasources > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart") || (this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart")) {
                return false;
            } else if (this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.chart && this.state.tab.chart.value === "donut") {
                return false;
            } else {
                return true;
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    {
                        !this.state.preview &&
                        !this.state.creative &&
                        !this.state.input &&
                        <div className="flex flex-1 flex-row justify-center items-center">
                            <div onClick={() => {
                                this.setState({
                                    open: !this.state.open
                                })
                            }} className="flex flex-col flex-1 cursor-pointer">
                                <h3 className="mb-0 font-semibold text-sm">
                                    Select date range
                                </h3>
                                {
                                    !(this.state.tab.daterange && this.state.tab.daterange.value) &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        There are <span
                                            className="font-bold ml-1 mr-1">{this.state.dateranges.length}</span> ranges
                                        available
                                    </p>
                                }
                                {
                                    this.state.tab.daterange && this.state.tab.daterange.value &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        You have selected <span
                                            className="font-bold ml-1 mr-1">{this.state.tab.daterange.title}</span>
                                    </p>
                                }
                            </div>
                            <div className="relative overflow-hidden mt-1">
                                <SwitchTailwind
                                    value={this.state.enable_daterange ? true : false}
                                    onSwitch={async () => {
                                        await this.promisedSetState({ enable_daterange: !this.state.enable_daterange });
                                        if (!this.state.enable_daterange) {
                                            this.state.tab.daterange = {};
                                            await this.promisedSetState({
                                                tab: this.state.tab
                                            });
                                            this.props.onTab(this.state.tab);
                                        }
                                    }}
                                />
                            </div>
                            {
                                this.state.enable_daterange &&
                                this.functions.valid() &&
                                <div className="flex flex-row ml-5">
                                    <div onClick={() => {
                                        this.setState({
                                            open: !this.state.open
                                        })
                                    }}
                                        className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        {
                                            !this.state.open &&
                                            <FeatherIcon className={'stroke-current'} size={16}
                                                icon={"chevron-down"} />
                                        }
                                        {
                                            this.state.open &&
                                            <FeatherIcon className={'stroke-current'} size={16}
                                                icon={"chevron-up"} />
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        !this.state.preview &&
                        !this.state.creative &&
                        !this.state.input &&
                        this.state.enable_daterange &&
                        <SlideDown closed={!this.state.open}>
                            <div className="w-full h-6" />
                            <div className="">
                                {
                                    this.state.dateranges.map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <div
                                                    className={((this.functions.dailySelected() && item.value == "lifetime") ? "opacity-50 cursor-not-allowed" : "") + " mb-2 flex flex-1 flex-row justify-center"}>
                                                    <div onClick={() => {

                                                    }}
                                                        className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={17} icon={item.icon} />
                                                    </div>
                                                    <div onClick={() => {
                                                        if (!(this.functions.dailySelected() && item.value == "lifetime")) {
                                                            if (this.state.tab.daterange && this.state.tab.daterange.compare_dates) {
                                                                item.compare_dates = this.state.tab.daterange.compare_dates;
                                                            }
                                                            if (this.state.tab.daterange && this.state.tab.daterange.compare_last_year) {
                                                                item.compare_last_year = this.state.tab.daterange.compare_last_year;
                                                            }
                                                            this.state.tab.daterange = item;
                                                            if (item.value === "x_days") {
                                                                this.state.tab.daterange.days = { id: 1, title: "1", name: "1 day", value: 1 };
                                                            } else {
                                                                delete this.state.tab.daterange.days;
                                                            }
                                                            this.setState({
                                                                tab: this.state.tab
                                                            }, () => {
                                                                this.props.onTab(this.state.tab);
                                                            });
                                                        }
                                                    }}
                                                        className={`cursor-pointer h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center ${this.functions.dailySelected() && item.value == "lifetime" && "cursor-not-allowed"} ${this.state.tab.daterange && this.state.tab.daterange.value === item.value && "border-purple-500"}`}
                                                    >
                                                        {
                                                            this.state.tab.daterange && this.state.tab.daterange.value === item.value &&
                                                            <div
                                                                className="w-full h-full rounded-full bg-purple-500" />
                                                        }
                                                    </div>
                                                    <div className="flex flex-1 flex-col">
                                                        <div className="font-medium text-sm">
                                                            {item.title}
                                                        </div>
                                                        {
                                                            (item.value === "custom_dates" && this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) ?
                                                                (<div className="text-xxs">
                                                                    From {this.state.tab.daterange.start_date} to {this.state.tab.daterange.end_date}
                                                                </div>) :
                                                                (<div className="text-xxs">
                                                                    {item.subtitle}
                                                                </div>)
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    item.value === "x_days" &&
                                                    this.state.tab.daterange && this.state.tab.daterange.value === "x_days" &&
                                                    <div className="w-full relative mb-2">
                                                        <DropdownTailwind
                                                            searchInput={true}
                                                            selected={this.state.tab.daterange && this.state.tab.daterange.days ? this.state.tab.daterange.days : { id: 0, name: "Select X days" }}
                                                            options={this.state.x_days}
                                                            onChange={(value) => {
                                                                if (!this.state.tab.daterange) {
                                                                    this.state.tab.daterange = {};
                                                                }
                                                                this.state.tab.daterange.days = value;
                                                                this.setState({
                                                                    tab: this.state.tab
                                                                }, () => {
                                                                    this.props.onTab(this.state.tab);
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" &&
                                <div className="w-full border-1.5 rounded-md mt-4 p-4 pb-0 pt-2 bg-custom-input">
                                    <SingleDatepicker
                                        large={true}
                                        maxDate={new Date()}
                                        from={this.state.tab.daterange.start_date}
                                        to={this.state.tab.daterange.end_date}
                                        onChange={(data) => {
                                            if (!this.state.tab.daterange) {
                                                this.state.tab.daterange = {};
                                            }
                                            this.state.tab.daterange.start_date = data.start_date;
                                            this.state.tab.daterange.end_date = data.end_date;
                                            this.setState({
                                                tab: this.state.tab
                                            }, () => {
                                                this.props.onTab(this.state.tab);
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                <div className={(this.functions.compareFormValidation() ? "" : "opacity-50 ") + " font-medium text-xs truncate mb-2 mt-5"}>
                                    Comparison settings
                                </div>
                            }
                            {
                                <div className="mb-2">
                                    <div className={(this.functions.compareFormValidation() ? "" : "opacity-50 cursor-not-allowed") + " flex flex-row"}>
                                        <div
                                            className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                            <FeatherIcon className={'stroke-current'}
                                                size={17} icon={"trending-up"} />
                                        </div>
                                        <div onClick={() => {
                                            if (this.functions.compareFormValidation()) {
                                                if (!this.state.tab.daterange) {
                                                    this.state.tab.daterange = {};
                                                    this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" };
                                                } else if (!this.state.tab.daterange.compare_dates) {
                                                    this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" }
                                                } else {
                                                    delete this.state.tab.daterange.compare_last_year;
                                                    delete this.state.tab.daterange.compare_dates;
                                                    delete this.state.tab.daterange.compare_dates;
                                                }
                                                this.setState({
                                                    tab: this.state.tab
                                                }, () => {
                                                    this.props.onTab(this.state.tab);
                                                });
                                            }
                                        }}
                                            className={(this.functions.compareFormValidation() ? "cursor-pointer " : "cursor-not-allowed ") + (this.functions.compareFormValidation() && this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                            {
                                                this.functions.compareFormValidation() &&
                                                this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                                                <FeatherIcon
                                                    className={'stroke-current'}
                                                    size={16}
                                                    icon="check" />
                                            }
                                        </div>
                                        <div className="flex flex-1 flex-col truncate">
                                            <div className="font-medium text-sm truncate">
                                                Compare with preceding period
                                            </div>
                                            <div className="text-xxs">
                                                Evaluate selected time period with previous period
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                !this.functions.compareFormValidation() &&
                                <div className="col-span-6 p-3 rounded-md font-medium text-xs text-orange-500 bg-orange-100 mb-2">
                                    OBS! Selection is not supported for comparing with preceding period
                                </div>
                            }
                            {
                                this.functions.compareFormValidation() &&
                                this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                                <div className="relative">
                                    {
                                        this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                        <div className="bg-white bg-opacity-50 absolute left-0 right-0 bottom-0 top-0"></div>
                                    }
                                    <div className="flex flex-row">
                                        <div
                                            className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                            <FeatherIcon className={'stroke-current'}
                                                size={17} icon={"clock"} />
                                        </div>
                                        <div onClick={() => {
                                            if (!this.state.tab.daterange.compare_last_year) {
                                                this.state.tab.daterange.compare_last_year = { title: "Enabled", value: "enabled" }
                                            } else {
                                                delete this.state.tab.daterange.compare_last_year;
                                            }
                                            this.setState({
                                                tab: this.state.tab
                                            }, () => {
                                                this.props.onTab(this.state.tab);
                                            });
                                        }}
                                            className={(this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                            {
                                                this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" &&
                                                <FeatherIcon
                                                    className={'stroke-current'}
                                                    size={16}
                                                    icon="check" />
                                            }
                                        </div>
                                        <div className="flex flex-1 flex-col">
                                            <div className="font-medium text-sm">
                                                Same daterange one year ago
                                            </div>
                                            <div className="text-xxs">
                                                Selected period one year ago
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.functions.compareFormValidation() &&
                                this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                                <div className="mt-2 relative">
                                    {
                                        this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" &&
                                        <div className="bg-white bg-opacity-50 absolute left-0 right-0 bottom-0 top-0"></div>
                                    }
                                    <div className="flex flex-row">
                                        <div
                                            className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                            <FeatherIcon className={'stroke-current'}
                                                size={17} icon={"calendar"} />
                                        </div>
                                        <div onClick={() => {
                                            if (!this.state.tab.daterange.compare_custom_date) {
                                                this.state.tab.daterange.compare_custom_date = { title: "Enabled", value: "enabled" }
                                            } else {
                                                delete this.state.tab.daterange.compare_custom_date;
                                            }
                                            this.setState({
                                                tab: this.state.tab
                                            }, () => {
                                                this.props.onTab(this.state.tab);
                                            });
                                        }}
                                            className={(this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                            {
                                                this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                                <FeatherIcon
                                                    className={'stroke-current'}
                                                    size={16}
                                                    icon="check" />
                                            }
                                        </div>
                                        <div className="flex flex-1 flex-col truncate">
                                            <div className="font-medium text-sm">
                                                Custom daterange
                                            </div>
                                            <div className="text-xxs">
                                                {
                                                    this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                                    this.state.tab.daterange.compare_custom_date.start_date &&
                                                    this.state.tab.daterange.compare_custom_date.end_date &&
                                                    <span> From {this.state.tab.daterange.compare_custom_date.start_date} to {this.state.tab.daterange.compare_custom_date.end_date}</span>
                                                    ||
                                                    <span>Select daterange</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                <div className="w-full border-1.5 rounded-md mt-4 p-4 pt-2 pb-0 bg-custom-input">
                                    <SingleDatepicker
                                        large={true}
                                        maxDate={new Date()}
                                        from={this.state.tab.daterange.compare_custom_date.start_date}
                                        to={this.state.tab.daterange.compare_custom_date.end_date}
                                        onChange={(data) => {
                                            this.state.tab.daterange.compare_custom_date.start_date = data.start_date;
                                            this.state.tab.daterange.compare_custom_date.end_date = data.end_date;
                                            this.setState({
                                                tab: this.state.tab
                                            }, () => {
                                                this.props.onTab(this.state.tab);
                                            });
                                        }}
                                    />
                                </div>
                            }
                        </SlideDown>
                    }
                </div>
            </>
        )
    }
}

class SelectCellDatasource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            accounts: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            search_campaigns: ""
        };
    };

    componentDidMount() {
        this.setState({
            masterTemplate: this.props.masterTemplate,
            template: this.props.template,
            tab: this.props.tab,
            open: this.props.open,
            accounts: this.props.accounts ? this.props.accounts : [],
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            selected_channels_internal: this.props.selectedChannelsInternal ? this.props.selectedChannelsInternal : {},
            selected_account_internal: this.props.selectedAccountsInternal ? this.props.selectedAccountsInternal : {},
            selected_campaigns_internal: this.props.selectedCampaignsInternal ? this.props.selectedCampaignsInternal : {},
            selected_adgroups_internal: this.props.selectedAdgroupsInternal ? this.props.selectedAdgroupsInternal : {},
            selected_ads_internal: this.props.selectedAdsInternal ? this.props.selectedAdsInternal : {},

            preview: this.props.preview,
            creative: this.props.creative,
            input: this.props.input,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            masterTemplate: nextProps.masterTemplate,
            template: nextProps.template,
            tab: nextProps.tab,
            open: nextProps.open,
            accounts: nextProps.accounts ? nextProps.accounts : [],
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            selected_channels_internal: nextProps.selectedChannelsInternal ? nextProps.selectedChannelsInternal : {},
            selected_accounts_internal: nextProps.selectedAccountsInternal ? nextProps.selectedAccountsInternal : {},
            selected_campaigns_internal: nextProps.selectedCampaignsInternal ? nextProps.selectedCampaignsInternal : {},
            selected_adgroups_internal: nextProps.selectedAdgroupsInternal ? nextProps.selectedAdgroupsInternal : {},
            selected_ads_internal: nextProps.selectedAdsInternal ? nextProps.selectedAdsInternal : {},

            preview: nextProps.preview,
            creative: nextProps.creative,
            input: nextProps.input
        });
    }

    functions = {
        color: (item) => {
            let colors = {
                hex: "",
                rgb: ""
            }
            colors.hex = "#3A5998";
            if (item.channel === "facebook") {
                colors.hex = "#3A5998";
            } else if (item.channel === "google") {
                colors.hex = "#4384F4";
            } else if (item.channel === "linkedin") {
                colors.hex = "#0D65A1";
            } else if (item.channel === "google_analytics") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_analytics_4") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_shopping") {
                colors.hex = "#34A853";
            } else if (item.channel === "adform") {
                colors.hex = "#60C1A4";
            } else if (item.channel === "bidtheatre") {
                colors.hex = "#f48e22";
            } else if (item.channel === "tiktok") {
                colors.hex = "#000000";
            } else if (item.channel === "twitter") {
                colors.hex = "#50ABF1";
            } else if (item.channel === "snapchat") {
                colors.hex = "#FFFC00";
            } else if (item.channel === "bing") {
                colors.hex = "#0C8484";
            } else if (item.channel === "dv360") {
                colors.hex = "#34A853";
            } else if (item.channel === "google_search_console") {
                colors.hex = "#34A145";
            }
            colors.rgb = colors.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16));
            return colors;
        },
        valid: () => {
            return true;
        },
        multipleLevelsSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 1
        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 0
        }
    };

    renders = {
        preview: () => {
            try {
                return this.state.tab.celltype.value === "preview"
            } catch (error) {
                return false
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div
                    className={" pt-6 px-4 sm:px-6 pb-6 border-b"}>
                    {
                        !this.state.creative &&
                        !this.state.input &&
                        //this.state.enable_datasoruce &&
                        <div className="flex flex-1 flex-row justify-center items-center">
                            <div onClick={() => {
                                if (this.props.onToggle) {
                                    this.props.onToggle(this.state.open);
                                }
                            }} className="flex flex-col flex-1 cursor-pointer">
                                <h3 className="mb-0 text-sm font-semibold">
                                    Select data source
                                </h3>
                                {
                                    !this.functions.anythingSelected() &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Display data from selected data sources
                                    </p>
                                }
                                {
                                    this.functions.anythingSelected() &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        You have selected
                                        {
                                            this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0 &&
                                            <span className="font-bold ml-1"> {Object.keys(this.state.selected_channels_internal).length} channels</span>
                                        }
                                        {
                                            this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0 &&
                                            <span className="font-bold ml-1"> {Object.keys(this.state.selected_accounts_internal).length} accounts</span>
                                        }
                                        {
                                            this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0 &&
                                            <span className="font-bold ml-1"> {Object.keys(this.state.selected_campaigns_internal).length} campaigns</span>
                                        }
                                        {
                                            this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0 &&
                                            <span className="font-bold ml-1"> {Object.keys(this.state.selected_adgroups_internal).length} adgroups</span>
                                        }
                                        {
                                            this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0 &&
                                            <span className="font-bold ml-1"> {Object.keys(this.state.selected_ads_internal).length} ads</span>
                                        }
                                    </p>
                                }
                            </div>
                            <div className="relative overflow-hidden mt-1">
                                <SwitchTailwind
                                    value={this.state.enable_datasoruce ? true : false}
                                    onSwitch={async () => {
                                        await this.promisedSetState({ enable_datasoruce: !this.state.enable_datasoruce });
                                        if (!this.state.enable_datasoruce) {
                                            await this.promisedSetState({
                                                selected_channels_internal: {},
                                                selected_accounts_internal: {},
                                                selected_campaigns_internal: {},
                                                selected_adgroups_internal: {},
                                                selected_ads_internal: {},
                                            });
                                            this.props.onSelectedChannels(this.state.selected_channels_internal);
                                            this.props.onSelectedAccounts(this.state.selected_accounts_internal);
                                            this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);
                                            this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);
                                            this.props.onSelectedAds(this.state.selected_ads_internal);
                                        }
                                    }}
                                />
                            </div>
                            {
                                this.state.enable_datasoruce &&
                                <div className="flex flex-row ml-5">
                                    <div onClick={() => {
                                        if (this.props.onToggle) {
                                            this.props.onToggle(this.state.open);
                                        }
                                    }}
                                        className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        {
                                            !this.state.open &&
                                            <FeatherIcon className={'stroke-current'} size={17}
                                                icon={"chevron-down"} />
                                        }
                                        {
                                            this.state.open &&
                                            <FeatherIcon className={'stroke-current'} size={17}
                                                icon={"chevron-up"} />
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {
                        !this.state.creative &&
                        !this.state.input &&
                        this.state.enable_datasoruce &&
                        <SlideDown closed={!this.state.open}>
                            {
                                (this.state.masterTemplate || this.state.template) &&
                                <Fragment>
                                    <div className="w-full h-4" />
                                    {
                                        false &&
                                        <div className="text-xs font-bold mb-1">Channels</div>
                                    }
                                </Fragment>
                            }
                            {
                                (this.state.masterTemplate || this.state.template) &&
                                [
                                    { id: "adform", name: "adform", value: "adform", channel: "adform" },
                                    { id: "bidtheatre", name: "bidtheatre", value: "bidtheatre", channel: "bidtheatre" },
                                    { id: "bing", name: "bing", value: "bing", channel: "bing" },
                                    { id: "dv360", name: "dv360", value: "dv360", channel: "dv360" },
                                    { id: "facebook", name: "facebook", value: "facebook", channel: "facebook" },
                                    { id: "google", name: "google", value: "google", channel: "google" },
                                    { id: "google_analytics_4", name: "Google Analytics 4", value: "google_analytics_4", channel: "google_analytics_4" },
                                    { id: "linkedin", name: "linkedin", value: "linkedin", channel: "linkedin" },
                                    { id: "snapchat", name: "snapchat", value: "snapchat", channel: "snapchat" },
                                    { id: "tiktok", name: "tiktok", value: "tiktok", channel: "tiktok" },
                                    { id: "twitter", name: "twitter", value: "twitter", channel: "twitter" }
                                ].map((account, index) => {
                                    return (
                                        <div className="flex flex-1 flex-row justify-center mb-2">
                                            <div className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">

                                            </div>
                                            {
                                                this.renders.preview() &&
                                                <div onClick={() => {
                                                    if (!account.is_loading) {
                                                        if (this.state.selected_channels_internal[account.id]) {
                                                            delete this.state.selected_channels_internal[account.id];
                                                        } else {
                                                            this.state.selected_channels_internal = {};
                                                            let colors = this.functions.color(account);
                                                            account.hex = colors.hex;
                                                            account.rgb = colors.rgb;
                                                            this.state.selected_channels_internal[account.id] = account;
                                                            if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                this.state.tab.breakdown = {
                                                                    title: "Ads",
                                                                    subtitle: "Ads",
                                                                    icon: "globe",
                                                                    value: "ads"
                                                                };
                                                            }
                                                        }
                                                        this.props.onTab(this.state.tab);
                                                        this.props.onSelectedChannels(this.state.selected_channels_internal);
                                                    }
                                                }}
                                                    className={((this.state.selected_channels_internal[account.id]) ? "border-purple-500" : "") + " h-10 w-10 cursor-pointer p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                    {
                                                        this.state.selected_channels_internal[account.id] &&
                                                        <div
                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                            }
                                            {
                                                !this.renders.preview() &&
                                                <div onClick={() => {
                                                    if (!account.is_loading) {
                                                        if (this.state.selected_channels_internal[account.id]) {
                                                            delete this.state.selected_channels_internal[account.id]
                                                        } else {
                                                            let colors = this.functions.color(account);
                                                            account.hex = colors.hex;
                                                            account.rgb = colors.rgb;
                                                            this.state.selected_channels_internal[account.id] = account;
                                                        }
                                                        this.props.onSelectedChannels(this.state.selected_channels_internal);
                                                    }
                                                }}
                                                    className={(this.state.selected_channels_internal[account.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                    {
                                                        this.state.selected_channels_internal[account.id] &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={14}
                                                            icon="check" />
                                                    }
                                                    {
                                                        account.is_loading &&
                                                        <div
                                                            className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                            <BeatLoader
                                                                sizeUnit={"px"}
                                                                size={4}
                                                                color={'#060534'}
                                                                loading={true}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                account.channel === "google" &&
                                                <div
                                                    className="bg-google-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/google_icon.svg')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "google_shopping" &&
                                                <div
                                                    className="bg-googleshopping-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/google-shopping.png')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "linkedin" &&
                                                <div
                                                    className="bg-linkedin-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/linkedin_icon.svg')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "facebook" &&
                                                <div
                                                    className="bg-facebook-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img style={{ width: '0.6rem' }}
                                                        src={require('../assets/images/facebook_icon.svg')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "google_analytics" &&
                                                <div
                                                    className="bg-googleanalytics-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/google_analytics_icon.png')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "google_analytics_4" &&
                                                <div
                                                    className="bg-googleanalytics-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/ga_icon.png')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "adform" &&
                                                <div
                                                    className="bg-adform-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/adform_icon.png')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "twitter" &&
                                                <div
                                                    className="bg-twitter-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/twitter_icon.svg')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "tiktok" &&
                                                <div
                                                    className="bg-tiktok-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/tiktok_icon.png')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "bidtheatre" &&
                                                <div
                                                    className="bg-bidtheatre-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/bidtheatre_icon.png')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "snapchat" &&
                                                <div
                                                    className="bg-snapchat-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/snapchat.svg')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "bing" &&
                                                <div
                                                    className="bg-bing-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/bing-logo.png')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "dv360" &&
                                                <div
                                                    className="bg-dv360-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/dv360-logo.svg')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "cm360" &&
                                                <div
                                                    className="bg-cm360-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                    <img className="w-4"
                                                        src={require('../assets/images/cm360-logo.png')} />
                                                </div>
                                            }
                                            {
                                                account.channel === "google_search_console" &&
                                                <div
                                                    className="bg-dv360-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                    <img className="w-3"
                                                        src={require('../assets/images/google_search_console.png')} />
                                                </div>
                                            }
                                            <div className="flex flex-1 flex-col truncate">
                                                <div className="font-semibold text-sm truncate">
                                                    {account.name}
                                                </div>
                                                <div className="text-xxs flex flex-row">
                                                    Account level
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.masterTemplate &&
                                <div>
                                    <div className="text-xs font-bold mt-4 mb-1">
                                        Account level data
                                    </div>
                                    <div className='flex flex-col w-full'>
                                        <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className={"flex flex-1 text-sm ml-5"}>
                                                {this.state.tab.settings && this.state.tab.settings.overwrite_tags ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.tab.settings && this.state.tab.settings.overwrite_tags ? true : false}
                                                    onSwitch={async () => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                        }
                                                        this.state.tab.settings.overwrite_tags = !this.state.tab.settings.overwrite_tags;
                                                        this.props.onTab(this.state.tab);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.masterTemplate &&
                                //this.state.tab.settings && this.state.tab.settings.overwrite_tags &&
                                <div className="mt-4 bg-blue-100 text-blue-500 font-medium text-sm p-4 rounded-md">
                                    OBS! If enabled this cell will always fetch data from account level
                                </div>
                            }
                            {
                                !this.state.masterTemplate &&
                                !this.state.template &&
                                this.state.accounts.length > 0 &&
                                <Fragment>
                                    <div className="w-full h-6" />
                                </Fragment>
                            }
                            {
                                !this.state.masterTemplate &&
                                !this.state.template &&
                                <div className="flex flex-1 mb-4 flex-col justify-center">
                                    <InputTailwind
                                        label={null}
                                        placeholder={"Search campaigns ..."}
                                        value={this.state.search_campaigns}
                                        onChange={(value) => {
                                            this.setState({
                                                search_campaigns: value
                                            });
                                        }}
                                    />
                                </div>
                            }
                            {
                                !this.state.masterTemplate &&
                                !this.state.template &&
                                this.state.accounts.map((account, index) => {
                                    return (
                                        <Fragment>
                                            {
                                                false &&
                                                <div className="text-xs mb-1">
                                                    {account.client_name ? account.client_name : ""}
                                                </div>
                                            }
                                            <div className="flex flex-1 flex-row justify-center mb-2">
                                                <div onClick={() => {
                                                    account.show_campaigns = !account.show_campaigns;
                                                    this.setState({
                                                        accounts: this.state.accounts
                                                    });
                                                }} className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    {
                                                        !account.show_campaigns &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={14}
                                                            icon="plus" />
                                                    }
                                                    {
                                                        account.show_campaigns &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={14}
                                                            icon="minus" />
                                                    }
                                                </div>
                                                {
                                                    this.renders.preview() &&
                                                    <div onClick={() => {
                                                        if (account.channel === "google" || account.channel === "bing" || account.channel === "facebook" || account.channel === "tiktok" || account.channel === "linkedin" || account.channel === "snapchat") {
                                                            if (!account.is_loading) {
                                                                if (this.state.selected_accounts_internal[account.id]) {
                                                                    delete this.state.selected_accounts_internal[account.id]
                                                                } else {

                                                                    this.state.selected_accounts_internal = {};
                                                                    this.state.selected_ads_internal = {};

                                                                    let colors = this.functions.color(account);
                                                                    account.hex = colors.hex;
                                                                    account.rgb = colors.rgb;
                                                                    this.state.selected_accounts_internal[account.id] = account;

                                                                    if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                        this.state.tab.breakdown = {
                                                                            title: "Ads",
                                                                            subtitle: "Ads",
                                                                            icon: "globe",
                                                                            value: "ads"
                                                                        };
                                                                    }

                                                                }
                                                                this.props.onTab(this.state.tab);
                                                                this.props.onSelectedAccounts(this.state.selected_accounts_internal);
                                                                this.props.onSelectedAds(this.state.selected_ads_internal);
                                                            }
                                                        }
                                                    }}
                                                        className={((this.state.selected_accounts_internal[account.id]) ? "border-purple-500" : "") + " h-10 w-10 cursor-pointer p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            this.state.selected_accounts_internal[account.id] &&
                                                            <div
                                                                className="w-full h-full rounded-full bg-purple-500" />
                                                        }
                                                    </div>
                                                }
                                                {
                                                    !this.renders.preview() &&
                                                    <div onClick={() => {
                                                        if (!account.is_loading) {
                                                            if (this.state.selected_accounts_internal[account.id]) {
                                                                delete this.state.selected_accounts_internal[account.id]
                                                            } else {
                                                                let colors = this.functions.color(account);
                                                                account.hex = colors.hex;
                                                                account.rgb = colors.rgb;
                                                                this.state.selected_accounts_internal[account.id] = account;
                                                            }
                                                            this.props.onSelectedAccounts(this.state.selected_accounts_internal);
                                                        }
                                                    }}
                                                        className={(this.state.selected_accounts_internal[account.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            this.state.selected_accounts_internal[account.id] &&
                                                            <FeatherIcon className={'stroke-current'}
                                                                size={14}
                                                                icon="check" />
                                                        }
                                                        {
                                                            account.is_loading &&
                                                            <div
                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                <BeatLoader
                                                                    sizeUnit={"px"}
                                                                    size={4}
                                                                    color={'#060534'}
                                                                    loading={true}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    [
                                                        { name: 'facebook', icon: 'facebook_icon.svg', style: { width: '0.6rem' } },
                                                        { name: 'google', icon: 'google_icon.svg', style: { width: '1.1rem' } },
                                                        { name: 'google_analytics', icon: 'google_analytics_icon.png', style: { width: '0.75rem' } },
                                                        { name: 'google_analytics_4', icon: 'ga_icon.png', style: { width: '0.75rem' } },
                                                        { name: 'google_shopping', icon: 'google_shopping_icon.png', style: { width: '0.75rem' } },
                                                        { name: 'adform', icon: 'adform_icon.png', style: { width: '0.75rem' } },
                                                        { name: 'linkedin', icon: 'linkedin_icon.svg', style: { width: '1rem' } },
                                                        { name: 'twitter', icon: 'twitter_icon.svg', style: { width: '0.75rem' } },
                                                        { name: 'bidtheatre', icon: 'bidtheatre_icon.png', style: { width: '0.75rem' } },
                                                        { name: 'snapchat', icon: 'snapchat.svg', style: { width: '0.75rem' } },
                                                        { name: 'bing', icon: 'bing-logo.png', style: { width: '0.75rem' } },
                                                        { name: 'dv360', icon: 'dv360-logo.svg', style: { width: '1rem' } }
                                                    ].filter((channel) => { return (account.channel == channel.name) }).map((item) => {
                                                        return (
                                                            <div
                                                                className={"bg-" + item.name.replace('_', '').replace('_', '') + "-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center"}>
                                                                <img className={item.style ? "" : "w-3"}
                                                                    style={item.style ? item.style : {}}
                                                                    src={require('../assets/images/' + item.icon)} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    account.custom &&
                                                    <div
                                                        style={{ backgroundColor: "#" + account.backgroundColor }}
                                                        className={"mr-2 h-10 w-10 rounded flex justify-center align-middle items-center"}>
                                                        {
                                                            account.logo &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + account.logo + ")" }}></div>
                                                        }
                                                        {
                                                            !account.logo &&
                                                            <div data-tip='' data-for={account.id} key={account.id + "_custom"} className="w-full h-full relative flex items-center justify-center text-semibold text-white text-sm uppercase">
                                                                {account.name[0]}{account.name[1]}
                                                                <ReactTooltip effect='solid' id={account.id}>
                                                                    {account.name[0]}{account.name[1]}
                                                                </ReactTooltip>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="flex flex-1 flex-row">
                                                    <div className="flex flex-col justify-center">
                                                        <div data-tip='' data-for={account.id + "_" + index} key={account.id} className="font-medium relative text-sm">
                                                            {account.name}
                                                            <ReactTooltip effect='solid' id={account.id + "_" + index}>
                                                                {account.name}
                                                            </ReactTooltip>
                                                        </div>
                                                        <div className="text-xxs flex flex-row">
                                                            Account &#x2022; #{account.id} &#x2022; <span className="ml-1 font-medium text-purple-500">{account.client_name ? account.client_name : ""}</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.selected_accounts_internal[account.id] &&
                                                        <div onClick={() => {
                                                            if (!this.state.tab.settings) {
                                                                this.state.tab.settings = { title: "" };
                                                                this.state.tab.settings.source_name = {};
                                                            } else if (!this.state.tab.settings.source_name) {
                                                                this.state.tab.settings.source_name = {};
                                                            }
                                                            if (account.id in this.state.tab.settings.source_name) {
                                                                delete this.state.tab.settings.source_name[account.id];
                                                            } else {
                                                                this.state.tab.settings.source_name[account.id] = "";
                                                            }
                                                            if (this.props.onTab) {
                                                                this.props.onTab(this.state.tab);
                                                            }
                                                        }} className="flex flex-1 justify-end">
                                                            <PencilAltIcon className="w-5 text-gray-500 cursor-pointer hover:text-gray-700" />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.state.tab.settings &&
                                                this.state.tab.settings.source_name &&
                                                (account.id in this.state.tab.settings.source_name) &&
                                                this.state.selected_accounts_internal[account.id] &&
                                                <div className="flex flex-1 w-full -mt-1 pl-12 mb-2">
                                                    <div className="flex flex-1">
                                                        <div className="w-full">
                                                            <InputTailwind
                                                                label={"Custom name"}
                                                                value={this.state.tab.settings.source_name[account.id]}
                                                                onChange={(value) => {
                                                                    this.state.tab.settings.source_name[account.id] = value;
                                                                    if (this.props.onTab) {
                                                                        this.props.onTab(this.state.tab);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !this.state.masterTemplate &&
                                                !this.state.template &&
                                                account.show_campaigns &&
                                                //this.state.search_campaigns == "" &&
                                                this.state.campaigns.filter((item) => {
                                                    return item.client_id == account.client && item.channel == account.channel;
                                                }).filter((campaign) => {
                                                    if (account.channel == "google_analytics_4" || account.channel == "google_analytics") {
                                                        return true;
                                                    } else if (account.show_paused) {
                                                        return true;
                                                    } else {
                                                        return typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled");
                                                    }
                                                }).length < 1 &&
                                                <div className="text-orange-500 pl-12 rounded-md mb-2 p-4 text-sm font-medium">
                                                    No active campaigns available <span onClick={() => {
                                                        account.show_paused = !account.show_paused;
                                                        this.setState({
                                                            accounts: this.state.accounts
                                                        });
                                                    }} className="underline cursor-pointer">Click here</span> to show all non active campaigns.
                                                </div>
                                            }
                                            {
                                                !this.state.masterTemplate &&
                                                !this.state.template &&
                                                (account.show_campaigns) &&
                                                this.state.campaigns.filter((item) => {
                                                    return item.client_id == account.client && item.channel == account.channel;
                                                }).filter((item) => {
                                                    if (typeof this.state.search_campaigns == "string" && this.state.search_campaigns !== "") {
                                                        return item.name.toLowerCase().indexOf(this.state.search_campaigns.toLowerCase()) > -1;
                                                    } else {
                                                        return true;
                                                    }
                                                }).sort((a, b) => {
                                                    let a_active = typeof a.status == "string" && (a.status.toLowerCase() === "active" || a.status.toLowerCase() === "enabled");
                                                    let b_active = typeof b.status == "string" && (b.status.toLowerCase() === "active" || b.status.toLowerCase() === "enabled");
                                                    return (a_active === b_active) ? 0 : a_active ? -1 : 1;
                                                }).filter((campaign) => {
                                                    if (account.channel == "google_analytics_4" || account.channel == "google_analytics") {
                                                        return true;
                                                    } else if (account.show_paused) {
                                                        return true;
                                                    } else {
                                                        return typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled");
                                                    }
                                                }).map((campaign, index) => {
                                                    return (
                                                        <Fragment>
                                                            <div
                                                                className="flex flex-1 flex-row justify-center mb-2 pl-12">
                                                                <div onClick={() => {
                                                                    //GA ONLY HAVE CAMPAIGN LEVEL
                                                                    if (campaign.channel !== "google_analytics" && campaign.channel !== "google_analytics_4") {
                                                                        if (!campaign.open) {
                                                                            if (this.props.getAdgroups) {
                                                                                this.props.getAdgroups(campaign);
                                                                                campaign.open = true;
                                                                            }
                                                                        } else {
                                                                            campaign.open = false;
                                                                        }
                                                                        this.setState({
                                                                            campaigns: this.state.campaigns
                                                                        }, () => {
                                                                            if (this.props.onCampaigns) {
                                                                                this.props.onCampaigns(this.state.campaigns);
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                    className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                    {
                                                                        campaign.channel !== "google_analytics" &&
                                                                        campaign.channel !== "google_analytics_4" &&
                                                                        !campaign.open && !campaign.loading &&
                                                                        <FeatherIcon className={'stroke-current'}
                                                                            size={16}
                                                                            icon="plus" />
                                                                    }
                                                                    {
                                                                        campaign.channel !== "google_analytics" &&
                                                                        campaign.channel !== "google_analytics_4" &&
                                                                        campaign.open && !campaign.loading &&
                                                                        <FeatherIcon className={'stroke-current'}
                                                                            size={16}
                                                                            icon="minus" />
                                                                    }
                                                                    {
                                                                        campaign.channel !== "google_analytics" &&
                                                                        campaign.channel !== "google_analytics_4" &&
                                                                        campaign.loading &&
                                                                        <BeatLoader
                                                                            sizeUnit={"px"}
                                                                            size={5}
                                                                            color={'#060534'}
                                                                            loading={true}
                                                                        />
                                                                    }
                                                                </div>
                                                                {
                                                                    !this.renders.preview() &&
                                                                    <div onClick={() => {

                                                                        if (!campaign.is_loading) {
                                                                            if (this.state.selected_campaigns_internal[campaign.id]) {
                                                                                delete this.state.selected_campaigns_internal[campaign.id]
                                                                            } else {
                                                                                let colors = this.functions.color(campaign);
                                                                                campaign.hex = colors.hex;
                                                                                campaign.rgb = colors.rgb;
                                                                                this.state.selected_campaigns_internal[campaign.id] = campaign;
                                                                            }
                                                                            this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);
                                                                        }

                                                                    }}
                                                                        className={(this.state.selected_campaigns_internal[campaign.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                        {
                                                                            this.state.selected_campaigns_internal[campaign.id] &&
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon="check" />
                                                                        }
                                                                        {
                                                                            campaign.is_loading &&
                                                                            <div
                                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                <BeatLoader
                                                                                    sizeUnit={"px"}
                                                                                    size={4}
                                                                                    color={'#060534'}
                                                                                    loading={true}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                <div className="flex flex-1 truncate">
                                                                    <div className="flex flex-col truncate justify-center">
                                                                        <div data-tip='' data-for={campaign.id + "_" + index} key={campaign.id} className="font-medium truncate relative text-sm">
                                                                            {campaign.name}
                                                                            <ReactTooltip effect='solid' id={campaign.id + "_" + index}>
                                                                                {campaign.name}
                                                                            </ReactTooltip>
                                                                        </div>
                                                                        <div className="text-xxs flex flex-row">
                                                                            Campaign &#x2022; #{campaign.id} &#x2022;
                                                                            {
                                                                                typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled") &&
                                                                                <div className="text-green-500 ml-1 font-medium text-xxs">{campaign.status.toUpperCase()}</div>
                                                                            }
                                                                            {
                                                                                typeof campaign.status == "string" && (campaign.status.toLowerCase() === "paused") &&
                                                                                <div className="text-orange-500 ml-1 font-medium text-xxs">{campaign.status.toUpperCase()}</div>
                                                                            }
                                                                            {
                                                                                typeof campaign.status == "string" && (campaign.status.toLowerCase() === "ended" || campaign.status.toLowerCase() === "removed") &&
                                                                                <div className="text-red-500 ml-1 font-medium text-xxs">{campaign.status.toUpperCase()}</div>
                                                                            }
                                                                            {
                                                                                typeof campaign.status == "string" && (campaign.status.toLowerCase() !== "active" && campaign.status.toLowerCase() !== "enabled" && campaign.status.toLowerCase() !== "ended" && campaign.status.toLowerCase() !== "removed" && campaign.status.toLowerCase() !== "paused") &&
                                                                                <div className="text-gray-500 ml-1 font-medium text-xxs">{campaign.status.toUpperCase()}</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.selected_campaigns_internal[campaign.id] &&
                                                                        <div onClick={() => {
                                                                            if (!this.state.tab.settings) {
                                                                                this.state.tab.settings = { title: "" };
                                                                                this.state.tab.settings.source_name = {};
                                                                            } else if (!this.state.tab.settings.source_name) {
                                                                                this.state.tab.settings.source_name = {};
                                                                            }
                                                                            if (campaign.id in this.state.tab.settings.source_name) {
                                                                                delete this.state.tab.settings.source_name[campaign.id];
                                                                            } else {
                                                                                this.state.tab.settings.source_name[campaign.id] = "";
                                                                            }
                                                                            if (this.props.onTab) {
                                                                                this.props.onTab(this.state.tab);
                                                                            }
                                                                        }} className="flex flex-1 pl-2 justify-end">
                                                                            <PencilAltIcon className="w-5 text-gray-500 cursor-pointer hover:text-gray-700" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.tab.settings &&
                                                                this.state.tab.settings.source_name &&
                                                                (campaign.id in this.state.tab.settings.source_name) &&
                                                                this.state.selected_campaigns_internal[campaign.id] &&
                                                                <div className="flex flex-1 w-full -mt-1 pl-12 mb-2">
                                                                    <div className="flex flex-1">
                                                                        <div className="w-full">
                                                                            <InputTailwind
                                                                                label={"Custom name"}
                                                                                value={this.state.tab.settings.source_name[campaign.id]}
                                                                                onChange={(value) => {
                                                                                    this.state.tab.settings.source_name[campaign.id] = value;
                                                                                    console.log(this.state.tab.settings.source_name);
                                                                                    if (this.props.onTab) {
                                                                                        this.props.onTab(this.state.tab);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.adgroups.filter((adgroup) => {
                                                                    return adgroup.campaign == campaign.id && campaign.open
                                                                }).sort((a, b) => {
                                                                    let a_active = typeof a.status == "string" && (a.status.toLowerCase() === "active" || a.status.toLowerCase() === "enabled");
                                                                    let b_active = typeof b.status == "string" && (b.status.toLowerCase() === "active" || b.status.toLowerCase() === "enabled");
                                                                    return (a_active === b_active) ? 0 : a_active ? -1 : 1;
                                                                }).map((adgroup, index) => {
                                                                    return (
                                                                        <Fragment>
                                                                            <div
                                                                                className="flex flex-1 flex-row justify-left pl-24 mb-2">
                                                                                <div onClick={() => {
                                                                                    if (!adgroup.open) {
                                                                                        this.props.getAds(adgroup);
                                                                                        adgroup.open = true;
                                                                                    } else {
                                                                                        adgroup.open = false;
                                                                                    }
                                                                                    this.props.onAdgroups(this.state.adgroups);
                                                                                }}
                                                                                    className="cursor-pointer min-w-10 min-h-10 h-10 w-10 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500">
                                                                                    {
                                                                                        !adgroup.open && !adgroup.loading &&
                                                                                        <FeatherIcon
                                                                                            className={'stroke-current'}
                                                                                            size={16} icon="plus" />
                                                                                    }
                                                                                    {
                                                                                        adgroup.open && !adgroup.loading &&
                                                                                        <FeatherIcon
                                                                                            className={'stroke-current'}
                                                                                            size={16} icon="minus" />
                                                                                    }
                                                                                    {
                                                                                        adgroup.loading &&
                                                                                        <BeatLoader
                                                                                            sizeUnit={"px"}
                                                                                            size={5}
                                                                                            color={'#060534'}
                                                                                            loading={true}
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    !this.renders.preview() &&
                                                                                    <div onClick={() => {
                                                                                        if (!adgroup.is_loading) {
                                                                                            if (this.state.selected_adgroups_internal[adgroup.id]) {
                                                                                                delete this.state.selected_adgroups_internal[adgroup.id];
                                                                                            } else {

                                                                                                let colors = this.functions.color(adgroup);
                                                                                                adgroup.hex = colors.hex;
                                                                                                adgroup.rgb = colors.rgb;
                                                                                                this.state.selected_adgroups_internal[adgroup.id] = adgroup;

                                                                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                    this.state.tab.breakdown = {
                                                                                                        title: "Overview",
                                                                                                        subtitle: "Aggregated data",
                                                                                                        icon: "globe",
                                                                                                        value: "overview"
                                                                                                    };
                                                                                                }

                                                                                            }
                                                                                            this.props.onTab(this.state.tab);
                                                                                            this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);
                                                                                        }
                                                                                    }}
                                                                                        className={(this.state.selected_adgroups_internal[adgroup.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 min-w-10 min-h-10 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                        {
                                                                                            this.state.selected_adgroups_internal[adgroup.id] &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={16}
                                                                                                icon="check" />
                                                                                        }
                                                                                        {
                                                                                            adgroup.is_loading &&
                                                                                            <div
                                                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                <BeatLoader
                                                                                                    sizeUnit={"px"}
                                                                                                    size={5}
                                                                                                    color={'#060534'}
                                                                                                    loading={true}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                <div className="flex flex-col truncate justify-center">
                                                                                    <div data-tip='' data-for={adgroup.id + "_" + index} key={adgroup.id} className="font-medium truncate relative text-sm">
                                                                                        {adgroup.name}
                                                                                        <ReactTooltip effect='solid' id={adgroup.id + "_" + index}>
                                                                                            {adgroup.name}
                                                                                        </ReactTooltip>
                                                                                    </div>
                                                                                    <div className="text-xxs flex flex-row">
                                                                                        Adgroup &#x2022; #{adgroup.id} &#x2022;
                                                                                        {
                                                                                            typeof adgroup.status == "string" && (adgroup.status.toLowerCase() === "active" || adgroup.status.toLowerCase() === "enabled") &&
                                                                                            <div className="text-green-500 ml-1 font-medium text-xxs">{adgroup.status.toUpperCase()}</div>
                                                                                        }
                                                                                        {
                                                                                            typeof adgroup.status == "string" && (adgroup.status.toLowerCase() === "paused") &&
                                                                                            <div className="text-orange-500 ml-1 font-medium text-xxs">{adgroup.status.toUpperCase()}</div>
                                                                                        }
                                                                                        {
                                                                                            typeof adgroup.status == "string" && (adgroup.status.toLowerCase() === "ended" || adgroup.status.toLowerCase() === "removed") &&
                                                                                            <div className="text-red-500 ml-1 font-medium text-xxs">{adgroup.status.toUpperCase()}</div>
                                                                                        }
                                                                                        {
                                                                                            typeof adgroup.status == "string" && (adgroup.status.toLowerCase() !== "active" && adgroup.status.toLowerCase() !== "enabled" && adgroup.status.toLowerCase() !== "ended" && adgroup.status.toLowerCase() !== "removed" && adgroup.status.toLowerCase() !== "paused") &&
                                                                                            <div className="text-gray-500 ml-1 font-medium text-xxs">{adgroup.status.toUpperCase()}</div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.selected_adgroups_internal[adgroup.id] &&
                                                                                    <div onClick={() => {
                                                                                        if (!this.state.tab.settings) {
                                                                                            this.state.tab.settings = { title: "" };
                                                                                            this.state.tab.settings.source_name = {};
                                                                                        } else if (!this.state.tab.settings.source_name) {
                                                                                            this.state.tab.settings.source_name = {};
                                                                                        }
                                                                                        if (adgroup.id in this.state.tab.settings.source_name) {
                                                                                            delete this.state.tab.settings.source_name[adgroup.id];
                                                                                        } else {
                                                                                            this.state.tab.settings.source_name[adgroup.id] = "";
                                                                                        }
                                                                                        if (this.props.onTab) {
                                                                                            this.props.onTab(this.state.tab);
                                                                                        }
                                                                                    }} className="flex flex-1 pl-2 justify-end">
                                                                                        <PencilAltIcon className="w-5 text-gray-500 cursor-pointer hover:text-gray-700" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                this.state.tab.settings &&
                                                                                this.state.tab.settings.source_name &&
                                                                                (adgroup.id in this.state.tab.settings.source_name) &&
                                                                                this.state.selected_adgroups_internal[adgroup.id] &&
                                                                                <div className="flex flex-1 w-full -mt-1 pl-24 mb-2">
                                                                                    <div className="flex flex-1">
                                                                                        <div className="w-full">
                                                                                            <InputTailwind
                                                                                                label={"Custom name"}
                                                                                                value={this.state.tab.settings.source_name[adgroup.id]}
                                                                                                onChange={(value) => {
                                                                                                    this.state.tab.settings.source_name[adgroup.id] = value;
                                                                                                    //console.log(this.state.tab.settings.source_name);
                                                                                                    if (this.props.onTab) {
                                                                                                        this.props.onTab(this.state.tab);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.ads.filter((ad) => {
                                                                                    return ad.adgroup == adgroup.id && adgroup.open
                                                                                }).sort((a, b) => {
                                                                                    let a_active = typeof a.status == "string" && (a.status.toLowerCase() === "active" || a.status.toLowerCase() === "enabled");
                                                                                    let b_active = typeof b.status == "string" && (b.status.toLowerCase() === "active" || b.status.toLowerCase() === "enabled");
                                                                                    return (a_active === b_active) ? 0 : a_active ? -1 : 1;
                                                                                }).map((ad, index) => {
                                                                                    return (
                                                                                        <Fragment>
                                                                                            <div
                                                                                                className="flex flex-1 flex-row justify-left ml-12 pl-24 mb-2">
                                                                                                <div className="h-10 w-10 flex rounded mr-2 bg-purple-100">

                                                                                                </div>
                                                                                                {
                                                                                                    !this.renders.preview() &&
                                                                                                    <div onClick={() => {
                                                                                                        if (!ad.is_loading) {
                                                                                                            if (this.state.selected_ads_internal[ad.id]) {
                                                                                                                delete this.state.selected_ads_internal[ad.id];
                                                                                                            } else {

                                                                                                                let colors = this.functions.color(ad);
                                                                                                                ad.hex = colors.hex;
                                                                                                                ad.rgb = colors.rgb;
                                                                                                                this.state.selected_ads_internal[ad.id] = ad;

                                                                                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                                    this.state.tab.breakdown = {
                                                                                                                        title: "Overview",
                                                                                                                        subtitle: "Aggregated data",
                                                                                                                        icon: "globe",
                                                                                                                        value: "overview"
                                                                                                                    };
                                                                                                                }

                                                                                                            }
                                                                                                            this.props.onTab(this.state.tab);
                                                                                                            this.props.onSelectedAds(this.state.selected_ads_internal);
                                                                                                        }
                                                                                                    }}
                                                                                                        className={(this.state.selected_ads_internal[ad.id] ? "border-purple-500 bg-purple-100" : "") + " min-h-10 min-w-10 cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                        {
                                                                                                            this.state.selected_ads_internal[ad.id] &&
                                                                                                            <FeatherIcon
                                                                                                                className={'stroke-current'}
                                                                                                                size={16}
                                                                                                                icon="check" />
                                                                                                        }
                                                                                                        {
                                                                                                            ad.is_loading &&
                                                                                                            <div
                                                                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                                <BeatLoader
                                                                                                                    sizeUnit={"px"}
                                                                                                                    size={5}
                                                                                                                    color={'#060534'}
                                                                                                                    loading={true}
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    this.renders.preview() &&
                                                                                                    <div onClick={() => {
                                                                                                        if (!ad.is_loading) {
                                                                                                            if (ad.id in this.state.selected_ads_internal) {
                                                                                                                delete this.state.selected_ads_internal[ad.id];
                                                                                                            } else {
                                                                                                                for (let key in this.state.selected_ads_internal) {
                                                                                                                    delete this.state.selected_ads_internal[key];
                                                                                                                }
                                                                                                                let colors = this.functions.color(ad);
                                                                                                                ad.hex = colors.hex;
                                                                                                                ad.rgb = colors.rgb;
                                                                                                                this.state.selected_ads_internal[ad.id] = ad;

                                                                                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                                    this.state.tab.breakdown = {
                                                                                                                        title: "Overview",
                                                                                                                        subtitle: "Aggregated data",
                                                                                                                        icon: "globe",
                                                                                                                        value: "overview"
                                                                                                                    };
                                                                                                                }
                                                                                                            }
                                                                                                            this.props.onTab(this.state.tab);
                                                                                                            this.props.onSelectedAds(this.state.selected_ads_internal);
                                                                                                        }
                                                                                                    }}
                                                                                                        className={((this.state.selected_ads_internal[ad.id]) ? "border-purple-500" : "") + " min-h-10 min-w-10 h-10 w-10 cursor-pointer p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                                        {
                                                                                                            this.state.selected_ads_internal[ad.id] &&
                                                                                                            <div
                                                                                                                className="w-full h-full rounded-full bg-purple-500" />
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                                <div class="flex truncate flex-1">
                                                                                                    <div className="flex flex-col truncate justify-center">
                                                                                                        <div data-tip='' data-for={ad.id + "_" + index} key={ad.id} className="font-medium truncate relative text-sm">
                                                                                                            {ad.name}
                                                                                                            {
                                                                                                                !ad.name && ad.advertising_type === "RESPONSIVE_DISPLAY_AD" &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        Array.isArray(ad.headlines) && ad.headlines.length > 0 &&
                                                                                                                        <>
                                                                                                                            {ad.headlines[0]}
                                                                                                                        </>
                                                                                                                    }
                                                                                                                </>
                                                                                                            }
                                                                                                            <ReactTooltip effect='solid' id={ad.id + "_" + index}>
                                                                                                                <span>
                                                                                                                    {ad.name}
                                                                                                                    {
                                                                                                                        !ad.name && ad.advertising_type === "RESPONSIVE_DISPLAY_AD" &&
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                Array.isArray(ad.headlines) && ad.headlines.length > 0 &&
                                                                                                                                <>
                                                                                                                                    {ad.headlines[0]}
                                                                                                                                </>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </ReactTooltip>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="text-xxs flex flex-row">
                                                                                                            Ad &#x2022;
                                                                                                            {"#" + ad.id}
                                                                                                            &#x2022;
                                                                                                            {
                                                                                                                typeof ad.status == "string" && (ad.status.toLowerCase() === "active" || ad.status.toLowerCase() === "enabled") &&
                                                                                                                <div className="text-green-500 ml-1 font-medium text-xxs">{ad.status.toUpperCase()}</div>
                                                                                                            }
                                                                                                            {
                                                                                                                typeof ad.status == "string" && (ad.status.toLowerCase() === "paused") &&
                                                                                                                <div className="text-orange-500 ml-1 font-medium text-xxs">{ad.status.toUpperCase()}</div>
                                                                                                            }
                                                                                                            {
                                                                                                                typeof ad.status == "string" && (ad.status.toLowerCase() === "ended" || ad.status.toLowerCase() === "removed") &&
                                                                                                                <div className="text-red-500 ml-1 font-medium text-xxs">{ad.status.toUpperCase()}</div>
                                                                                                            }
                                                                                                            {
                                                                                                                typeof ad.status == "string" && (ad.status.toLowerCase() !== "active" && ad.status.toLowerCase() !== "enabled" && ad.status.toLowerCase() !== "ended" && ad.status.toLowerCase() !== "removed" && ad.status.toLowerCase() !== "paused") &&
                                                                                                                <div className="text-gray-500 ml-1 font-medium text-xxs">{ad.status.toUpperCase()}</div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        this.state.selected_ads_internal[ad.id] &&
                                                                                                        <div onClick={() => {
                                                                                                            if (!this.state.tab.settings) {
                                                                                                                this.state.tab.settings = { title: "" };
                                                                                                                this.state.tab.settings.source_name = {};
                                                                                                            } else if (!this.state.tab.settings.source_name) {
                                                                                                                this.state.tab.settings.source_name = {};
                                                                                                            }
                                                                                                            if (ad.id in this.state.tab.settings.source_name) {
                                                                                                                delete this.state.tab.settings.source_name[ad.id];
                                                                                                            } else {
                                                                                                                this.state.tab.settings.source_name[ad.id] = "";
                                                                                                            }
                                                                                                            if (this.props.onTab) {
                                                                                                                this.props.onTab(this.state.tab);
                                                                                                            }
                                                                                                        }} className="flex flex-1 pl-2 justify-end">
                                                                                                            <PencilAltIcon className="w-5 text-gray-500 cursor-pointer hover:text-gray-700" />
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                this.state.tab.settings &&
                                                                                                this.state.tab.settings.source_name &&
                                                                                                (ad.id in this.state.tab.settings.source_name) &&
                                                                                                this.state.selected_ads_internal[ad.id] &&
                                                                                                <div className="flex flex-1 w-full -mt-1 pl-24 mb-2">
                                                                                                    <div className="flex flex-1 pl-12">
                                                                                                        <div className="w-full">
                                                                                                            <InputTailwind
                                                                                                                label={"Custom name"}
                                                                                                                value={this.state.tab.settings.source_name[ad.id]}
                                                                                                                onChange={(value) => {
                                                                                                                    this.state.tab.settings.source_name[ad.id] = value;
                                                                                                                    //console.log(this.state.tab.settings.source_name);
                                                                                                                    if (this.props.onTab) {
                                                                                                                        this.props.onTab(this.state.tab);
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                (this.state.campaigns.length - 1) !== index &&
                                                                <div className="border-t flex-1 flex mb-2" />
                                                            }
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                            {
                                                account.show_campaigns &&
                                                <div className="mb-4 mt-2 pl-12">
                                                    {
                                                        this.state.campaigns.length > 0 &&
                                                        account.channel !== "google_analytics_4" && account.channel !== "google_analytics" &&
                                                        //this.state.search_campaigns == "" &&
                                                        <span onClick={() => {
                                                            account.show_paused = !account.show_paused;
                                                            this.setState({
                                                                accounts: this.state.accounts
                                                            });
                                                        }} className="text-sm underline font-medium cursor-pointer">
                                                            {!account.show_paused && <span>Show all campaigns</span>}{account.show_paused && <span>Show less campaigns</span>}
                                                        </span>
                                                    }
                                                </div>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                            {
                                this.functions.multipleLevelsSelected() &&
                                <div className="flex flex-1 bg-yellow-100 rounded-md p-4 mt-4">
                                    <div className="text-xs text-yellow-500 font-bold">
                                        OBS! You have selected multiple levels of datasources
                                    </div>
                                </div>
                            }
                        </SlideDown>
                    }
                </div>
            </>
        )
    }

}

class SelectCellDaterangeOld extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            dateranges: [],
            selected_cells: [],
            preview: false,
            creative: false,
            chart: false,
            input: false
        };
    };

    componentDidMount() {
        let dateranges = [
            /*
            {
                title: "Lifetime",
                subtitle: "From start date until today",
                icon: "calendar",
                value: "lifetime",
                disabled: true
            },
            */
            {
                title: "Last 10 days",
                subtitle: ("From " + moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                icon: "calendar",
                value: "10_days",
                disabled: false
            },
            {
                title: "Last 14 days",
                subtitle: ("From " + moment(new Date()).subtract(14, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                icon: "calendar",
                value: "14_days",
                disabled: false
            },
            {
                title: "last 20 days",
                subtitle: ("From " + moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                icon: "calendar",
                value: "20_days",
                disabled: false
            },
            {
                title: "Last 30 days",
                subtitle: ("From " + moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                icon: "calendar",
                value: "30_days",
                disabled: false
            },
            {
                title: "Custom dates",
                subtitle: "Add custom start date and end date",
                icon: "calendar",
                value: "custom_dates",
                disabled: false
            }
        ];
        this.setState({
            dateranges: dateranges,
            tab: this.props.tab ? this.props.tab : {},
            preview: this.props.preview,
            creative: this.props.creative,
            chart: this.props.chart,
            input: this.props.input,
            selected_cells: this.props.selectedCells ? this.props.selectedCells : [],
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab ? nextProps.tab : {},
            preview: nextProps.preview,
            creative: nextProps.creative,
            chart: nextProps.chart,
            input: nextProps.input,
            selected_cells: nextProps.selectedCells ? nextProps.selectedCells : [],
        })
    }

    functions = {
        dailySelected: () => {
            return false;
        },
        compareFormValidation: () => {
            let valid = true;
            try {
                this.state.selected_cells.map((cell) => {
                    let amount_of_datasources = 0;
                    amount_of_datasources += Object.keys(cell.data_sources.accounts).length;
                    amount_of_datasources += Object.keys(cell.data_sources.campaigns).length;
                    amount_of_datasources += Object.keys(cell.data_sources.adgroups).length;
                    amount_of_datasources += Object.keys(cell.data_sources.ads).length;
                    if ((amount_of_datasources > 1 && cell.celltype && cell.celltype.value === "chart") || (cell.metrics && Object.keys(cell.metrics).length > 1 && cell.celltype && cell.celltype.value === "chart")) {
                        valid = false;
                    } else if (cell.celltype && cell.celltype.value === "chart" && cell.chart && cell.chart.value === "donut") {
                        valid = false;
                    }
                });
            } catch (error) { }
            return valid;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    {
                        !this.state.preview &&
                        !this.state.creative &&
                        !this.state.input &&
                        <div className='flex flex-col w-full mb-4'>
                            <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Edit dates</div>
                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                <div className={`flex flex-1 text-sm ml-5`}>
                                    {this.state.enable_daterange ? "Enabled" : "Disabled"}
                                </div>
                                <div className="relative overflow-hidden mr-5">
                                    <SwitchTailwind
                                        value={this.state.enable_daterange ? true : false}
                                        onSwitch={async () => {
                                            await this.promisedSetState({ enable_daterange: !this.state.enable_daterange });
                                            if (!this.state.enable_daterange) {
                                                this.state.tab.daterange = {};
                                                await this.promisedSetState({
                                                    tab: this.state.tab
                                                });
                                                this.props.onTab(this.state.tab);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        !this.state.preview &&
                        !this.state.creative &&
                        !this.state.input &&
                        this.state.enable_daterange &&
                        <div className="pt-1">
                            <div className="">
                                {
                                    this.state.dateranges.map((item, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <div
                                                    className={(item.disabled ? "opacity-50 cursor-not-allowed" : "") + " mb-2 flex flex-1 flex-row justify-center"}>
                                                    <div onClick={() => {

                                                    }}
                                                        className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={14} icon={item.icon} />
                                                    </div>
                                                    <div onClick={() => {
                                                        if (!item.disabled) {
                                                            this.state.tab.daterange = item;
                                                            this.props.onTab(this.state.tab);
                                                        }
                                                    }}
                                                        className={(item.disabled ? "cursor-not-allowed" : "cursor-pointer") + ((this.state.tab.daterange && this.state.tab.daterange.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            this.state.tab.daterange && this.state.tab.daterange.value === item.value &&
                                                            <div
                                                                className="w-full h-full rounded-full bg-purple-500" />
                                                        }
                                                    </div>
                                                    <div className="flex flex-1 flex-col truncate">
                                                        <div className="font-bold text-xs truncate">
                                                            {item.title}
                                                        </div>
                                                        {
                                                            (item.value === "custom_dates" && this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) ?
                                                                (<div className="text-xxs">
                                                                    From {this.state.tab.daterange.start_date} to {this.state.tab.daterange.end_date}
                                                                </div>) :
                                                                (<div className="text-xxs">
                                                                    {item.subtitle}
                                                                </div>)
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" &&
                                <div className="w-full">
                                    <SingleDatepicker
                                        maxDate={new Date()}
                                        from={this.state.tab.daterange.start_date}
                                        to={this.state.tab.daterange.end_date}
                                        onChange={async (data) => {
                                            if (!this.state.tab.daterange) {
                                                this.state.tab.daterange = {};
                                            }
                                            this.state.tab.daterange.start_date = data.start_date;
                                            this.state.tab.daterange.end_date = data.end_date;
                                            await this.promisedSetState({
                                                tab: this.state.tab
                                            });
                                            this.props.onTab(this.state.tab);
                                        }}
                                    />
                                </div>
                            }
                            {
                                <div className={(this.functions.compareFormValidation() && this.state.tab.daterange && this.state.tab.daterange.value ? "" : "opacity-50 ") + " font-bold text-xs truncate mb-2 mt-5"}>
                                    Comparison settings
                                </div>
                            }
                            {
                                <div className="mb-2">
                                    <div className={(this.functions.compareFormValidation() && this.state.tab.daterange && this.state.tab.daterange.value ? "" : "opacity-50 cursor-not-allowed") + " flex flex-row"}>
                                        <div
                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                            <FeatherIcon className={'stroke-current'}
                                                size={14} icon={"trending-up"} />
                                        </div>
                                        <div onClick={async () => {
                                            if (this.functions.compareFormValidation() && this.state.tab.daterange && this.state.tab.daterange.value) {
                                                if (!this.state.tab.daterange) {
                                                    this.state.tab.daterange = {};
                                                    this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" };
                                                } else if (!this.state.tab.daterange.compare_dates) {
                                                    this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" }
                                                } else {
                                                    delete this.state.tab.daterange.compare_dates;
                                                }
                                                await this.promisedSetState({
                                                    tab: this.state.tab
                                                });
                                                this.props.onTab(this.state.tab);
                                            }
                                        }}
                                            className={(this.functions.compareFormValidation() ? "cursor-pointer " : "cursor-not-allowed ") + (this.functions.compareFormValidation() && this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                            {
                                                this.functions.compareFormValidation() &&
                                                this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                                                <FeatherIcon
                                                    className={'stroke-current'}
                                                    size={14}
                                                    icon="check" />
                                            }
                                        </div>
                                        <div className="flex flex-1 flex-col truncate">
                                            <div className="font-bold text-xs truncate">
                                                Compare with preceding period
                                            </div>
                                            <div className="text-xxs">
                                                Evaluate selected time period with previous period
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                false &&
                                !this.functions.compareFormValidation() &&
                                <div className="col-span-6 p-3 rounded-md font-medium text-xs text-orange-500 bg-orange-100 mb-2">
                                    OBS! Selection is not supported for comparing with preceding period
                                </div>
                            }
                        </div>
                    }
                </div>
            </>
        )
    }
}

class SelectCellSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            channels: [],
            accounts: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            preview: false,
            creative: false,
            input: false,
            selectedId: "",
            background_color: "ffffff",
            font_color: "000000"
        };
    };

    componentDidMount() {
        this.setState({
            tab: this.props.tab,
            open: this.props.open,
            master_template: this.props.masterTemplate,
            template: this.props.template,
            channels: this.props.channels ? this.props.channels : [],
            accounts: this.props.accounts ? this.props.accounts : [],
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            background_color: this.props.background_color ? JSON.parse(JSON.stringify(this.props.background_color)) : "ffffff",
            font_color: this.props.font_color ? JSON.parse(JSON.stringify(this.props.font_color)) : "000000",
            transparent: this.props.transparent ? JSON.parse(JSON.stringify(this.props.transparent)) : false,
            shadow: this.props.shadow ? JSON.parse(JSON.stringify(this.props.shadow)) : false,
            enable_styling: this.props.enable_styling ? JSON.parse(JSON.stringify(this.props.enable_styling)) : false,
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            preview: this.props.preview,
            creative: this.props.creative,
            input: this.props.input,
            hide_settings: this.props.hideSettings,
        }, () => {
            this.state.channels = this.state.channels.map((item) => {
                item.selected = false;
                item.open_2 = false;
                return item;
            });
            this.state.accounts = this.state.accounts.map((item) => {
                item.selected = false;
                item.open_2 = false;
                return item;
            });
            this.state.campaigns = this.state.campaigns.map((item) => {
                item.selected = false;
                item.open_2 = false;
                return item;
            });
            this.state.adgroups = this.state.adgroups.map((item) => {
                item.selected = false;
                item.open_2 = false;
                return item;
            });
            this.state.ads = this.state.ads.map((item) => {
                item.selected = false;
                item.open_2 = false;
                return item;
            });
            this.setState({
                channels: this.state.channels,
                accounts: this.state.accounts,
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups,
                ads: this.state.ads
            })
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let channels = [];
        let accounts = [];
        let campaigns = [];
        let adgroups = [];
        let ads = [];
        if (Array.isArray(nextProps.channels)) {
            channels = JSON.parse(JSON.stringify(nextProps.channels));
            channels = channels.map((item) => {
                this.state.channels.map((inner_item) => {
                    if (inner_item.id == item.id && inner_item.channel == item.channel) {
                        item.open_2 = inner_item.open_2;
                    }
                });
                return item;
            });
        }
        if (Array.isArray(nextProps.accounts)) {
            accounts = JSON.parse(JSON.stringify(nextProps.accounts));
            accounts = accounts.map((item) => {
                this.state.accounts.map((inner_item) => {
                    if (inner_item.id == item.id && inner_item.channel == item.channel) {
                        item.open_2 = inner_item.open_2;
                    }
                });
                return item;
            });
        }
        if (Array.isArray(nextProps.campaigns)) {
            campaigns = JSON.parse(JSON.stringify(nextProps.campaigns));
            campaigns = campaigns.map((item) => {
                this.state.campaigns.map((inner_item) => {
                    if (inner_item.id == item.id && inner_item.channel == item.channel) {
                        item.open_2 = inner_item.open_2;
                    }
                });
                return item;
            });
        }
        if (Array.isArray(nextProps.adgroups)) {
            adgroups = JSON.parse(JSON.stringify(nextProps.adgroups));
            adgroups = adgroups.map((item) => {
                this.state.adgroups.map((inner_item) => {
                    if (inner_item.id == item.id && inner_item.channel == item.channel) {
                        item.open_2 = inner_item.open_2;
                    }
                });
                return item;
            });
        }
        if (Array.isArray(nextProps.ads)) {
            ads = JSON.parse(JSON.stringify(nextProps.ads));
            ads = ads.map((item) => {
                this.state.ads.map((inner_item) => {
                    if (inner_item.id == item.id && inner_item.channel == item.channel) {
                        item.open_2 = inner_item.open_2;
                    }
                });
                return item;
            });
        }
        this.setState({
            tab: nextProps.tab,
            open: nextProps.open,
            master_template: nextProps.masterTemplate,
            channels: channels ? channels : [],
            accounts: accounts ? accounts : [],
            campaigns: campaigns ? campaigns : [],
            adgroups: adgroups ? adgroups : [],
            ads: ads ? ads : [],
            background_color: nextProps.background_color ? JSON.parse(JSON.stringify(nextProps.background_color)) : "ffffff",
            font_color: nextProps.font_color ? JSON.parse(JSON.stringify(nextProps.font_color)) : "000000",
            transparent: nextProps.transparent ? JSON.parse(JSON.stringify(nextProps.transparent)) : false,
            shadow: nextProps.shadow ? JSON.parse(JSON.stringify(nextProps.shadow)) : false,
            enable_styling: nextProps.enable_styling ? JSON.parse(JSON.stringify(nextProps.enable_styling)) : false,
            preview: nextProps.preview,
            creative: nextProps.creative,
            input: nextProps.input,
            hide_settings: nextProps.hideSettings
        });
    }

    functions = {
        color: (item) => {
            let colors = {
                hex: "",
                rgb: ""
            }
            colors.hex = "#3A5998";
            if (item.channel === "facebook") {
                colors.hex = "#3A5998";
            } else if (item.channel === "google") {
                colors.hex = "#4384F4";
            } else if (item.channel === "linkedin") {
                colors.hex = "#0D65A1";
            } else if (item.channel === "google_analytics") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_analytics_4") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_shopping") {
                colors.hex = "#34A853";
            } else if (item.channel === "adform") {
                colors.hex = "#60C1A4";
            } else if (item.channel === "bidtheatre") {
                colors.hex = "#f48e22";
            } else if (item.channel === "tiktok") {
                colors.hex = "#000000";
            } else if (item.channel === "twitter") {
                colors.hex = "#50ABF1";
            } else if (item.channel === "snapchat") {
                colors.hex = "#FFFC00";
            } else if (item.channel === "bing") {
                colors.hex = "#0C8484";
            }
            colors.rgb = colors.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16));
            return colors;
        },
        valid: () => {
            return true;
        },
        multipleLevelsSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 1
        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 0
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>

                {
                    !this.state.hide_settings &&
                    <div className='px-6 pt-6'>

                        {
                            !this.state.preview &&
                            !this.state.creative &&
                            !this.state.input &&
                            <div className="flex flex-1 flex-row justify-center items-center">
                                <div onClick={() => {
                                    this.setState({
                                        open: !this.state.open
                                    })
                                }} className="flex flex-col flex-1 cursor-pointer">
                                    <h3 className="mb-0 font-semibold text-sm">
                                        Select settings
                                    </h3>
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Select cell settings
                                    </p>
                                </div>
                                <div className="relative overflow-hidden mt-1">
                                    <SwitchTailwind
                                        value={this.state.enable_styling ? true : false}
                                        onSwitch={async () => {
                                            await this.promisedSetState({ enable_styling: !this.state.enable_styling });
                                            this.props.onEnableStyling(this.state.enable_styling);
                                        }}
                                    />
                                </div>
                            </div>
                        }

                        {
                            this.state.enable_styling &&
                            !this.state.transparent &&
                            <div className="text-xs font-medium text-gray-700 mb-2 mt-4">
                                Cell shadow
                            </div>
                        }
                        {
                            this.state.enable_styling &&
                            !this.state.transparent &&
                            <div className='flex flex-col w-full mb-4'>
                                <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className={`flex flex-1 text-sm ml-5`}>
                                        {this.state.shadow ? "Enabled" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.shadow ? true : false}
                                            onSwitch={async () => {
                                                this.state.shadow = !this.state.shadow;
                                                this.props.onShadow(this.state.shadow);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.enable_styling &&
                            <div className="text-xs font-medium text-gray-700 mb-2 mt-4">Cell background color</div>
                        }
                        {
                            this.state.enable_styling &&
                            <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "background_color" ? "border-purple-500" : ""}`}>
                                <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                    <div
                                        style={{ backgroundColor: "#fcfcfc" }}
                                        className="flex items-center col-span-3 p-1">
                                        <span className={`pl-3`}>#</span>
                                        <input
                                            id="background_color"
                                            className="w-full border-none p-2 bg-transparent"
                                            type="text"
                                            placeholder='FFFFFF'
                                            value={this.state.background_color}
                                            onClick={(e) => {
                                                this.promisedSetState({ selectedId: e.target.id })
                                            }}
                                            onChange={async (e) => {
                                                e.target.value = e.target.value.replace(/#/g, "")
                                                //await this.promisedSetState({ background_color: e.target.value })
                                                this.props.onBackgroundColor(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                        <input
                                            style={{ width: 110, height: 65, bottom: -10 }}
                                            className="absolute cursor-pointer"
                                            type="color"
                                            value={"#" + this.state.background_color}
                                            onChange={async (e) => {
                                                let color_string = e.target.value.slice(1)
                                                //await this.promisedSetState({ background_color: color_string })
                                                this.props.onBackgroundColor(color_string);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.enable_styling &&
                            <div className="text-xs font-medium text-gray-700 mb-2 mt-4">Cell font color</div>
                        }
                        {
                            this.state.enable_styling &&
                            <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "font_color" ? "border-purple-500" : ""}`}>
                                <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                    <div
                                        style={{ backgroundColor: "#fcfcfc" }}
                                        className="flex items-center col-span-3 p-1">
                                        <span className={`pl-3`}>#</span>
                                        <input
                                            id="font_color"
                                            className="w-full border-none p-2 bg-transparent"
                                            type="text"
                                            placeholder='FFFFFF'
                                            value={this.state.font_color}
                                            onClick={(e) => {
                                                this.promisedSetState({ selectedId: e.target.id })
                                            }}
                                            onChange={async (e) => {
                                                e.target.value = e.target.value.replace(/#/g, "")
                                                //await this.promisedSetState({ font_color: e.target.value });
                                                this.props.onFontColor(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                        <input
                                            style={{ width: 110, height: 65, bottom: -10 }}
                                            className="absolute cursor-pointer"
                                            type="color"
                                            value={"#" + this.state.font_color}
                                            onChange={async (e) => {
                                                let color_string = e.target.value.slice(1)
                                                //await this.promisedSetState({ font_color: color_string })
                                                this.props.onFontColor(color_string);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }

            </>
        )
    }

}

export default AdvancedReportSlideInDatasource;