import React, { Component, Fragment } from 'react';
import moment from "moment/moment";
import FeatherIcon from "feather-icons-react";
import SlideDown from "react-slidedown";
import DropdownTailwind from '../../moduleFiles/dropdownTailwind';
import SingleDatepicker from "../../modules/singleDatepicker";

class SelectCellDaterange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            channels: {},
            accounts: {},
            campaigns: {},
            adgroups: {},
            ads: {},
            dateranges: [],
            x_days: []
        };
    };

    componentDidMount() {
        let dateranges = [
            {
                title: "Last 7 days",
                subtitle: ("From " + moment().subtract(7, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "7_days",
                disabled: false
            },
            {
                title: "Last 10 days",
                subtitle: ("From " + moment().subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "10_days",
                disabled: false
            },
            {
                title: "Last 14 days",
                subtitle: ("From " + moment().subtract(14, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "14_days",
                disabled: false
            },
            {
                title: "last 20 days",
                subtitle: ("From " + moment().subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "20_days",
                disabled: false
            },
            {
                title: "Last 30 days",
                subtitle: ("From " + moment().subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "30_days",
                disabled: false
            },
            {
                title: "Last X days",
                subtitle: "Select X amounts of days",
                icon: "calendar",
                value: "x_days",
                disabled: false
            },
            {
                title: "Last week",
                subtitle: ("From " + moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_week",
                disabled: false
            },
            {
                title: "Last month",
                subtitle: ("From " + moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_month",
                disabled: false
            },
            {
                title: "This month",
                subtitle: ("From " + moment().startOf('month').format("YYYY-MM-DD") + " To " + (moment().subtract(1, 'days').format("DD") == "01" ? moment().format("YYYY-MM-DD") : moment().subtract(1, 'days').format("YYYY-MM-DD"))),
                icon: "calendar",
                value: "this_month",
                disabled: false
            },
            {
                title: "Custom dates",
                subtitle: "Add custom start date and end date",
                icon: "calendar",
                value: "custom_dates",
                disabled: false
            }
        ];
        this.setState({
            x_days: Array.from({ length: 364 }, (_, i) => i + 2).map((item) => { return { id: item, title: item, name: item + " days", value: item } }),
            dateranges: dateranges,
            tab: this.props.tab ? this.props.tab : {},
            channels: this.props.selectedChannels ? this.props.selectedChannels : {},
            accounts: this.props.selectedAccounts ? this.props.selectedAccounts : {},
            campaigns: this.props.selectedCampaigns ? this.props.selectedCampaigns : {},
            adgroups: this.props.selectedAdgroups ? this.props.selectedAdgroups : {},
            ads: this.props.selectedAds ? this.props.selectedAds : {},
            open: this.props.open
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab ? nextProps.tab : {},
            channels: nextProps.selectedChannels ? nextProps.selectedChannels : {},
            accounts: nextProps.selectedAccounts ? nextProps.selectedAccounts : {},
            campaigns: nextProps.selectedCampaigns ? nextProps.selectedCampaigns : {},
            adgroups: nextProps.selectedAdgroups ? nextProps.selectedAdgroups : {},
            ads: nextProps.selectedAds ? nextProps.selectedAds : {},
            open: nextProps.open
        })
    }

    functions = {
        valid: () => {
            return true;
        },
        dailySelected: () => {
            let daily_selected = false;
            if (this.state.tab.channel_breakdowns) {
                for (let channel in this.state.tab.channel_breakdowns) {
                    for (let breakdown in this.state.tab.channel_breakdowns[channel]) {
                        if (breakdown == "date" || breakdown == "ga:date") {
                            daily_selected = true;
                        }
                    }
                }
            }
            return daily_selected;
        },
        compareFormValidation: () => {
            let amount_of_datasources = 0;
            amount_of_datasources += Object.keys(this.state.channels).length;
            amount_of_datasources += Object.keys(this.state.accounts).length;
            amount_of_datasources += Object.keys(this.state.campaigns).length;
            amount_of_datasources += Object.keys(this.state.adgroups).length;
            amount_of_datasources += Object.keys(this.state.ads).length;
            if ((amount_of_datasources > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart") || (this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart")) {
                return false;
            } else if (this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.chart && this.state.tab.chart.value === "donut") {
                return false;
            } else {
                return true;
            }
        }
    };

    render() {
        return (
            <>
                <div
                    className={(this.functions.valid() ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            this.props.onToggle(this.state.open);
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select date range
                            </h3>
                            {
                                !(this.state.tab.daterange && this.state.tab.daterange.value) &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    There are <span
                                    className="font-bold ml-1 mr-1">{this.state.dateranges.length}</span> ranges
                                    available
                                </p>
                            }
                            {
                                this.state.tab.daterange && this.state.tab.daterange.value &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected <span
                                    className="font-bold ml-1 mr-1">{this.state.tab.daterange.title}</span>
                                </p>
                            }
                        </div>
                        {
                            this.functions.valid() &&
                            <div className="flex flex-row">
                                {
                                    (
                                        (this.state.tab.daterange && this.state.tab.daterange.value && this.state.tab.daterange.value !== "custom_dates")
                                        ||
                                        (this.state.tab.daterange && this.state.tab.daterange.value && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)
                                    ) &&
                                    <div
                                        className="mr-2 border-purple-500 border-2 text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={16}
                                                     icon={"check"} />
                                    </div>
                                }
                                <div onClick={() => {
                                    this.props.onToggle(this.state.open);
                                }}
                                     className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                    {
                                        !this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                                     icon={"chevron-down"} />
                                    }
                                    {
                                        this.state.open &&
                                        <FeatherIcon className={'stroke-current'} size={16}
                                                     icon={"chevron-up"} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="">
                            {
                                this.state.dateranges.filter((item) => {
                                    if (this.state.tab.celltype && this.state.tab.celltype.id === "performance") {
                                        return item.value == "custom_dates";
                                    } else {
                                        return true;
                                    }
                                }).map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div
                                                className={((this.functions.dailySelected() && item.value == "lifetime") ? "opacity-50 cursor-not-allowed" : "") + " mb-2 flex flex-1 flex-row justify-center"}>
                                                <div onClick={() => {

                                                }}
                                                     className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'}
                                                                 size={17} icon={item.icon} />
                                                </div>
                                                <div onClick={() => {
                                                    if (!(this.functions.dailySelected() && item.value == "lifetime")) {
                                                        if (this.state.tab.daterange && this.state.tab.daterange.compare_dates) {
                                                            item.compare_dates = this.state.tab.daterange.compare_dates;
                                                        }
                                                        if (this.state.tab.daterange && this.state.tab.daterange.compare_last_year) {
                                                            item.compare_last_year = this.state.tab.daterange.compare_last_year;
                                                        }
                                                        this.state.tab.daterange = item;
                                                        if (item.value === "x_days") {
                                                            this.state.tab.daterange.days = { id: 1, title: "1", name: "1 day", value: 1 };
                                                        } else {
                                                            delete this.state.tab.daterange.days;
                                                        }
                                                        this.setState({
                                                            tab: this.state.tab
                                                        }, () => {
                                                            this.props.onTab(this.state.tab);
                                                        });
                                                    }
                                                }}
                                                     className={`cursor-pointer h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center ${this.functions.dailySelected() && item.value == "lifetime" && "cursor-not-allowed"} ${this.state.tab.daterange && this.state.tab.daterange.value === item.value && "border-purple-500"}`}
                                                >
                                                    {
                                                        this.state.tab.daterange && this.state.tab.daterange.value === item.value &&
                                                        <div
                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className="flex flex-1 flex-col">
                                                    <div className="font-medium text-sm">
                                                        {item.title}
                                                    </div>
                                                    {
                                                        (item.value === "custom_dates" && this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) ?
                                                            (<div className="text-xxs">
                                                                From {this.state.tab.daterange.start_date} to {this.state.tab.daterange.end_date}
                                                            </div>) :
                                                            (<div className="text-xxs">
                                                                {item.subtitle}
                                                            </div>)
                                                    }
                                                </div>
                                            </div>
                                            {
                                                item.value === "x_days" &&
                                                this.state.tab.daterange && this.state.tab.daterange.value === "x_days" &&
                                                <div className="w-full relative mb-2">
                                                    <DropdownTailwind
                                                        searchInput={true}
                                                        selected={this.state.tab.daterange && this.state.tab.daterange.days ? this.state.tab.daterange.days : { id: 0, name: "Select X days" }}
                                                        options={this.state.x_days}
                                                        onChange={(value) => {
                                                            if (!this.state.tab.daterange) {
                                                                this.state.tab.daterange = {};
                                                            }
                                                            this.state.tab.daterange.days = value;
                                                            this.setState({
                                                                tab: this.state.tab
                                                            }, () => {
                                                                this.props.onTab(this.state.tab);
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" &&
                            <div className="w-full border-1.5 rounded-md mt-4 p-4 pb-0 pt-2 bg-custom-input">
                                <SingleDatepicker
                                    large={true}
                                    maxDate={(this.state.tab.celltype && this.state.tab.celltype.id === "performance") ? null : new Date()}
                                    from={this.state.tab.daterange.start_date}
                                    to={this.state.tab.daterange.end_date}
                                    onChange={(data) => {
                                        if (!this.state.tab.daterange) {
                                            this.state.tab.daterange = {};
                                        }
                                        this.state.tab.daterange.start_date = data.start_date;
                                        this.state.tab.daterange.end_date = data.end_date;
                                        this.setState({
                                            tab: this.state.tab
                                        }, () => {
                                            this.props.onTab(this.state.tab);
                                        });
                                    }}
                                />
                            </div>
                        }
                        {
                            !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                            <div className={(this.functions.compareFormValidation() ? "" : "opacity-50 ") + " font-medium text-xs truncate mb-2 mt-5"}>
                                Comparison settings
                            </div>
                        }
                        {
                            !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                            <div className="mb-2">
                                <div className={(this.functions.compareFormValidation() ? "" : "opacity-50 cursor-not-allowed") + " flex flex-row"}>
                                    <div
                                        className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'}
                                                     size={17} icon={"trending-up"} />
                                    </div>
                                    <div onClick={() => {
                                        if (this.functions.compareFormValidation()) {
                                            if (!this.state.tab.daterange) {
                                                this.state.tab.daterange = {};
                                                this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" };
                                            } else if (!this.state.tab.daterange.compare_dates) {
                                                this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" }
                                            } else {
                                                delete this.state.tab.daterange.compare_last_year;
                                                delete this.state.tab.daterange.compare_dates;
                                                delete this.state.tab.daterange.compare_dates;
                                            }
                                            this.setState({
                                                tab: this.state.tab
                                            }, () => {
                                                this.props.onTab(this.state.tab);
                                            });
                                        }
                                    }}
                                         className={(this.functions.compareFormValidation() ? "cursor-pointer " : "cursor-not-allowed ") + (this.functions.compareFormValidation() && this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.functions.compareFormValidation() &&
                                            this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                                            <FeatherIcon
                                                className={'stroke-current'}
                                                size={16}
                                                icon="check" />
                                        }
                                    </div>
                                    <div className="flex flex-1 flex-col truncate">
                                        <div className="font-medium text-sm truncate">
                                            Compare with preceding period
                                        </div>
                                        <div className="text-xxs">
                                            Evaluate selected time period with previous period
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !this.functions.compareFormValidation() &&
                            <div className="col-span-6 p-3 rounded-md font-medium text-xs text-orange-500 bg-orange-100 mb-2">
                                OBS! Selection is not supported for comparing with preceding period
                            </div>
                        }
                        {
                            this.functions.compareFormValidation() &&
                            this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                            <div className="relative">
                                {
                                    this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                    <div className="bg-white bg-opacity-50 absolute left-0 right-0 bottom-0 top-0"></div>
                                }
                                <div className="flex flex-row">
                                    <div
                                        className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'}
                                                     size={17} icon={"clock"} />
                                    </div>
                                    <div onClick={() => {
                                        if (!this.state.tab.daterange.compare_last_year) {
                                            this.state.tab.daterange.compare_last_year = { title: "Enabled", value: "enabled" }
                                        } else {
                                            delete this.state.tab.daterange.compare_last_year;
                                        }
                                        this.setState({
                                            tab: this.state.tab
                                        }, () => {
                                            this.props.onTab(this.state.tab);
                                        });
                                    }}
                                         className={(this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" &&
                                            <FeatherIcon
                                                className={'stroke-current'}
                                                size={16}
                                                icon="check" />
                                        }
                                    </div>
                                    <div className="flex flex-1 flex-col">
                                        <div className="font-medium text-sm">
                                            Same daterange one year ago
                                        </div>
                                        <div className="text-xxs">
                                            Selected period one year ago
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.functions.compareFormValidation() &&
                            this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                            <div className="mt-2 relative">
                                {
                                    this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" &&
                                    <div className="bg-white bg-opacity-50 absolute left-0 right-0 bottom-0 top-0"></div>
                                }
                                <div className="flex flex-row">
                                    <div
                                        className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'}
                                                     size={17} icon={"calendar"} />
                                    </div>
                                    <div onClick={() => {
                                        if (!this.state.tab.daterange.compare_custom_date) {
                                            this.state.tab.daterange.compare_custom_date = { title: "Enabled", value: "enabled" }
                                        } else {
                                            delete this.state.tab.daterange.compare_custom_date;
                                        }
                                        this.setState({
                                            tab: this.state.tab
                                        }, () => {
                                            this.props.onTab(this.state.tab);
                                        });
                                    }}
                                         className={(this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                            <FeatherIcon
                                                className={'stroke-current'}
                                                size={16}
                                                icon="check" />
                                        }
                                    </div>
                                    <div className="flex flex-1 flex-col truncate">
                                        <div className="font-medium text-sm">
                                            Custom daterange
                                        </div>
                                        <div className="text-xxs">
                                            {
                                                this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                                this.state.tab.daterange.compare_custom_date.start_date &&
                                                this.state.tab.daterange.compare_custom_date.end_date &&
                                                <span> From {this.state.tab.daterange.compare_custom_date.start_date} to {this.state.tab.daterange.compare_custom_date.end_date}</span>
                                                ||
                                                <span>Select daterange</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                            <div className="w-full border-1.5 rounded-md mt-4 p-4 pt-2 pb-0 bg-custom-input">
                                <SingleDatepicker
                                    large={true}
                                    maxDate={new Date()}
                                    from={this.state.tab.daterange.compare_custom_date.start_date}
                                    to={this.state.tab.daterange.compare_custom_date.end_date}
                                    onChange={(data) => {
                                        this.state.tab.daterange.compare_custom_date.start_date = data.start_date;
                                        this.state.tab.daterange.compare_custom_date.end_date = data.end_date;
                                        this.setState({
                                            tab: this.state.tab
                                        }, () => {
                                            this.props.onTab(this.state.tab);
                                        });
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.tab.daterange && this.state.tab.daterange.value && (this.state.tab.daterange.value !== "custom_dates" || (this.state.tab.daterange.value == "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}

export default SelectCellDaterange